import React, { useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { JobPostForm } from "../../Components";

const JobPost = () => {
  const [loading, setLoading] = useState(false)
  return (
    <SidebarLayout>
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <JobPostForm type='jobPost' setLoading={setLoading} />
        </div>
      </main>
    </SidebarLayout>
  );
};

export default JobPost;
