import React, { useCallback, useEffect, useRef, useState } from "react";
import { Colors } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../Redux/Services/AuthServices";
import { createSession } from "../Redux/Services/OtherServices";
import { LoginAlert } from "./Popups/LoginAlert";
import { Alert, InfoAlert, Login } from "./Popups";
import { getData, storageKey } from "../Utility/Storage";
import { useLocation, useNavigate } from "react-router-dom";
import { routeName } from "../Utility";
import { loggedInUsedAction } from "../Redux/Actions/AuthActions";

export const PlansCard = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const queryParams = new URLSearchParams(location?.search);
  const userIdPay = queryParams?.get("userID");
  const sessionId = queryParams?.get("session_id");
  const success = queryParams?.get("success");
  console.log("Your Param Value:", userIdPay, sessionId, success);

  const auth = useSelector((state) => state?.authReducer);
  const { cardData } = props;
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [show, setShow] = useState(false);
  const [planId, setPlanId] = useState("");

  const handleChoose = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        product_id: cardData?.custom_product_id,
        price_id: cardData?.custom_price_id,
        user_id: userID ? JSON?.parse(userID) : "",
        action: "web_subscription_react",
      };
      let res = await dispatch(createSession(body));
      if (res?.status == 200) {
        if (window) {
          window.open(res?.results?.url, "_blank");
        }
      }
    } else {
      setLoginModalVisible(true);
    }
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    // console.log(userID,"hiiiiiuserID");
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status === 200) {
        // console.log(res?.results,
        //   res?.results?.user_data?.subscription_pro_id,
        //   "res?.results?.user_data?.subscription_pro_id"
        // );
        // setPlanId(res?.results?.user_data?.subscription_pro_id || "");
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
        navigate(routeName.HOME, { replace: true });
      }
    } else {
    }
  };

  const handlePayStatus = async () => {
    let body = {
      // action: "session",
      action: "session_react",
      user_id: userIdPay || "",
      sessions_id: sessionId || "",
    };
    let res = await dispatch(createSession(body));
    if (res?.status == 200) {
      // console.log('hiiiiii');
      // if (window) {
        getUserData();
        // navigate(routeName.HOME, { replace: true });
      // }
    }
  };

  const memoizedHandlePayStatus = useCallback(() => {
    if (userIdPay && sessionId && success) {
      handlePayStatus();
    }
  }, [userIdPay, sessionId, success]);
  
  useEffect(() => {
    memoizedHandlePayStatus();
  }, [memoizedHandlePayStatus]);

  // useEffect(() => {
  //   if (userIdPay && sessionId && success) {
  //     handlePayStatus();
  //   }
  // }, []);


  // useEffect(() => {
  //   if (loggedInUser?.user_data?.subscription_pro_id) {
  //     setPlanId(loggedInUser?.user_data?.subscription_pro_id);
  //   }
  // }, [loggedInUser]);

  return (
    <>
      <InfoAlert
        title="Order Now"
        text="Are you sure you want to buy this package?"
        show={show}
        setShow={setShow}
        setConfirm={setShow}
        onConfirm={handleChoose}
      />
      <div className="col-md-4 ">
        <div className="table">
          <div className="table-header">
            <h3>{cardData?.post_title}</h3>
            <h2>{`${
              cardData?.localizedPrice
                ? cardData?.localizedPrice
                : cardData?._price
                ? "$" + parseFloat(cardData?._price)
                : "0"
            }`}</h2>
            <p style={{ color: Colors?.gray, fontWeight: 400 }}>
              {cardData?.description?.top_content}
            </p>
          </div>
          <div className="table-body">
            <ul className="features list-unstyled">
              {cardData?.description?.bottom?.map((item) => {
                return (
                  <>
                    <li style={{ color: Colors?.gray, fontWeight: 400 }}>
                      {item}
                    </li>
                  </>
                );
              })}

              {/* <li>5 Jobs to Apply</li>
              <li>No Portfolio</li>
              <li>No Social Media Post</li>
              <li>Messaging to other Talent</li>
              <li>Basic Search Ranking</li>
              <li>15% service fee</li> */}
            </ul>
            <div
              className="table-footer"
              onClick={() => {
                if (loggedInUser?.user_data?.subscription_pro_id == cardData?.product_id) {
                } else {
                  setShow(true);
                }
              }}
            >
              <a
                className={
                  loggedInUser?.user_data?.subscription_pro_id == cardData?.product_id
                    ? "signup btn disable"
                    : "signup btn"
                }
                style={{
                  opacity: loggedInUser?.user_data?.subscription_pro_id == cardData?.product_id ? 0.3 : 1,
                }}
              >
                Order Now
              </a>
            </div>
          </div>
        </div>
      </div>
      {loginModalVisible && (
        <LoginAlert
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
    </>
  );
};
