import React, { useEffect, useRef, useState } from "react";
import { Routing } from "./Routing";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/css/normalize.css";
import "./Assets/css/scrollbar.css";
import "./Assets/css/fontawesome/fontawesome-all.css";
import "./Assets/css/font-awesome.min.css";
import "./Assets/css/owl.carousel.min.css";
import "./Assets/css/linearicons.css";
import "./Assets/css/jquery-ui.css";
import "./Assets/css/tipso.css";
import "./Assets/css/chosen.css";
import "./Assets/css/main.css";
import "./Assets/css/dashboard.css";
import "./Assets/css/color.css";
import "./Assets/css/transitions.css";
import "./Assets/css/style.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-gallery/styles/css/image-gallery.css";
// import Cropper, { ReactCropperElement } from "react-cropper";
// import "cropperjs/dist/cropper.css";
import "./Assets/css/responsive.css";
import { useDispatch } from "react-redux";
import { getOptionsData } from "./Redux/Services/AuthServices";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptionsData());
  }, [])
  
  return (
    <>
      <Routing />
    </>
  );
}

export default App;
// import Cropper from "react-cropper";
// import "cropperjs/dist/cropper.css";
// import { Button, Modal } from "react-bootstrap";
// import { base64StringToFile } from "./Utility";

// const ImageCropper = ({ onCrop }) => {
//   const cropperRef = useRef(null);
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [images, setImages] = useState([]);
//   const [show, setShow] = useState(false);
//   const [selectedFiles, setSelectedFiles] = useState([]);

//   const handleImageCrop = (index) => {
//     // setCurrentImageIndex(index);
//     if (typeof cropperRef.current?.cropper !== "undefined") {
//       let cropped_file = cropperRef.current?.cropper
//         .getCroppedCanvas()
//         .toDataURL();
//       let file = base64StringToFile(cropped_file, "example.png", "image/jpeg");
//       console.log("cropped_filecropped_file----", file);
//       let arr = [...selectedFiles];
//       arr?.push(file);
//       setSelectedFiles(arr);
//       onCrop(arr, "profile");
//     }
//     if (images?.length - 1 == index) {
//       setShow(false);
//       setCurrentImageIndex(-1);
//     } else {
//       setCurrentImageIndex(currentImageIndex + 1);
//       const currentImage = images[index];
//       cropperRef.current.src = currentImage;
//     }
//   };

//   console.log("selectedFilesselectedFilesselectedFiles----", selectedFiles);
//   const handleChooseGallery = async (event) => {
//     const files = event.target.files;
//     if (files?.length != 0) {
//       const newDetails = Array.from(files).map((file) => ({
//         uri: URL.createObjectURL(file),
//         name: file.name,
//         size: file.size,
//         type: file?.type,
//       }));

//       let newArr = [...(images || []), ...newDetails];
//       if (newArr?.length > 10) {
//         alert(
//           "You cannot upload another Image. You reached your limit, Choose Minimum 3 Images or Maximum 10 Images."
//         );
//       } else {
//         setImages((prevFileDetails) => [
//           ...(prevFileDetails || []),
//           ...newDetails,
//         ]);
//         setShow(true);
//       }
//     }
//   };
//   return (
//     <div>
//       <input
//         type="file"
//         name="file"
//         id="filew"
//         multiple
//         onChange={handleChooseGallery}
//       />
//       <div>
//         {currentImageIndex != -1 && (
//           <Modal show={show} onHide={() => setShow(false)} centered size="md">
//             <Modal.Header closeButton></Modal.Header>
//             {images.map((image, index) => (
//               <>
//                 {currentImageIndex == index && (
//                   <>
//                     <Modal.Body key={index}>
//                       <Cropper
//                         ref={cropperRef}
//                         src={images[currentImageIndex]?.uri}
//                         style={{ height: 400, width: "100%" }}
//                         aspectRatio={16 / 9}
//                       />
//                     </Modal.Body>
//                     <Modal.Footer>
//                       <Button
//                         variant="secondary"
//                         onClick={() => setShow(false)}
//                       >
//                         Cancel
//                       </Button>
//                       <Button
//                         key={index}
//                         variant="primary"
//                         onClick={() => handleImageCrop(index)}
//                       >
//                         Crop
//                       </Button>
//                     </Modal.Footer>
//                   </>
//                 )}
//               </>
//             ))}
//           </Modal>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ImageCropper;
