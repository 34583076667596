import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import SubmitIdentity from "../../features/identityVerify/SubmitIdentity";
import SecondStep from "../../features/identityVerify/SecondStep";
import VerificationComplete from "../../features/identityVerify/VerificationComplete";
import { useSelector } from "react-redux";

const IdentityVerification = () => {
  const [step, setStep] = useState(0);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  useEffect(() => {
    if(loggedInUser?.user_data?.identity_status === 0){
      setStep(1)
    } else if(loggedInUser?.user_data?.identity_status === 1){
      setStep(2)
    } else if(loggedInUser?.user_data?.identity_status === 2){
      setStep(3)
    }else {
      // setStep(1)
    }
    
  }, [loggedInUser])
  

  console.log(loggedInUser?.user_data?.identity_status , loggedInUser?.user_data ,"lplplp");

  return (
    <SidebarLayout>
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          {step === 1  ? (
            <SubmitIdentity setStep={setStep} />
          ) : step === 2  ? (
            <SecondStep setStep={setStep} />
          ) : step === 3  ? (
            <VerificationComplete />
          ) :  <SubmitIdentity setStep={setStep} />}
        </div>
      </main>
    </SidebarLayout>
  );
};

export default IdentityVerification;
