import React, { useState } from "react";
import {
  Header,
  Footer,
  Loader,
  CalendarCompo,
  PortfolioData,
} from "../../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Images } from "../../Constants";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import { useLocation } from "react-router-dom";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Utility";
import { getData, storageKey } from "../../Utility/Storage";
import {
  blockUser,
  getUserPortfolio,
  getUserReviews,
  getUserSocialPosts,
  userFollowing,
  userReporting,
} from "../../Redux/Services/OtherServices";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LoginAlert } from "../../Components/Popups/LoginAlert";
import Rating from "@mui/material/Rating";
import { BookTalent, Login } from "../../Components/Popups";
import FeedbackModal from "../../Components/Popups/Feedback";

export const TalentProfile = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const allOptionData = useSelector(
    (state) => state?.authReducer.allOptionData
  );

  const [lightbox, setlightbox] = useState(false);
  const [slideIndex, setSlideIndex] = useState(-1);
  const [portfolioData, setPortfolioData] = useState("");
  const [socialData, setSocialData] = useState("");
  const [lightActorbox, setlightActorbox] = useState(false);
  const [lightPortbox, setlightPortbox] = useState(false);
  const [selectedImages, setSelectedImages] = useState(-1);
  const [slideActorIndex, setSlideActorIndex] = useState(-1);
  const [reportDescription, setReportDescription] = useState("");
  const [reportReason, setReportReason] = useState("");
  const [isReadMore, setIsReadMore] = useState(false);
  const [isReadMoreProfession, setIsReadMoreProfession] = useState(false);
  const [talentData, setTalentData] = useState("");
  const [isFollow, setIsFollow] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [userFeedback, setUserFeedback] = useState("");
  const [isFeedback, setIsFeedback] = useState(false);
  const [bookJobModal, setBookJobModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [slideIndex2, setSlideIndex2] = useState(-1);

  const changeTab = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = [
    { id: 1, title: "Detail" },
    { id: 2, title: "Portfolio" },
    { id: 3, title: "Social Post" },
    // {content: 'Content of Tab 3' },
  ];

  var Gallery = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var Actor = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status == 200) {
        // setUserLoggedInData(res?.results);
      }
    } else {
    }
  };

  const getFeedbackData = async () => {
    setLoading(true);
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: id,
      };
      let res = await dispatch(getUserReviews(body));
      setLoading(false);
      if (res?.status === 200) {
        setUserFeedback(res?.results || []);
      }
    } else {
    }
  };

  const getPortfolioData = async (userId, profileId) => {
    setLoading(true);
    if (userId) {
      let body = {
        user_id: userId,
        profile_id: profileId,
      };
      let res = await dispatch(getUserPortfolio(body));
      setLoading(false);
      if (res?.status === 200) {
        setPortfolioData(res?.results || []);
      }
    } else {
    }
  };
  const getSocialPostData = async (userId, profileId) => {
    setLoading(true);
    if (userId) {
      let body = {
        user_id: userId,
        profile_id: profileId,
      };
      let res = await dispatch(getUserSocialPosts(body));
      setLoading(false);
      if (res?.status === 200) {
        setSocialData(res?.results || []);
      }
    } else {
    }
  };

  const getTalentDetail = async () => {
    setLoading(true);
    let user = await getData(storageKey.USER_ID);
    let body = {
      user_id: id || "1664",
    };
    let res = await dispatch(getUserDetail(body));
    setLoading(false);
    if (res?.status == 200) {
      setTalentData({
        ...talentData,
        fwOption: res?.results?.fw_option?.length
          ? res?.results?.fw_option?.[0]
          : "",
        userData: res?.results?.user_data || "",
        companyDetails: res?.results?.company_details || "",
        postDetails: res?.results?.post_meta_details || "",
        socialFollowers: res?.results?.social_followers || "",
        measurement: res?.results?.measurment || "",
        imageGallery: res?.results?.images_gallery?.length
          ? res?.results?.images_gallery
          : "",
        profileImage: res?.results?.profile_image || "",
        projectCount: res?.results?.project_count_details || "",
        measuremenrGallery: res?.results?.measument_gallery?.length
          ? res?.results?.measument_gallery
          : "",
      });
      getPortfolioData(id, res?.results?.user_data?.profile_id);
      getSocialPostData(id, res?.results?.user_data?.profile_id);
      setIsFollow(
        res?.results?.post_meta_details?.user_followers?.includes(user)
          ? true
          : false
      );
      // dataArray.some(item => item.id === userIdToCheck)
      setIsBlock(
        res?.results?.user_data?.user_blocked_by_list?.length &&
          res?.results?.user_data?.user_blocked_by_list?.some(
            (item) => item?.id === user
          )
          ? true
          : false
      );
    }
  };

  useEffect(() => {
    getTalentDetail();
    getFeedbackData();
  }, []);

  const handleBookTalent = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      setBookJobModal(true);
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to [erform this action!')
    }
  };

  const handleBlockUser = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      setLoading(true);
      let body = {
        user_id: user,
        post_id: talentData?.userData?.user_id,
        action: isBlock ? "unblock" : "block",
      };
      let res = await dispatch(blockUser(body));
      setLoading(false);
      if (res?.status == 200) {
        getTalentDetail();
        // showToast("Block succesfully");
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to [erform this action!')
    }
  };

  const handleFollowUnFollow = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      setLoading(true);
      let body = {
        user_id: user,
        post_id: talentData?.userData?.profile_id,
        action: isFollow ? "unfollow" : "follow",
      };
      let res = await dispatch(userFollowing(body));
      setLoading(false);
      if (res?.status == 200) {
        getTalentDetail();
        showToast(
          isFollow ? "UnFollow Successfully" : "Follow successfully",
          "success"
        );
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  const handleReport = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      if (!reportReason) {
        showToast("Choose report reason first");
        return;
      }
      setLoading(true);
      let body = {
        user_by: JSON.parse(user),
        reported_user_id: talentData?.userData?.user_id,

        report_type: "freelancer",
        reported_post_id: talentData?.userData?.profile_id,

        title: reportReason,
        content: reportDescription || "",
      };
      let res = await dispatch(userReporting(body));
      setLoading(false);
      if (res?.status == 200) {
        setReportReason("");
        setReportDescription("");
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  console.log(
    portfolioData?.[selectedImages],
    portfolioData?.[selectedImages]?.media,
    selectedImages,
    "klklmm"
  );

  return (
    <div>
      <Loader loading={loading} />
      <Header />
      <div className="profileSleder">
        {talentData?.imageGallery?.length ? (
          <Slider {...Gallery} infinite={Gallery.length >= 4}>
            {talentData?.imageGallery?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setlightbox(true);
                    setSlideIndex(index);
                    setSlideActorIndex(-1);
                    setlightActorbox(false);
                    setSlideIndex2(-1);
                    setlightPortbox(false);
                    setSelectedImages(-1);
                  }}
                >
                  <img src={item?.url} />
                </div>
              );
            })}
          </Slider>
        ) : undefined}
      </div>

      {talentData?.imageGallery?.length ? (
        <SlideshowLightbox
          open={lightbox}
          startingSlideIndex={slideIndex}
          onClose={() => {
            setlightbox(false);
          }}
          className="lightBoxHIde"
        >
          {talentData?.imageGallery?.map((item, index) => {
            return <img key={index} src={item?.url} />;
          })}
        </SlideshowLightbox>
      ) : undefined}

      {talentData?.measuremenrGallery?.length ? (
        <SlideshowLightbox
          open={lightActorbox}
          startingSlideIndex={slideActorIndex}
          onClose={() => {
            setlightActorbox(false);
          }}
          className="lightBoxHIde"
        >
          {talentData?.measuremenrGallery?.map((item, index) => {
            return <img key={index} src={item?.value} />;
          })}
        </SlideshowLightbox>
      ) : undefined}

      {portfolioData?.length &&
      selectedImages > -1 &&
      portfolioData?.[selectedImages]?.media?.length ? (
        <SlideshowLightbox
          open={lightPortbox}
          startingSlideIndex={slideIndex2}
          onClose={() => {
            setlightPortbox(false);
          }}
          className="lightBoxHIde"
        >
          {portfolioData?.[selectedImages]?.media?.map((item, index) => {
            return <img key={index} src={item?.url} />;
          })}
        </SlideshowLightbox>
      ) : undefined}

      <div className="talentInfo">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="wt-userprofileholder">
                <div className="row">
                  {talentData?.postDetails?.subscription_pro_id != 107 ? (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip
                          id={`tooltip-${talentData?.postDetails?.subscription_pro_id}`}
                        >
                          {talentData?.postDetails?.subscription_pro_id ==
                            106 ||
                          talentData?.postDetails?.subscription_pro_id == 104
                            ? "Professional member"
                            : talentData?.postDetails?.subscription_pro_id ==
                                103 ||
                              talentData?.postDetails?.subscription_pro_id ==
                                105
                            ? "Premium member"
                            : ""}
                        </Tooltip>
                      }
                    >
                      {talentData?.postDetails?.subscription_pro_id ? (
                        <div
                          className={
                            talentData?.postDetails?.subscription_pro_id ==
                              106 ||
                            talentData?.postDetails?.subscription_pro_id == 104
                              ? "paidUsers pro"
                              : talentData?.postDetails?.subscription_pro_id ==
                                  103 ||
                                talentData?.postDetails?.subscription_pro_id ==
                                  105
                              ? "paidUsers"
                              : ""
                          }
                        >
                          <i class="fas fa-crown"></i>
                        </div>
                      ) : (
                        <></>
                      )}
                    </OverlayTrigger>
                  ) : null}
                  {/* <div class="paidUsers">
                    <i class="fas fa-crown"></i>
                  </div> */}
                  <div class="col-md-3">
                    <div class="row">
                      <div class="wt-userprofile">
                        {talentData?.profileImage?.length ? (
                          <figure>
                            <img
                              src={
                                talentData?.profileImage?.[0]?.guid ||
                                Images?.hero1
                              }
                              alt="img description"
                            />
                            {/* <div class="wt-userdropdown wt-online"></div> */}
                          </figure>
                        ) : undefined}
                        <div class="wt-title">
                          <h3>
                            <i class="fa fa-check-circle"></i>{" "}
                            {`${talentData?.userData?.first_name || ""} ${
                              talentData?.userData?.last_name || ""
                            }`}
                          </h3>

                          <div className="ratingProfile">
                            <Rating
                              name="half-rating-read"
                              precision={0.5}
                              value={
                                talentData?.postDetails?.user_rating
                                  ? Number(talentData?.postDetails?.user_rating)
                                  : 0
                              }
                              readOnly
                            />
                          </div>

                          <span>
                            {`${
                              talentData?.postDetails?.user_rating
                                ? Number(talentData?.postDetails?.user_rating)
                                : 0
                            }/5`}
                            {userFeedback?.length ? (
                              <a
                                onClick={() => {
                                  setIsFeedback(true);
                                }}
                                class="javascript:void(0);"
                              >
                                {`(${
                                  talentData?.postDetails?.user_rating || 0
                                } Feedback)`}
                              </a>
                            ) : (
                              <a class="javascript:void(0);">{`(${
                                talentData?.postDetails?.user_rating || 0
                              } Feedback)`}</a>
                            )}
                            <p>{`Member since ${moment(
                              talentData?.userData?.post_date
                            ).format("MMM DD,YYYY")}`}</p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="wt-proposalhead wt-userdetails">
                      <h2>{talentData?.fwOption?.tag_line || ""}</h2>
                      <ul class="wt-userlisting-breadcrumb wt-userlisting-breadcrumbvtwo">
                        <li>
                          <span>
                            <i class="far fa-money-bill-alt"></i>{" "}
                            {`$${
                              talentData?.postDetails?.perhour_rate || 0
                            } / hr`}
                          </span>
                        </li>
                        <li>
                          <span>{talentData?.postDetails?.state || ""}</span>
                        </li>
                        <li>{talentData?.postDetails?.city || ""}</li>
                      </ul>
                      <div
                        class="wt-description"
                        data-readmore=""
                        aria-expanded="false"
                        id="rmjs-1"
                      >
                        {talentData?.postDetails?.professional_bio ? (
                          <>
                            <span>Professional Bio:</span>
                            <p>
                              {isReadMoreProfession
                                ? talentData?.postDetails?.professional_bio
                                : talentData?.postDetails?.professional_bio?.slice(
                                    0,
                                    200
                                  ) || ""}
                              {!isReadMoreProfession &&
                              talentData?.postDetails?.professional_bio
                                ?.length > 200 ? (
                                <span
                                  className="read_btn"
                                  onClick={() =>
                                    setIsReadMoreProfession(
                                      !isReadMoreProfession
                                    )
                                  }
                                >
                                  ...Read More
                                </span>
                              ) : talentData?.postDetails?.professional_bio
                                  ?.length > 200 ? (
                                <span
                                  className="read_btn"
                                  onClick={() =>
                                    setIsReadMoreProfession(
                                      !isReadMoreProfession
                                    )
                                  }
                                >
                                  Read Less
                                </span>
                              ) : undefined}
                            </p>
                          </>
                        ) : undefined}
                        {talentData?.postDetails?.personal_bio &&
                        talentData?.postDetails?.personal_bio?.length > 200 ? (
                          <>
                            <span>Personal Bio:</span>
                            <p>
                              {isReadMore
                                ? talentData?.postDetails?.personal_bio
                                : talentData?.postDetails?.personal_bio?.slice(
                                    0,
                                    200
                                  ) || ""}
                              {!isReadMore &&
                              talentData?.postDetails?.personal_bio?.length >
                                200 ? (
                                <span
                                  className="read_btn"
                                  onClick={() => setIsReadMore(!isReadMore)}
                                >
                                  ...Read More
                                </span>
                              ) : talentData?.postDetails?.personal_bio
                                  ?.length > 200 ? (
                                <span
                                  className="read_btn"
                                  onClick={() => setIsReadMore(!isReadMore)}
                                >
                                  Read Less
                                </span>
                              ) : undefined}
                            </p>
                          </>
                        ) : talentData?.postDetails?.personal_bio &&
                          talentData?.postDetails?.personal_bio?.length <
                            200 ? (
                          <>
                            <span>Personal Bio:</span>
                            <p>{talentData?.postDetails?.personal_bio || ""}</p>
                          </>
                        ) : undefined}
                      </div>
                    </div>
                    <div
                      id="wt-statistics"
                      class="wt-statistics wt-profilecounter"
                    >
                      <div class="wt-statisticcontent wt-countercolor1">
                        <h3
                          data-from="0"
                          data-to="03"
                          data-speed="800"
                          data-refresh-interval="03"
                        >
                          {talentData?.projectCount?.ongoing_project || 0}
                        </h3>
                        <h4>Ongoing Projects</h4>
                      </div>
                      <div class="wt-statisticcontent wt-countercolor2">
                        <h3
                          data-from="0"
                          data-to="1503"
                          data-speed="8000"
                          data-refresh-interval="100"
                        >
                          {talentData?.projectCount?.complete_project || 0}
                        </h3>
                        <h4>Completed Projects</h4>
                      </div>
                      <div class="wt-statisticcontent wt-countercolor4">
                        <h3
                          data-from="0"
                          data-to="02"
                          data-speed="800"
                          data-refresh-interval="02"
                        >
                          {talentData?.projectCount?.cancelled_project || 0}
                        </h3>
                        <h4>Cancelled Projects</h4>
                      </div>
                      <div class="wt-statisticcontent wt-countercolor3">
                        <h3
                          data-from="0"
                          data-to="25"
                          data-speed="8000"
                          data-refresh-interval="100"
                        >
                          {talentData?.postDetails?.user_followers?.length || 0}
                        </h3>
                        {/* <em>k</em> */}
                        <h4>Followers</h4>
                      </div>
                      
                        <div class="wt-profile-btn">
                          <a onClick={handleFollowUnFollow} class="wt-btn">
                            {isFollow ? "UnFollow" : "Follow"}
                          </a>
                            <a class="wt-btn">Message</a>
                          
                          {loggedInUser?.user_data?.user_role == 12 ||
                          loggedInUser?.user_data?.user_role == 14 ? (
                            <a onClick={handleBookTalent} class="wt-btn">
                              Book
                            </a>
                          ) : undefined}
                          {loggedInUser?.user_data?.user_id && (
                          <a onClick={handleBlockUser} class="wt-btn">
                            {isBlock ? "UnBlock" : "Block"}
                          </a>
                            )}
                        </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div class="col-md-8">
              <div className="tab-buttons">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    className={tab.id === activeTab ? "active" : ""}
                    onClick={() => changeTab(tab.id)}
                  >
                    {tab.title}
                  </button>
                ))}
              </div>
              {activeTab === 1 ? (
                <div class="talentDescription">
                  <p>
                    <span> Age :</span>
                    <span>{talentData?.postDetails?.age || ""}</span>
                  </p>
                  <p>
                    <span> Gender :</span>
                    <span>
                      {talentData?.fwOption?.gender?.toUpperCase() || ""}
                    </span>
                  </p>

                  {loggedInUser?.user_data?.user_role == 12 &&
                  talentData?.userData?.relationship_status ? (
                    <p>
                      <span>Relationship Status :</span>
                      <span>{talentData?.userData?.relationship_status}</span>
                    </p>
                  ) : undefined}
                  <p>
                    <span> Height :</span>
                    <span>{talentData?.postDetails?.height || ""}</span>
                  </p>
                  <p>
                    <span> Weight :</span>
                    <span>{talentData?.postDetails?.weight || ""}</span>
                  </p>
                  <p>
                    <span> Ethnicity :</span>
                    {talentData?.postDetails?.ethnicity?.length ? (
                      <span>
                        {talentData?.postDetails?.ethnicity?.map((item) => (
                          <div key={item?.label}>{item?.value || " "} </div>
                        ))}
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </p>

                  <p>
                    <span> Eye Color :</span>
                    <span>{talentData?.postDetails?.eye_color || ""}</span>
                  </p>

                  {talentData?.postDetails?.freelancer_type === "child" ? (
                    <>
                      {talentData?.measurement?.shirt_size?.length ? (
                        <p>
                          <span> Shirt size : </span>
                          <span>
                            {talentData?.measurement?.shirt_size?.[0]?.value
                              ? talentData?.measurement?.shirt_size?.[0]?.value
                              : talentData?.measurement?.shirt_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.pant_size?.length ? (
                        <p>
                          <span> Pant size : </span>
                          <span>
                            {talentData?.measurement?.pant_size?.[0]?.value
                              ? talentData?.measurement?.pant_size?.[0]?.value
                              : talentData?.measurement?.pant_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.pant_size?.length ? (
                        <p>
                          <span> Shoe size : </span>
                          <span>
                            {talentData?.measurement?.shoe_size?.[0]?.value
                              ? talentData?.measurement?.shoe_size?.[0]?.value
                              : talentData?.measurement?.shoe_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.pant_size?.length ? (
                        <p>
                          <span> Dress size : </span>
                          <span>
                            {talentData?.measurement?.dress_size?.[0]?.value
                              ? talentData?.measurement?.dress_size?.[0]?.value
                              : talentData?.measurement?.dress_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                    </>
                  ) : (
                    <>
                      {talentData?.measurement?.shirt_size?.length ? (
                        <p>
                          <span> Shirt size : </span>
                          <span>
                            {talentData?.measurement?.shirt_size?.[0]?.value
                              ? talentData?.measurement?.shirt_size?.[0]?.value
                              : talentData?.measurement?.shirt_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.shirt_size_f?.length ? (
                        <p>
                          <span> Shirt size (F) : </span>
                          <span>
                            {talentData?.measurement?.shirt_size_f?.[0]?.value
                              ? talentData?.measurement?.shirt_size_f?.[0]
                                  ?.value
                              : talentData?.measurement?.shirt_size_f?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.pant_size_waist?.length ? (
                        <p>
                          <span> Pant size (Waist) : </span>
                          <span>
                            {talentData?.measurement?.pant_size_waist?.[0]
                              ?.value
                              ? talentData?.measurement?.pant_size_waist?.[0]
                                  ?.value
                              : talentData?.measurement?.pant_size_waist?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.pant_size_length?.length ? (
                        <p>
                          <span> Pant size (Length) : </span>
                          <span>
                            {talentData?.measurement?.pant_size_length?.[0]
                              ?.value
                              ? talentData?.measurement?.pant_size_length?.[0]
                                  ?.value
                              : talentData?.measurement?.pant_size_length?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.pant_size?.length ? (
                        <p>
                          <span> Pant size : </span>
                          <span>
                            {talentData?.measurement?.pant_size?.[0]?.value
                              ? talentData?.measurement?.pant_size?.[0]?.value
                              : talentData?.measurement?.pant_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.pant_size_f?.length ? (
                        <p>
                          <span> Pant size (F) : </span>
                          <span>
                            {talentData?.measurement?.pant_size_f?.[0]?.value
                              ? talentData?.measurement?.pant_size_f?.[0]?.value
                              : talentData?.measurement?.pant_size_f?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.neck_size?.length ? (
                        <p>
                          <span> Neck Size : </span>
                          <span>{talentData?.measurement?.neck_size}</span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.chest_size ? (
                        <p>
                          <span> Chest Size : </span>
                          <span>{talentData?.measurement?.chest_size}</span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.jacket?.length ? (
                        <p>
                          <span> Jacket : </span>
                          <span>{talentData?.measurement?.jacket}</span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.shoe_size ? (
                        <p>
                          <span> Shoe Size : </span>
                          <span>
                            {talentData?.measurement?.shoe_size?.[0]?.value
                              ? talentData?.measurement?.shoe_size?.[0]?.value
                              : talentData?.measurement?.shoe_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}

                      {talentData?.measurement?.shoe_size_f ? (
                        <p>
                          <span> Shoe Size (F) : </span>
                          <span>
                            {talentData?.measurement?.shoe_size_f?.[0]?.value
                              ? talentData?.measurement?.shoe_size_f?.[0]?.value
                              : talentData?.measurement?.shoe_size_f?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.bra_size?.length ? (
                        <p>
                          <span> Bra size : </span>
                          <span>{talentData?.measurement?.bra_size}</span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.bra_cup?.length ? (
                        <p>
                          <span> Bra Cup : </span>
                          <span>{talentData?.measurement?.bra_cup}</span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.dress_size ? (
                        <p>
                          <span> Dress Size : </span>
                          <span>
                            {talentData?.measurement?.dress_size?.[0]?.label
                              ? talentData?.measurement?.dress_size?.[0]?.label
                              : talentData?.measurement?.dress_size?.[0]}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.dress_shirt_size?.length ? (
                        <p>
                          <span> Dress Shirt Size : </span>
                          <span>
                            {talentData?.measurement?.dress_shirt_size}
                          </span>
                        </p>
                      ) : undefined}
                      {talentData?.measurement?.dress_shirt_sleeve?.length ? (
                        <p>
                          <span> Dress Shirt Sleeves : </span>
                          <span>
                            {talentData?.measurement?.dress_shirt_sleeve}
                          </span>
                        </p>
                      ) : undefined}
                    </>
                  )}

                  <p>
                    <span>English level :</span>
                    <span class="cont_flex_flow">
                      {talentData?.postDetails?.english_level || ""}
                    </span>
                  </p>

                  {loggedInUser?.user_data?.user_role == 12 &&
                  talentData?.postDetails?.occupation ? (
                    <p>
                      <span>Occupation :</span>
                      <span>{talentData?.postDetails?.occupation || ""}</span>
                    </p>
                  ) : undefined}

                  {loggedInUser?.user_data?.user_role == 12 &&
                  talentData?.postDetails?.have_children ? (
                    <p>
                      <span>How many children do you have? :</span>
                      <span>{talentData?.postDetails?.have_children}</span>
                    </p>
                  ) : undefined}

                  {loggedInUser?.user_data?.user_role == 12 &&
                  talentData?.postDetails?.about_you_nobody_knows ? (
                    <p>
                      <span>Three things about you that no one knows :</span>
                      <span>
                        {talentData?.postDetails?.about_you_nobody_knows}
                      </span>
                    </p>
                  ) : undefined}

                  {loggedInUser?.user_data?.user_role == 12 &&
                  talentData?.postDetails?.reality_show_yourself_why ? (
                    <p>
                      <span>
                        What reality show can you see yourself on and why? :
                      </span>
                      <span>
                        {talentData?.postDetails?.reality_show_yourself_why}
                      </span>
                    </p>
                  ) : undefined}

                  {loggedInUser?.user_data?.user_role == 12 &&
                  talentData?.postDetails?.reality_show_what_when ? (
                    <p>
                      <span>
                        Have you ever been on a reality show? If yes, which show
                        and when? :
                      </span>
                      <span>
                        {talentData?.postDetails?.reality_show_what_when}
                      </span>
                    </p>
                  ) : undefined}

                  {talentData?.postDetails?.tattoo_location ? (
                    <p>
                      <span>Tattoo Location :</span>
                      <span class="cont_flex_flow">
                        {talentData?.postDetails?.tattoo_location || ""}
                      </span>
                    </p>
                  ) : undefined}

                  {talentData?.postDetails?.theater_exp ? (
                    <p>
                      <span>Theater Experience :</span>
                      <span class="cont_flex_flow">
                        {talentData?.postDetails?.theater_exp || ""}
                      </span>
                    </p>
                  ) : undefined}

                  {talentData?.postDetails?.member_of ? (
                    <p>
                      <span>Member of :</span>
                      <span class="cont_flex_flow">
                        {talentData?.postDetails?.member_of || ""}
                      </span>
                    </p>
                  ) : undefined}

                  {talentData?.postDetails?.list_awards ? (
                    <p>
                      <span>Awards :</span>
                      <span class="cont_flex_flow">
                        {talentData?.postDetails?.list_awards || ""}
                      </span>
                    </p>
                  ) : undefined}

                  {talentData?.postDetails?.edu_training ? (
                    <p>
                      <span>Education Or Training :</span>
                      <span class="cont_flex_flow">
                        {talentData?.postDetails?.edu_training || ""}
                      </span>
                    </p>
                  ) : undefined}

                  {talentData?.postDetails?.comm_exp ? (
                    <p>
                      <span>Commercial Experience :</span>
                      <span class="cont_flex_flow">
                        {talentData?.postDetails?.comm_exp || ""}
                      </span>
                    </p>
                  ) : undefined}
                </div>
              ) : undefined}
              {activeTab === 2 ? (
                <PortfolioData
                  portfolioData={portfolioData}
                  setSlideIndex2={setSlideIndex2}
                  setlightPortbox={setlightPortbox}
                  lightPortbox={lightPortbox}
                  slideIndex2={slideIndex2}
                  setlightbox={setlightbox}
                  setlightActorbox={setlightActorbox}
                  setSlideActorIndex={setSlideActorIndex}
                  setSlideIndex={setSlideIndex}
                  setSelectedImages={setSelectedImages}
                />
              ) : undefined}
              {activeTab === 3 ? (
                <PortfolioData
                  portfolioData={socialData}
                  setSlideIndex2={setSlideIndex2}
                  setlightPortbox={setlightPortbox}
                  lightPortbox={lightPortbox}
                  slideIndex2={slideIndex2}
                  setlightbox={setlightbox}
                  setlightActorbox={setlightActorbox}
                  setSlideActorIndex={setSlideActorIndex}
                  setSlideIndex={setSlideIndex}
                  setSelectedImages={setSelectedImages}
                />
              ) : undefined}
            </div>
            <div class="col-md-4">
              <div className="rightSideBar">
                {talentData?.userData?.user_role == 15 &&
                talentData?.measuremenrGallery?.length ? (
                  <h4>Actor Profile</h4>
                ) : undefined}
                {talentData?.userData?.user_role == 15 &&
                talentData?.measuremenrGallery?.length ? (
                  <div className="measumentSlider">
                    <Slider {...Actor}>
                      {talentData?.measuremenrGallery?.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setlightbox(false);
                              setSlideIndex(-1);
                              setSlideIndex2(-1);
                              setSelectedImages(-1);
                              setlightPortbox(false);
                              setlightActorbox(true);
                              setSlideActorIndex(index);
                            }}
                          >
                            <img src={item?.value} />
                            {item?.label == "Happy" ||
                            item?.label == "happy" ||
                            item?.label == "angry" ||
                            item?.label == "Angry" ||
                            item?.label == "Sad" ||
                            item?.label == "sad" ? (
                              <p>{item?.label || ""}</p>
                            ) : null}
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                ) : undefined}
                {talentData?.socialFollowers?.facebook_profile_link ||
                talentData?.socialFollowers?.youtube_profile_link ||
                talentData?.socialFollowers?.instagram_profile_link ||
                talentData?.socialFollowers?.twitter_profile_link ||
                talentData?.socialFollowers?.tiktok_profile_link ||
                talentData?.socialFollowers?.vimeo_profile_link ||
                talentData?.postDetails?.personal_website ? (
                  <h4>Social Media Handles</h4>
                ) : undefined}
                <ul class="socialIcon">
                  {talentData?.socialFollowers?.facebook_profile_link &&
                  talentData?.socialFollowers?.facebook_follower ? (
                    <li>
                      <a
                        href={
                          talentData?.socialFollowers?.facebook_profile_link
                        }
                        target="_blank"
                      >
                        <i class="fa fa-facebook"></i>{" "}
                        <span>{`${talentData?.socialFollowers?.facebook_follower} Followers`}</span>
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.instagram_profile_link &&
                  talentData?.socialFollowers?.instagram_follower ? (
                    <li>
                      <a
                        href={
                          talentData?.socialFollowers?.instagram_profile_link
                        }
                        target="_blank"
                      >
                        <i class="fa fa-instagram"></i>{" "}
                        <span>{`${talentData?.socialFollowers?.instagram_follower} Followers`}</span>
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.youtube_profile_link &&
                  talentData?.socialFollowers?.youtube_follower ? (
                    <li>
                      <a
                        href={talentData?.socialFollowers?.youtube_profile_link}
                        target="_blank"
                        title="0-9k Follower"
                      >
                        <i class="fa fa-youtube"></i>{" "}
                        <span>{`${talentData?.socialFollowers?.youtube_follower} Followers`}</span>
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.twitter_profile_link &&
                  talentData?.socialFollowers?.twitter_follower ? (
                    <li>
                      <a
                        href={talentData?.socialFollowers?.twitter_profile_link}
                        target="_blank"
                        title="0-9k Follower"
                      >
                        <i class="fa fa-twitter"></i>{" "}
                        <span>{`${talentData?.socialFollowers?.twitter_follower} Followers`}</span>
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.tiktok_profile_link &&
                  talentData?.socialFollowers?.tiktok_follower ? (
                    <li>
                      <a
                        href={talentData?.socialFollowers?.tiktok_profile_link}
                        target="_blank"
                        title="0-9k Follower"
                      >
                        <i class="fab fa-tiktok"></i>
                        <span>{`${talentData?.socialFollowers?.tiktok_follower} Followers`}</span>
                      </a>
                    </li>
                  ) : undefined}
                  {talentData?.socialFollowers?.vimeo_profile_link &&
                  talentData?.socialFollowers?.vimeo_follower ? (
                    <li>
                      <a
                        href={talentData?.socialFollowers?.vimeo_profile_link}
                        target="_blank"
                        title="0-9k Follower"
                      >
                        <i class="fa fa-vimeo"></i>{" "}
                        <span>{`${talentData?.socialFollowers?.vimeo_follower} Followers`}</span>
                      </a>
                    </li>
                  ) : undefined}

                  {talentData?.postDetails?.personal_website ? (
                    <li>
                      <a
                        href={talentData?.postDetails?.personal_website}
                        target="_blank"
                      >
                        <i class="fa fa-globe"></i>{" "}
                        {/* <span>{`${talentData?.socialFollowers?.vimeo_follower} Followers`}</span> */}
                      </a>
                    </li>
                  ) : undefined}
                  {/* personal_website */}
                </ul>

                {talentData?.postDetails?.video_link ? (
                  <h4>Video Link</h4>
                ) : undefined}
                <ul class="socialIcon">
                  {talentData?.postDetails?.video_link ? (
                    <li>
                      <a
                        href={talentData?.postDetails?.video_link}
                        target="_blank"
                      >
                        <i class="fa fa-video-camera"></i>{" "}
                        {/* <span>{`${talentData?.socialFollowers?.vimeo_follower} Followers`}</span> */}
                      </a>
                    </li>
                  ) : undefined}
                </ul>

                <h4>Report This Model</h4>
                <div class="wt-widgetcontent">
                  <form class="wt-formtheme wt-formreport">
                    <fieldset>
                      <div class="form-group">
                        <span class="wt-select">
                          <select
                            value={reportReason}
                            onChange={(e) => setReportReason(e.target.value)}
                          >
                            <option disabled value="">
                              Select Reason
                            </option>
                            {allOptionData?.userReportOptions?.length
                              ? allOptionData?.userReportOptions?.map(
                                  (item) => (
                                    <option
                                      key={item?.label}
                                      value={item?.label}
                                    >
                                      {item?.value}
                                    </option>
                                  )
                                )
                              : undefined}
                          </select>
                        </span>
                      </div>
                      <div class="form-group">
                        <textarea
                          class="form-control"
                          placeholder="Description"
                          value={reportDescription}
                          onChange={(e) => setReportDescription(e.target.value)}
                        ></textarea>
                      </div>
                      <div class="form-group wt-btnarea">
                        <a
                          onClick={handleReport}
                          href="javascrip:void(0);"
                          class="wt-btn"
                        >
                          Submit
                        </a>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="wt-userprofileholder availability">
                <h3 className="sTitle">
                  Availability of{" "}
                  {`${talentData?.userData?.first_name || ""} ${
                    talentData?.userData?.last_name || ""
                  }`}
                </h3>
                <CalendarCompo />
              </div>
            </div>
          </div>
        </div>
      </div>

      {loginModalVisible && (
        <LoginAlert
          title="hhiiii"
          text="hdfj"
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}

      {isFeedback && (
        <FeedbackModal
          setShow={setIsFeedback}
          show={isFeedback}
          userFeedback={userFeedback}
        />
      )}

      {bookJobModal && (
        <BookTalent
          show={bookJobModal}
          setShow={setBookJobModal}
          receiverId={id}
          setLoading={setLoading}
        />
      )}

      <Footer />
    </div>
  );
};
