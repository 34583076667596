import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import { Header, Footer, Loader } from "../../Components";
import SidebarLayout from "../../layout/SidebarLayout";
import {
  getLatestProposals,
  getModelProjects,
  getPostedListing,
  getProposalListing,
} from "../../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import { routeName } from "../../Utility";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Constants";

const ClientProjects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobsList, setJobsList] = useState([]);
  const [tab, setTab] = useState("posted");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  useEffect(() => {
    getAllJobsList("posted");

    setTab("posted");
  }, []);

  const getAllJobsList = async (type) => {
    let userID = await getData(storageKey?.USER_ID);
    let body = {
      client_id: userID,
      job_status: type,
    };
    let res = await dispatch(getPostedListing(body));
    console.log("cddv cdcdcdcdc", res);
    if (res?.status == 200) {
      setTab(type);
      setJobsList(res?.results);
    } else {
      setJobsList([]);
    }
  };
  const options = [
    {
      name: "Posted Jobs",
      type: "posted",
    },
    {
      name: "Ongoing Jobs",
      type: "ongoing",
    },
    {
      name: "Completed Jobs",
      type: "completed",
    },
    {
      name: "Cancelled Jobs",
      type: "cancelled",
    },
  ];

  return (
    <>
      <SidebarLayout>
        <div class="dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="posted"
              id="jobs"
              activeKey={tab}
              onSelect={getAllJobsList}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  <div class="wt-managejobcontent">
                    {jobsList?.map((ele, ind) => {
                      return (
                        <>
                          <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                            <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                              <div class="wt-contenthead">
                                <div class="wt-title">
                                  {ele?.post_meta_details?.project_status && (
                                    <a
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <i
                                        style={{
                                          color:
                                            ele?.post_meta_details
                                              ?.project_status == "completed"
                                              ? Colors?.green
                                              : ele?.post_meta_details
                                                  ?.project_status ==
                                                "cancelled"
                                              ? Colors?.red
                                              : Colors?.blue,
                                        }}
                                        class={
                                          ele?.post_meta_details
                                            ?.project_status == "completed"
                                            ? "fa fa-check-circle"
                                            : ele?.post_meta_details
                                                ?.project_status == "cancelled"
                                            ? "fa fa-times-circle"
                                            : "fa fa-check-fa fa-spinner"
                                        }
                                      ></i>
                                      {"  "}
                                      {ele?.post_meta_details?.project_status}
                                    </a>
                                  )}
                                  <h2>{ele?.profile?.post_title}</h2>
                                </div>
                                <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                  <li>
                                    <span>
                                      <i class="far fa-flag"></i> Location :{" "}
                                      {ele?.post_meta_details?.country} |{" "}
                                      {ele?.post_meta_details?.city}
                                    </span>
                                  </li>
                                  <li>
                                    <a class="wt-clicksavefolder">
                                      <i class="far fa-folder"></i> Type:{" "}
                                      {ele?.post_meta_details?.project_type}
                                    </a>
                                  </li>
                                  <li>
                                    <span class="wt-dashboradclock">
                                      <i class="fa fa-usd"></i> Hourly Rate :{" "}
                                      {ele?.post_meta_details?.hourly_rate}
                                    </span>
                                  </li>
                                </ul>
                                <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                  <li>
                                    <span class="wt-dashboradclock">
                                      <i class="far fa-calendar"></i> Start Date
                                      : {ele?.post_meta_details?.starting_date}
                                    </span>
                                  </li>
                                  <li>
                                    <span class="wt-dashboradclock">
                                      <i class="far fa-calendar"></i> End Date :{" "}
                                      {ele?.post_meta_details?.end_date}
                                    </span>
                                  </li>
                                </ul>
                                <div class="wt-btnarea-btn">
                                  <span
                                    class="wt-bt"
                                    onClick={() => {
                                      const queryParams = new URLSearchParams({
                                        id: ele?.profile?.ID,
                                      });
                                      navigate(
                                        `${
                                          routeName.JOB_DETAILS
                                        }?${queryParams.toString()}`
                                      );
                                    }}
                                  >
                                    View Job
                                  </span>
                                  {item?.type == "cancelled" ? (
                                    <span class="wt-bt">Repost</span>
                                  ) : null}
                                  
                                  {item?.type == "ongoing" ? (
                                    <span class="wt-bt">Complete & Pay</span>
                                  ) : null}
                                  {item?.type == "posted" && (loggedInUser?.user_data?.user_role === 12 || loggedInUser?.user_data?.user_role === 14)  ? (
                                    <>
                                      <span class="wt-bt">Edit</span>
                                      <span class="wt-bt">Delete</span>
                                    </>
                                  ) : null}
                                  {item?.type == "ongoing" ||
                                  item?.type == "completed" ? (
                                    <>
                                      <span
                                        class="wt-bt"
                                        onClick={() => {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: ele?.profile?.ID,
                                            });
                                          navigate(
                                            `${
                                              routeName.CHAT
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        Message
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                              <div class="wt-rightarea">
                                {item?.type == "posted" ? (
                                  <div class="wt-hireduserstatus">
                                    <h4>
                                      {ele?.post_meta_details?.proposal_count}
                                    </h4>
                                    <span>Proposal count</span>

                                    <h6
                                      class="wt-dashboradclock"
                                      style={{ color: Colors?.blue }}
                                      onClick={() => {
                                        const queryParams = new URLSearchParams(
                                          {
                                            id: ele?.profile?.ID,
                                          }
                                        );
                                        navigate(
                                          `${
                                            routeName.PROPOSALS
                                          }?${queryParams.toString()}`
                                        );
                                      }}
                                    >
                                      View Proposals
                                    </h6>
                                  </div>
                                ) : (
                                  <div class="wt-hireduserstatus">
                                    <h4>Hired</h4>
                                    <ul class="wt-hireduserimgs">
                                      {ele?.project_hired_users?.map(
                                        (user, i) => {
                                          return (
                                            <>
                                              <li>
                                                <figure>
                                                  {user?.proposal_details
                                                    ?.profile_image ? (
                                                    <img
                                                      src={
                                                        user?.proposal_details
                                                          ?.profile_image
                                                      }
                                                      title={
                                                        user?.proposal_details
                                                          ?.display_name
                                                      }
                                                      alt="img description"
                                                      class="mCS_img_loaded"
                                                    />
                                                  ) : (
                                                    <i class="fas fa-user-circle"></i>
                                                  )}
                                                </figure>
                                              </li>
                                            </>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </SidebarLayout>
    </>
  );
};

export default ClientProjects;
