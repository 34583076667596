export const NAVIGATOR_STATUS = "NAVIGATOR_STATUS";

export const USER_REGISTER_LOADING = "USER_REGISTER_LOADING";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

export const PROFILE_SETUP_LOADING = "PROFILE_SETUP_LOADING";
export const PROFILE_SETUP_SUCCESS = "PROFILE_SETUP_SUCCESS";
export const PROFILE_SETUP_ERROR = "PROFILE_SETUP_ERROR";

export const PHOTOGRAPHER_REGISTER_LOADING = "PHOTOGRAPHER_REGISTER_LOADING";
export const PHOTOGRAPHER_REGISTER_SUCCESS = "PHOTOGRAPHER_REGISTER_SUCCESS";
export const PHOTOGRAPHER_REGISTER_ERROR = "PHOTOGRAPHER_REGISTER_ERROR";

export const UPDATE_PHOTOGRAPHER_PROFILE_LOADING =
  "UPDATE_PHOTOGRAPHER_PROFILE_LOADING";
export const UPDATE_PHOTOGRAPHER_PROFILE_SUCCESS =
  "UPDATE_PHOTOGRAPHER_PROFILE_SUCCESS";
export const UPDATE_PHOTOGRAPHER_PROFILE_ERROR =
  "UPDATE_PHOTOGRAPHER_PROFILE_ERROR";

export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";

export const REGISTER_TOKEN_LOADING = "REGISTER_TOKEN_LOADING";
export const REGISTER_TOKEN_SUCCESS = "REGISTER_TOKEN_SUCCESS";
export const REGISTER_TOKEN_ERROR = "REGISTER_TOKEN_ERROR";

export const USER_LOGOUT_LOADING = "USER_LOGOUT_LOADING";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";

export const GET_OPTIONS_DATA_LOADING = "GET_OPTIONS_DATA_LOADING";
export const GET_OPTIONS_DATA_SUCCESS = "GET_OPTIONS_DATA_SUCCESS";
export const GET_OPTIONS_DATA_ERROR = "GET_OPTIONS_DATA_ERROR";

export const GET_USER_DETAIL_LOADING = "GET_USER_DETAIL_LOADING";
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS";
export const GET_USER_DETAIL_ERROR = "GET_USER_DETAIL_ERROR";

export const UPLOAD_GALLERY_PROFILE_LOADING = "UPLOAD_GALLERY_PROFILE_LOADING";
export const UPLOAD_GALLERY_PROFILE_SUCCESS = "UPLOAD_GALLERY_PROFILE_SUCCESS";
export const UPLOAD_GALLERY_PROFILE_ERROR = "UPLOAD_GALLERY_PROFILE_ERROR";

export const GET_PACKAGES_LOADING = "GET_PACKAGES_LOADING";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_ERROR = "GET_PACKAGES_ERROR";

export const BUY_SUBSCRIPTION_LOADING = "BUY_SUBSCRIPTION_LOADING";
export const BUY_SUBSCRIPTION_SUCCESS = "BUY_SUBSCRIPTION_SUCCESS";
export const BUY_SUBSCRIPTION_ERROR = "BUY_SUBSCRIPTION_ERROR";

export const SEND_VARIFICATION_LOADING = "SEND_VARIFICATION_LOADING";
export const SEND_VARIFICATION_SUCCESS = "SEND_VARIFICATION_SUCCESS";
export const SEND_VARIFICATION_ERROR = "SEND_VARIFICATION_ERROR";

export const SEND_VARIFICATION_EMAIL_LOADING =
  "SEND_VARIFICATION_EMAIL_LOADING";
export const SEND_VARIFICATION_EMAIL_SUCCESS =
  "SEND_VARIFICATION_EMAIL_SUCCESS";
export const SEND_VARIFICATION_EMAIL_ERROR = "SEND_VARIFICATION_EMAIL_ERROR";

export const OTP_VERIFICATION_LOADING = "OTP_VERIFICATION_LOADING";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_ERROR = "OTP_VERIFICATION_ERROR";

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const FORGET_PASSWORD_LOADING = "FORGET_PASSWORD_LOADING";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";

export const IDENTITY_VERIFICATION_LOADING = "IDENTITY_VERIFICATION_LOADING";
export const IDENTITY_VERIFICATION_SUCCESS = "IDENTITY_VERIFICATION_SUCCESS";
export const IDENTITY_VERIFICATION_ERROR = "IDENTITY_VERIFICATION_ERROR";

export const GET_IDENTITY_VERIFICATION_LOADING = "GET_IDENTITY_VERIFICATION_LOADING";
export const GET_IDENTITY_VERIFICATION_SUCCESS = "GET_IDENTITY_VERIFICATION_SUCCESS";
export const GET_IDENTITY_VERIFICATION_ERROR = "GET_IDENTITY_VERIFICATION_ERROR";

export const REJECT_IDENTITY_VERIFICATION_LOADING = "REJECT_IDENTITY_VERIFICATION_LOADING";
export const REJECT_IDENTITY_VERIFICATION_SUCCESS = "REJECT_IDENTITY_VERIFICATION_SUCCESS";
export const REJECT_IDENTITY_VERIFICATION_ERROR = "REJECT_IDENTITY_VERIFICATION_ERROR";

export const REMOVE_IMAGE_LOADING = "REMOVE_IMAGE_LOADING";
export const REMOVE_IMAGE_SUCCESS = "REMOVE_IMAGE_SUCCESS";
export const REMOVE_IMAGE_ERROR = "REMOVE_IMAGE_ERROR";

// to store the logged in user detail
export const LOGIN_USER_DATA = "LOGIN_USER_DATA";
export const LOGIN_STEP_DATA = 'LOGIN_STEP_DATA';
export const REGISTER_DATA = 'REGISTER_DATA';

export const LOGIN_REDIRECT_LOADING = "LOGIN_REDIRECT_LOADING";
export const LOGIN_REDIRECT_SUCCESS = "LOGIN_REDIRECT_SUCCESS";
export const LOGIN_REDIRECT_ERROR = "LOGIN_REDIRECT_ERROR";