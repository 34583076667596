import React, { useState } from "react";

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  const handleMessageSend = () => {
    if (newMessage.trim() === "") {
      // Don't send empty messages
      return;
    }

    // Add the new message to the messages array
    setMessages([...messages, { text: newMessage, sender: "user" }]);

    // Clear the input field
    setNewMessage("");
  };

  return (
    <div>
      <div className="messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={
              message.sender === "user" ? "user-message" : "other-message"
            }
          >
            {message.text}
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleMessageSend}>Send</button>
      </div>

      <section class="wt-haslayout wt-dbsectionspace">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-12">
            <div class="wt-dashboardbox wt-messages-holder">
              <div class="wt-dashboardboxtitle">
                <h2>Messages</h2>
              </div>
              <div class="wt-dashboardboxtitle wt-titlemessages">
                <a href="javascript:void(0);" class="wt-back">
                  <i class="ti-arrow-left"></i>
                </a>
                <div class="wt-userlogedin">
                  <figure class="wt-userimg">
                    <img src="images/user-img.jpg" alt="image description" />
                  </figure>
                  <div class="wt-username">
                    <h3>
                      <i class="fa fa-check-circle"></i> Louanne Mattioli
                    </h3>
                    <span>Amento Tech</span>
                  </div>
                </div>
                <a href="javascript:void(0);" class="wt-viewprofile">
                  View Profile
                </a>
              </div>
              <div class="wt-dashboardboxcontent wt-dashboardholder wt-offersmessages">
                <ul>
                  <li>
                    <form class="wt-formtheme wt-formsearch">
                      <fieldset>
                        <div class="form-group">
                          <input
                            type="text"
                            name="Location"
                            class="form-control"
                            placeholder="Search Here"
                          />
                          <a href="javascrip:void(0);" class="wt-searchgbtn">
                            <i class="lnr lnr-magnifier"></i>
                          </a>
                        </div>
                      </fieldset>
                    </form>
                    <div class="wt-verticalscrollbar wt-dashboardscrollbar mCustomScrollbar _mCS_2 mCS_no_scrollbar">
                      <div
                        id="mCSB_2"
                        class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                        tabindex="0"
                        style="max-height: none;"
                      >
                        <div
                          id="mCSB_2_container"
                          class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                          style="position: relative; top: 0px; left: 0px;"
                          dir="ltr"
                        >
                          <div class="wt-ad wt-dotnotification wt-active">
                            <figure>
                              <img
                                src="images/messages/img-01.jpg"
                                alt="image description"
                                class="mCS_img_loaded"
                              />
                            </figure>
                            <div class="wt-adcontent">
                              <h3>Reta Milnes </h3>
                              <span>
                                Consectetur adipisicing elit sed do...
                              </span>
                            </div>
                          </div>
                          <div class="wt-ad wt-dotnotification">
                            <figure>
                              <img
                                src="images/messages/img-02.jpg"
                                alt="image description"
                                class="mCS_img_loaded"
                              />
                            </figure>
                            <div class="wt-adcontent">
                              <h3>Jed Loeffler</h3>
                              <span>
                                Consectetur adipisicing elit sed do...
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          id="mCSB_2_scrollbar_vertical"
                          class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
                          style="display: none;"
                        >
                          <div class="mCSB_draggerContainer">
                            <div
                              id="mCSB_2_dragger_vertical"
                              class="mCSB_dragger"
                              style="position: absolute; min-height: 30px; display: block; height: 0px; max-height: 750px; top: 0px;"
                            >
                              <div
                                class="mCSB_dragger_bar"
                                style="line-height: 30px;"
                              ></div>
                            </div>
                            <div class="mCSB_draggerRail"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="wt-chatarea">
                      <div class="wt-messages wt-verticalscrollbar wt-dashboardscrollbar mCustomScrollbar _mCS_3">
                        <div
                          id="mCSB_3"
                          class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                          tabindex="0"
                          style="max-height: none;"
                        >
                          <div
                            id="mCSB_3_container"
                            class="mCSB_container"
                            style="position: relative; top: -12px; left: 0px;"
                            dir="ltr"
                          >
                            <div class="wt-offerermessage">
                              <figure>
                                <img
                                  src="images/messages/img-12.jpg"
                                  alt="image description"
                                  class="mCS_img_loaded"
                                />
                              </figure>
                              <div class="wt-description">
                                <p>
                                  Consectetur adipisicing elit sei do eiusmod
                                  tempor incididunt labore et dolore.
                                </p>
                                <div class="clearfix"></div>
                                <time datetime="2017-08-08">
                                  January 12th, 2011
                                </time>
                              </div>
                            </div>
                            <div class="wt-memessage wt-readmessage">
                              <figure>
                                <img
                                  src="images/messages/img-11.jpg"
                                  alt="image description"
                                  class="mCS_img_loaded"
                                />
                              </figure>
                              <div class="wt-description">
                                <p>
                                  Eiusmod tempor incididunt labore et dolore
                                  magna aliqiu enim ad minim veniam qiuisru
                                  exercitation ullamco laborisen nisi ut aliquip
                                  exea.
                                </p>
                                <div class="clearfix"></div>
                                <p>
                                  <a
                                    href="https://themeforest.net"
                                    target="_blank"
                                  >
                                    https://themeforest.net
                                  </a>
                                </p>
                                <div class="clearfix"></div>
                                <p>It that ok?</p>
                                <div class="clearfix"></div>
                                <time datetime="2017-08-08">
                                  Jun 28, 2017 09:30
                                </time>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                            <div class="wt-offerermessage">
                              <figure>
                                <img
                                  src="images/messages/img-12.jpg"
                                  alt="image description"
                                  class="mCS_img_loaded"
                                />
                              </figure>
                              <div class="wt-description">
                                <div class="clearfix"></div>
                                <p>
                                  Consectetur adipisicing elit sei do eiusmod
                                  tempor incididunt labore et dolore.
                                </p>
                                <div class="clearfix"></div>
                                <time datetime="2017-08-08">
                                  January 12th, 2011
                                </time>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                            <div class="wt-memessage wt-readmessage">
                              <figure>
                                <img
                                  src="images/messages/img-11.jpg"
                                  alt="image description"
                                  class="mCS_img_loaded"
                                />
                              </figure>
                              <div class="wt-description">
                                <div class="clearfix"></div>
                                <p>
                                  Eiusmod tempor incididunt labore et dolore
                                  magna aliqiu enim ad minim veniam qiuisru
                                  exercitation ullamco laborisen nisi ut aliquip
                                  exea.
                                </p>
                                <div class="clearfix"></div>
                                <p>
                                  <a
                                    href="https://themeforest.net"
                                    target="_blank"
                                  >
                                    https://themeforest.net
                                  </a>
                                </p>
                                <div class="clearfix"></div>
                                <p>It that ok?</p>
                                <div class="clearfix"></div>
                                <time datetime="2017-08-08">
                                  Jun 28, 2017 09:30
                                </time>
                                <div class="clearfix"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="mCSB_3_scrollbar_vertical"
                            class="mCSB_scrollTools mCSB_3_scrollbar mCS-light mCSB_scrollTools_vertical"
                            style="display: block;"
                          >
                            <div class="mCSB_draggerContainer">
                              <div
                                id="mCSB_3_dragger_vertical"
                                class="mCSB_dragger"
                                style="position: absolute; min-height: 30px; display: block; height: 640px; max-height: 642px; top: 12px;"
                              >
                                <div
                                  class="mCSB_dragger_bar"
                                  style="line-height: 30px;"
                                ></div>
                              </div>
                              <div class="mCSB_draggerRail"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="wt-replaybox">
                        <div class="form-group">
                          <textarea
                            class="form-control"
                            name="reply"
                            placeholder="Type message here"
                          ></textarea>
                        </div>
                        <div class="wt-iconbox">
                          <i class="lnr lnr-thumbs-up"></i>
                          <i class="lnr lnr-thumbs-down"></i>
                          <i class="lnr lnr-smile"></i>
                          <a href="javascript:void(0);" class="wt-btnsendmsg">
                            Send
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Chat;
