// import React, { useState } from 'react';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';

// const CalendarCompo = () => {
//   const [selectedDates, setSelectedDates] = useState([]);

//   const handleDateChange = (date) => {
//     setSelectedDates([...selectedDates, date]);
//   };

//   return (
//     <div>
//       <h2>Selected Dates:</h2>
//       <ul>
//         {selectedDates.map((date, index) => (
//           <li key={index}>{date.toLocaleString()}</li>
//         ))}
//       </ul>
//       <Calendar
//         onChange={handleDateChange}
//         value={selectedDates}
//         selectRange={true}

//       />
//     </div>
//   );
// };

// export default CalendarCompo;

import React, { useState } from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [view, setView] = useState(Views.DAY);
  const [events, setEvents] = useState([]);
  const eventsArr = [
    {
      "id": "239",
      "title": "Booked",
      "url": "",
      "status": "hours",
      "start": new Date("2024-04-17T07:54"),
      "end": new Date("2024-04-17T10:55"),
      "allday": false
    },
    // Add more events here
  ];

  const handleViewChange = (newView) => {
    setView(newView);
  };

  // Function to check if a date is disabled based on events data
  const dayPropGetter = date => {
    const eventForDate = eventsArr.find(event => moment(event.start).isSame(date, 'day'));

    if (eventForDate) {
      return {
        className: 'event-date',
        title: eventForDate.title
      };
    }

    return {};
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['day', 'week', 'month']}
        view={view}
        onView={(view) => handleViewChange(view)}
        onSelectSlot={(slotInfo) => console.log('Selected slot: ', slotInfo)}
        style={{ height: 500 }}
        dayPropGetter={dayPropGetter}
        eventPropGetter={(event) => ({
          className: 'custom-event',
          // You can add more props here if needed
        })}
      />
    </div>
  );
};

export default MyCalendar;



