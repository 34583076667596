import * as url from "../../API Services/Url";
import * as Services from "../../API Services/services";
import {
  userLoginLoading,
  userLoginSuccess,
  userLoginError,
  registerTokenLoading,
  registerTokenSuccess,
  registerTokenError,
  userLogoutLoading,
  userLogoutSuccess,
  userLogoutError,
  userRegisterLoading,
  userRegisterSuccess,
  userRegisterError,
  photographerRegisterLoading,
  photographerRegisterSuccess,
  photographerRegisterError,
  updatePhotographerProfileLoading,
  updatePhotographerProfileSuccess,
  updatePhotographerProfileError,
  profileSetupLoading,
  profileSetupSuccess,
  profileSetupError,
  navigatorStatus,
  getOptionsDataLoading,
  getOptionsDataSuccess,
  getOptionsDataError,
  getUserDetailLoading,
  getUserDetailSuccess,
  getUserDetailError,
  uploadGalleryProfileLoading,
  uploadGalleryProfileSuccess,
  uploadGalleryProfileError,
  getPackagesLoading,
  getPackagesSuccess,
  getPackagesError,
  buySubscriptionLoading,
  buySubscriptionSuccess,
  buySubscriptionError,
  sendVerficationError,
  sendVerficationLoading,
  sendVerficationSuccess,
  sendVerficationEmailError,
  sendVerficationEmailLoading,
  sendVerficationEmailSuccess,
  otpVerifyLoading,
  otpVerifySuccess,
  otpVerifyError,
  resetPasswordLoading,
  resetPasswordSuccess,
  resetPasswordError,
  forgetPasswordLoading,
  forgetPasswordSuccess,
  forgetPasswordError,
  identityVerificationLoading,
  identityVerificationSuccess,
  identityVerificationError,
  removeImageLoading,
  removeImageSuccess,
  removeImageError,
  loginRedirectLoading,
  loginRedirectSuccess,
  loginRedirectError,
  getIdentityVerifyLoading,
  getIdentityVerifySuccess,
  getIdentityVerifyError,
  rejectIdentityVerifyLoading,
  rejectIdentityVerifySuccess,
  rejectIdentityVerifyError,
} from "../Actions/AuthActions";
import {
  clearData,
  getData,
  storageKey,
  storeData,
} from "../../Utility/Storage";
import { routeName, showToast } from "../../Utility";

// Authentication APIs
export const userRegister = (body, url) => async (dispatch) => {
  dispatch(userRegisterLoading(true));
  try {
    const response = await Services.post(
      url + "?apiKey=apiKey",
      "",
      body,
      true
    );
    if (response.status == 200) {
      let data =
        response?.results?.length == 1
          ? response?.results[0]
          : response?.results;
      dispatch(userRegisterSuccess(false, { registrationData: data }));
      storeData(storageKey?.USER_ID, JSON?.stringify(data?.user_id));
      storeData(storageKey?.USER_ROLE, JSON.stringify(data?.user_role));
      storeData(storageKey?.PROFILE_ID, JSON.stringify(data?.profile_id));

      storeData(storageKey?.AUTH_TOKEN, data?.access_token);
      showToast("Saved !", "success");
    } else {
      dispatch(userRegisterSuccess(false));
    }
    return response;
  } catch (error) {
    console.log("userRegister error--", error);

    dispatch(userRegisterError(false));
    return { message: error };
  }
};

export const photographerRegister = (body) => async (dispatch) => {
  dispatch(photographerRegisterLoading(true));
  try {
    const response = await Services.post(
      url.PHOTOGRAPHER_REGISTER + "?apiKey=apiKey",
      "",
      body,
      true
    );
    if (response?.status === 200) {
      dispatch(
        photographerRegisterSuccess(false, {
          registrationData: response?.results,
        })
      );
      storeData(storageKey?.USER_ID, response?.results?.user_id);
      storeData(
        storageKey?.USER_ROLE,
        JSON.stringify(response?.results?.user_role)
      );
      storeData(
        storageKey?.PROFILE_ID,
        JSON.stringify(response?.results?.profile_id)
      );
      storeData(storageKey?.AUTH_TOKEN, response?.results?.access_token);
      if (response?.status == 200) {
        showToast("Saved !", "success");
      }
    }
    dispatch(photographerRegisterSuccess(false));
    return response;
  } catch (error) {
    console.log("photographerRegister error--", error);
    dispatch(photographerRegisterError(false));
    return { message: error };
  }
};

export const updatePhotographerProfile = (body) => async (dispatch) => {
  dispatch(updatePhotographerProfileLoading(true));
  try {
    const response = await Services.post(
      url.UPDATE_PHOTOGRAPHER_REGISTER + "?apiKey=apiKey",
      "",
      body
    );
    if (response.data) {
      dispatch(updatePhotographerProfileSuccess(false));
    }
    dispatch(updatePhotographerProfileSuccess(false));
    return response;
  } catch (error) {
    console.log("updatePhotographerProfile error--", error);
    dispatch(updatePhotographerProfileError(false));
    return { message: error };
  }
};
export const profileSetup = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  dispatch(profileSetupLoading(true));
  try {
    const response = await Services.post(
      url.PROFILE_SETUP + "?apiKey=apiKey",
      "",
      body,
      true
    );
    if (response.status == 200) {
      dispatch(profileSetupSuccess(false));
    }

    dispatch(profileSetupSuccess(false));
    return response;
  } catch (error) {
    dispatch(profileSetupError(false));
    return { message: error };
  }
};
export const userLogin = (body) => async (dispatch) => {
  dispatch(userLoginLoading(true));
  try {
    const response = await Services.post(
      url.USER_LOGIN + "?apiKey=apiKey",
      "",
      body
    );
    if (response.status == 200) {
      storeData(storageKey.USER_ID, response?.results?.id);
      dispatch(userLoginSuccess(false, response?.results?.user_role));
      dispatch(
        userRegisterSuccess(false, { registrationData: response?.results })
      );
      storeData(storageKey?.AUTH_TOKEN, response?.results?.access_token);
      storeData(
        storageKey?.USER_ROLE,
        JSON.stringify(response?.results?.user_role)
      );
      storeData(
        storageKey?.APPROVAL_STATUS,
        JSON?.stringify(response?.results?.profile_approval)
      );
      storeData(
        storageKey?.PROFILE_ID,
        JSON?.stringify(response?.results?.profile_id)
      );
      storeData(
        storageKey?.USER_STATUS,
        JSON?.stringify(response?.results?.completed_step)
      );
      storeData(
        storageKey?.PACKAGE_ID,
        JSON.stringify(response?.results?.packages_id)
      );
    } else {
      dispatch(userLoginSuccess(false));
    }
    return response;
  } catch (error) {
    console.log("userLogin error--", error);
    dispatch(userLoginError(false));
    return { message: error };
  }
};

export const userLoginRedirect = (body) => async (dispatch) => {
  dispatch(loginRedirectLoading(true));
  try {
    const response = await Services.post(
      url.LOGIN_REDIRECT + "?apiKey=apiKey",
      "",
      body,
      true
    );
    if (response.status == 200) {
      storeData(storageKey.USER_ID, response?.results?.id);
      dispatch(loginRedirectSuccess(false, response?.results?.user_role));
      dispatch(
        userRegisterSuccess(false, { registrationData: response?.results })
      );
      storeData(storageKey?.AUTH_TOKEN, response?.results?.access_token);
      storeData(
        storageKey?.USER_ROLE,
        JSON.stringify(response?.results?.user_role)
      );
      storeData(
        storageKey?.APPROVAL_STATUS,
        JSON?.stringify(response?.results?.profile_approval)
      );
      storeData(
        storageKey?.PROFILE_ID,
        JSON?.stringify(response?.results?.profile_id)
      );
      storeData(
        storageKey?.USER_STATUS,
        JSON?.stringify(response?.results?.completed_step)
      );
      storeData(
        storageKey?.PACKAGE_ID,
        JSON.stringify(response?.results?.packages_id)
      );
    } else {
      dispatch(loginRedirectError(false));
    }
    return response;
  } catch (error) {
    console.log("userLogin error--", error);
    dispatch(loginRedirectError(false));
    return { message: error };
  }
};

export const registerToken = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  dispatch(registerTokenLoading(true));
  try {
    const response = await Services.post(
      url.REGISTER_TOKEN + "?apiKey=apiKey",
      token,
      body,
      true
    );
    if (response.status == 200) {
      dispatch(registerTokenSuccess(false));
    } else {
      dispatch(registerTokenSuccess(false));
    }
    return response;
  } catch (error) {
    console.log("registerToken error--", error);
    dispatch(registerTokenError(false));
    return { message: error };
  }
};

export const removeToken = (body) => async (dispatch) => {
  try {
    const response = await Services.post(
      url.REMOVE_TOKEN + "?apiKey=apiKey",
      "",
      body,
      true
    );
    if (response.status == 200) {
    }
    return response;
  } catch (error) {
    console.log("removeToken error--", error);
    return { message: error };
  }
};

export const userLogout = (body) => async (dispatch) => {
  dispatch(userLogoutLoading(true));
  try {
    const response = await Services.post(
      url.USER_LOGOUT + "?apiKey=apiKey",
      "",
      body
    );
    if (response.status == 200) {
      clearData();
      dispatch(userLogoutSuccess(false));
      return response;
    } else {
      clearData();
    }
    dispatch(userLogoutSuccess(false));
  } catch (error) {
    console.log("userLogout error--", error);
    dispatch(userLogoutError(false));
    return { message: error };
  }
};

export const getOptionsData = () => async (dispatch) => {
  dispatch(getOptionsDataLoading(true));
  try {
    const response = await Services.get(
      url.GET_OPTIONS_DATA + "?apiKey=apiKey",
      "",
      true
    );
    if (response.status == 200) {
      dispatch(
        getOptionsDataSuccess(false, {
          femaleOptions: response?.results?.group_62749b609360c,
          maleOptions: response?.results?.group_62749a513bf1a,
          childOptions: response?.results?.group_63181d08b6357,
          otherOptions: response?.results?.group_627497cf304a6,
          socialMediaOptions: response?.results?.group_62849b4520284,
          postJobOptions: response?.results?.group_628774e8ce197,
          filterOption: response?.results?.group_62e2bb6565f61,
          languages: response?.results?.languages,
          englishLevel: response?.results?.english_level,
          actorRelation: response?.results?.group_63181d08b7031,
          postReportOptions: response?.results?.post_report_options,
          userReportOptions: response?.results?.user_report_options,
          pay_type: response?.results?.pay_type,
          when_you_expect_payment: response?.results?.when_you_expect_payment,
          time_zone: response?.results?.time_zone,
          project_location_type: response?.results?.project_location_type,
          project_levels: response?.results?.project_levels,
          job_durations: response?.results?.job_durations,
          identityVerify: response?.results?.identity_document_type,
          // job_durations
        })
      );
    } else {
      dispatch(getOptionsDataError(false));
    }
    return response;
  } catch (error) {
    console.log("getOptionsData error--", error);
    dispatch(getOptionsDataError(false));
    return { message: error };
  }
};

export const getUserDetail = (body) => async (dispatch) => {
  dispatch(getUserDetailLoading(true));
  let token = await getData(storageKey?.AUTH_TOKEN);
  try {
    const response = await Services.post(
      url.GET_USER_DETAIL + "?apiKey=apiKey",
      "",
      body,
      true,
      false
    );

    if (response.status == 200) {
      console.log("getUserDetail response--", response);
      dispatch(
        getUserDetailSuccess(false, {
          userData: response?.results,
        })
      );
      return response;
    } else {
      dispatch(getUserDetailSuccess(false, { userData: {} }));
    }
  } catch (error) {
    console.log("getUserDetail error--", error);
    dispatch(getUserDetailError(false));
    return { message: error };
  }
};

export const uploadGalleryProfile = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  dispatch(uploadGalleryProfileLoading(true));
  try {
    const response = await Services.formDataPost(
      url.UPLOAD_GALLERY_PROFILE + "?apiKey=apiKey",
      "",
      body,
      true
    );
    if (response.status == 200) {
      dispatch(uploadGalleryProfileSuccess(false));
      return response;
    } else {
      dispatch(uploadGalleryProfileSuccess(false));
    }
  } catch (error) {
    console.log("uploadGalleryProfile error--", error);
    dispatch(uploadGalleryProfileError(false));
    return { message: error };
  }
};

export const getPackages = (body) => async (dispatch) => {
  dispatch(getPackagesLoading(true));
  try {
    const response = await Services.get(
      url.GET_PACKAGES + "?apiKey=apiKey",
      "",
      true
    );
    if (response.status == 200) {
      dispatch(getPackagesSuccess(false));
      return response;
    }
    dispatch(getPackagesSuccess(false));
  } catch (error) {
    console.log("getPackages error--", error);
    dispatch(getPackagesError(false));
    return { message: error };
  }
};

export const buySubscription = (body) => async (dispatch) => {
  dispatch(buySubscriptionLoading(true));
  try {
    const response = await Services.get(
      url.BUY_SUBSCRIPTION + "?apiKey=apiKey",
      "",
      true
    );
    if (response.status == 200) {
      dispatch(buySubscriptionSuccess(false));
      return response;
    }
    dispatch(buySubscriptionSuccess(false));
  } catch (error) {
    console.log("buySubscription error--", error);
    dispatch(buySubscriptionError(false));
    return { message: error };
  }
};

export const getPaymentStatus = () => async () => {
  try {
    const response = await Services.get(
      url.GET_PAYMENT_STATUS + "?apiKey=apiKey",
      "",
      true
    );
    if (response.status == 200) {
      return response;
    }
  } catch (error) {
    console.log("getPaymentStatus error--", error);
    return { message: error };
  }
};

export const sendVerficationEmail = (body) => async (dispatch) => {
  dispatch(sendVerficationEmailLoading(true));
  try {
    const response = await Services.post(
      url.SEND_VARIFICATION_EMAIL + "?apiKey=apiKey",
      "",
      body
    );
    dispatch(
      sendVerficationEmailSuccess(false, {
        verificationData: response?.results,
      })
    );
    return response;
  } catch (error) {
    console.log("sendVerficationEmail error --", error);
    dispatch(sendVerficationEmailError(false));
    return { message: error };
  }
};

export const sendVerfication = (body) => async (dispatch) => {
  dispatch(sendVerficationLoading(true));
  try {
    const response = await Services.post(
      url.SEND_VARIFICATION + "?apiKey=apiKey",
      "",
      body
    );
    dispatch(sendVerficationSuccess(false));
    return response;
  } catch (error) {
    console.log("sendVerfication error --", error);
    dispatch(sendVerficationError(false));
    return { message: error };
  }
};

export const otpVerify = (body) => async (dispatch) => {
  dispatch(otpVerifyLoading(true));
  try {
    const response = await Services.post(
      url.OTP_VERIFICATION + "?apiKey=apiKey",
      "",
      body
    );
    dispatch(otpVerifySuccess(false));
    return response;
  } catch (error) {
    console.log("otpVerify error --", error);
    dispatch(otpVerifyError(false));
    return { message: error };
  }
};

export const resetPassword = (body) => async (dispatch) => {
  dispatch(resetPasswordLoading(true));
  try {
    const response = await Services.post(
      url.RESET_PASSWORD + "?apiKey=apiKey",
      "",
      body
    );
    dispatch(resetPasswordSuccess(false));
    return response;
  } catch (error) {
    console.log("resetPassword error --", error);
    dispatch(resetPasswordError(false));
    return { message: error };
  }
};
export const forgetPassword = (body) => async (dispatch) => {
  dispatch(forgetPasswordLoading(true));
  try {
    const response = await Services.post(
      url.FORGOT_PASSWORD + "?apiKey=apiKey",
      "",
      body
    );
    dispatch(forgetPasswordSuccess(false));
    return response;
  } catch (error) {
    console.log("forgetPassword error --", error);
    dispatch(forgetPasswordError(false));
    return { message: error };
  }
};

export const identityVerification = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  dispatch(identityVerificationLoading(true));
  try {
    const response = await Services.formDataPost(
      url.IDENTITY_VERIFICATION + "?apiKey=apiKey",
      token,
      body
    );
    dispatch(identityVerificationSuccess(false));
    return response;
  } catch (error) {
    console.log("identityVerification error --", error);
    dispatch(identityVerificationError(false));
    return { message: error };
  }
};

export const getIdentityVerifyApi = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  dispatch(getIdentityVerifyLoading(true));
  try {
    const response = await Services.post(
      url.GET_USER_IDENTITY + "?apiKey=apiKey",
      token,
      body,
      true
    );
    dispatch(getIdentityVerifySuccess(false));
    return response;
  } catch (error) {
    console.log("identityVerification error --", error);
    dispatch(getIdentityVerifyError(false));
    return { message: error };
  }
};

export const cancelIdentityVerifyApi = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  dispatch(rejectIdentityVerifyLoading(true));
  try {
    const response = await Services.post(
      url.REJECT_VERIF + "?apiKey=apiKey",
      token,
      body
    );
    dispatch(rejectIdentityVerifySuccess(false));
    return response;
  } catch (error) {
    console.log("identityVerification error --", error);
    dispatch(rejectIdentityVerifyError(false));
    return { message: error };
  }
};

export const removeImage = (body) => async (dispatch) => {
  let token = await getData(storageKey?.AUTH_TOKEN);
  dispatch(removeImageLoading(true));
  try {
    const response = await Services.post(
      url.REMOVE_IMAGE + "?apiKey=apiKey",
      "",
      body
    );
    dispatch(removeImageSuccess(false));
    return response;
  } catch (error) {
    console.log("removeImage error --", error);
    dispatch(removeImageError(false));
    return { message: error };
  }
};
