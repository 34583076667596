import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Rating from "@mui/material/Rating";

const FeedbackModal = (props) => {
  const { show, setShow, userFeedback } = props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>Job Feedback </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {userFeedback?.length
            ? userFeedback?.map((item, index) => {
              return (
                <div className="modal-body modal-body_content fooedbackModel">
                  <div className="top_model_cont">
                    <div className="avatar-wrap-icon">
                      <div className="wt-username">
                        <figure className="wt-userimg">
                          <img
                            src="https://booksculp.com/wp-content/uploads/2023/10/1670543357-225x142-1698320583-100x100.png"
                            alt="Profile Avatar"
                          />
                        </figure>
                        <h3>Book Sculp</h3>
                      </div>
                      <Rating
                        name="half-rating-read"
                        precision={0.5}
                        value={
                          item?.user_rating ? Number(item?.user_rating) : 0
                        }
                        readOnly
                      />
                      {/* <span className="wt-stars wt-starstwo">
                  <span></span>
                </span> */}
                    </div>
                  {item?.review_title ?  <p>
                      {" "}
                      <i className="fa fa-quote-left"></i>
                      {item?.review_title || ''}
                      <i className="fa fa-quote-right"></i>
                    </p> : undefined}
                  </div>
                  <div className="rating_review_list">
                    {item?.review?.length ? item?.review?.map((rate) => {
                      return (
                        <div>
                          <span>{rate?.label || ''}</span>
                          <span>{rate?.value ? Number(rate?.value)?.toFixed(2) : 0}</span>
                        </div>
                      )
                    }) : undefined}
                  </div>
                </div>
              );
            })
            : undefined}
        </div>
        {/* <div className="top_model_cont">
              <div className="avatar-wrap-icon">
                <div className="wt-username">
                  <figure className="wt-userimg">
                    <img
                      src="https://booksculp.com/wp-content/uploads/2023/10/1670543357-225x142-1698320583-100x100.png"
                      alt="Profile Avatar"
                    />
                  </figure>
                  <h3>Book Sculp</h3>
                </div>
                <span className="wt-stars wt-starstwo">
                  <span></span>
                </span>
              </div>
            </div>
            <div className="rating_review_list">
              <div>
                <span>How professional was this model?</span>
                <span>0.00</span>
              </div>
              <div>
                <span>How was their quality of work?</span>
                <span>0.00</span>
              </div>
              <div>
                <span>Was this model focused on the job?</span>
                <span>0.00</span>
              </div>
              <div>
                <span>Was it worth having their services?</span>
                <span>0.00</span>
              </div>
              <div>
                <span>Did they have a good attitude?</span>
                <span>0.00</span>
              </div>
            </div>

            <div className="top_model_cont">
              <div className="avatar-wrap-icon">
                <div className="wt-username">
                  <figure className="wt-userimg">
                    <img
                      src="https://booksculp.com/wp-content/uploads/2023/10/1670543357-225x142-1698320583-100x100.png"
                      alt="Profile Avatar"
                    />
                  </figure>
                  <h3>Book Sculp</h3>
                </div>
                <span className="wt-stars wt-starstwo">
                  <span></span>
                </span>
              </div>
              <p>
                {" "}
                <i className="fa fa-quote-left"></i>wonderful
                <i className="fa fa-quote-right"></i>
              </p>
            </div>
            <div className="rating_review_list">
              <div>
                <span>How professional was this model?</span>
                <span>5.00</span>
              </div>
              <div>
                <span>How was their quality of work?</span>
                <span>5.00</span>
              </div>
              <div>
                <span>Was this model focused on the job?</span>
                <span>5.00</span>
              </div>
              <div>
                <span>Was it worth having their services?</span>
                <span>5.00</span>
              </div>
              <div>
                <span>Did they have a good attitude?</span>
                <span>5.00</span>
              </div>
            </div> */}
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
