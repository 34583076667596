import React from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotificationList from "./notificationList";
import { useDispatch, useSelector } from "react-redux";
import { routeName } from "../../Utility";
import { doubleEncodedData } from "../../Utility/helper";
import { saveTalentFiltersAction } from "../../Redux/Actions/OtherActions";

const AuthButton = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state?.authReducer);
  const badgeCount = useSelector((state) => state.otherReducer.badgeCount);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const {
    notificationData,
    handleViewAction,
    dropItems,
    setShow,
    setLoginModal,
    userId,
    type,
    userRole,
  } = props;

  const navigate = useNavigate();
  return (
    <Nav className="align-items-center">
      {type === "pc" ? (
        <>
          {userRole != 11 && (
            <Nav.Link
              onClick={() => navigate(routeName?.SEARCH_TALENT)}
              // onClick={() => {
              //   let body = {
              //     user_id: loggedInUser?.user_data?.user_id || ''
              //   }
              //   let encoded = doubleEncodedData(body)
              //   if (window) {
              //     window.open(`https://booksculp.com/redirect/?code=search_model&redirect=${encoded}`,"_self")
              //     // window.open("https://booksculp.com/search-models/","_self");
              //   }
              // }}
            >
              Search Talent
            </Nav.Link>
          )}
          {userRole != 12 && (
            <Nav.Link
              onClick={() => {  dispatch(saveTalentFiltersAction(null)); navigate(routeName?.SEARCH_JOBS)}}
              // onClick={() => {
              //   let body = {
              //     user_id: loggedInUser?.user_data?.user_id || ''
              //   }
              //   let encoded = doubleEncodedData(body)
              //   if (window) {
              //     window.open(`https://booksculp.com/redirect/?code=search_job&redirect=${encoded}`,"_self")
              //   }
              // }}
            >
              Search Jobs
            </Nav.Link>
          )}

          <Nav.Link
            onClick={() => navigate(routeName?.INSIGHTS)}
          //   onClick={() => {
          //     let body = {
          //       user_id: loggedInUser?.user_data?.user_id || ''
          //     }
          //     let encoded = doubleEncodedData(body)
          //     if (window) {
          //       window.open(`https://booksculp.com/redirect/?code=insight&redirect=${encoded}`,"_self")
          //     }
          //   }}
          >
            Insight
          </Nav.Link>
        </>
      ) : undefined}
      {userId && loggedInUser ? (
        <>
          <Nav.Link className="notification">
            <div class="wt-userlogedin wt-notificatoin ">
              <figure class="wt-userimg">
                {badgeCount != 0 ? (
                  <span class="notify-counter">{badgeCount || ""}</span>
                ) : undefined}
                <i class="fa fa-bell-o" aria-hidden="true"></i>
              </figure>
              <NotificationList
                notificationData={notificationData}
                handleViewAction={handleViewAction}
              />
            </div>
          </Nav.Link>
          <Nav.Link className="accountSection">
            <div class="wt-userlogedin">
              <figure class="wt-userimg">
                {auth?.isLoading ? null : loggedInUser &&
                  loggedInUser?.profile_image &&
                  loggedInUser?.profile_image?.length != 0 &&
                  loggedInUser?.profile_image[0]?.guid ? (
                  <img
                    src={loggedInUser?.profile_image?.[0]?.guid}
                    alt="image description"
                  />
                ) : (
                  <i
                    class="fa fa-user-circle user_profile"
                    aria-hidden="true"
                  ></i>
                )}
              </figure>
              <div class="wt-username">
                <h5>
                  {loggedInUser?.user_data?.full_name
                    ? loggedInUser?.user_data?.full_name
                    : loggedInUser?.user_data?.first_name +
                      loggedInUser?.user_data?.last_name}
                </h5>
                <span>
                  {loggedInUser?.company_details?.title
                    ? loggedInUser?.company_details?.title?.length > 15
                      ? `${loggedInUser?.company_details?.title?.slice(
                          0,
                          15
                        )}...`
                      : loggedInUser?.company_details?.title
                    : `${
                        loggedInUser?.post_meta_details?.tag_line?.slice(
                          0,
                          15
                        ) || ""
                      }...` || ""}
                </span>
              </div>
              <nav class="wt-usernav">
                <ul>
                  {dropItems?.length != 0 &&
                    dropItems?.map((item, index) => {
                      return (
                        <>
                          {!item?.hide && (
                            <li onClick={() =>{
                              if(item?.key === 2){
                                // navigate(item?.path)
                                let body = {
                                  user_id: loggedInUser?.user_data?.user_id,
                                  profile_id: loggedInUser?.user_data?.profile_id,
                                };
                                console.log(body,"hfgshdgfhdsgf");
                                let encoded = doubleEncodedData(body)
                                
                                console.log(encoded,"sdjhgjdsgfjd");
                                if(window){
                                  window.open(`https://booksculp.com/redirect/?code=dashboard&redirect=${encoded}`,"_self")
                                }
                              }else{
                                navigate(item?.path)
                              }
                            }}>
                              <a>
                                <span>{item?.name}</span>
                              </a>
                            </li>
                          )}
                        </>
                      );
                    })}

                  <li onClick={() => setShow(true)}>
                    <a href="">
                      <span>Log Out</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </Nav.Link>
        </>
      ) : (
        <>
          <Nav.Link className="notification">
            <button
              className="tBtn"
              onClick={() => {
                setLoginModal(true);
                // let body = {
                //   user_id:  ''
                // }
                // let encoded = doubleEncodedData(body)
                // if(window){
                //   window.open(`https://booksculp.com/redirect/?code=login&redirect=${encoded}`,"_self")
                // }
              }}
            >
              <i className="fa fa-sign-in"></i> Sign In
            </button>
          </Nav.Link>
          <Nav.Link className="notification">
            <button
              onClick={() => navigate(routeName?.SIGN_UP)}
              className="tBtn tBrnLight"
            >
              Sign Up
            </button>
          </Nav.Link>
        </>
      )}
    </Nav>
  );
};

export default AuthButton;
