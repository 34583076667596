import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  About,
  Insights,
  ContactUs,
  Home,
  SearchJobs,
  SearchTalent,
  NotFound,
  Dashboard,
  PrivacyPolicy,
  TermsConditions,
  TalentProfile,
  Faq,
  JobDetails,
  Notifications,
  JobProposal,
  IdentityVerification,
  Redirect,
  PayoutSetting,
  StripeResponse,
  JobPost,
  PostCastingCallJob,
  ModelJobs,
  Chat,
  ClientProjects,
  Proposals,
  Packages,
  AddPortfolio,
  AddPost,
  ManagePortfolio,
  ManagePost,
  SocialDetail,
  InsightDetail,
  FollowDetails,
  SavedCollection
} from "./Screens/Others";
import { Register, Signup, EditProfile, ProfileSetup } from "./Screens/Auth";
import { useEffect, useState } from "react";
import { getData, storageKey, storeData } from "./Utility/Storage";
import { routeName } from "./Utility";
import { useSelector } from "react-redux";
import { ClientProfile } from "./Screens/Others/ClientProfile";
export const Routing = () => {
  const auth = useSelector((state) => state?.authReducer);
  const verificationData = auth?.verificationData?.verificationData;

  const [userData, setUserData] = useState({
    userId: "",
    email: verificationData?.user_email,
    userRole: verificationData?.user_type,
    refferID: verificationData?.user_type,
  });
  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    let userId = await getData(storageKey?.USER_ID);
    const params = new URLSearchParams(window.location.href);
    const refferValue = params.get("reffer");
    console.log("currentUrl---------", refferValue);
    await storeData(storageKey?.REFFER_ID, JSON?.stringify(refferValue));
    setUserData({
      userId: userId,
      email: verificationData?.user_email,
      userRole: verificationData?.user_type,
      refferID: refferValue,
    });
  };
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path={routeName?.ABOUT} element={<About />} />
          <Route path={routeName?.CONTACT_US} element={<ContactUs />} />
          <Route path={routeName?.INSIGHTS} element={<Insights />} />
          <Route path={routeName?.SEARCH_JOBS} element={<SearchJobs />} />
          <Route path={routeName?.SEARCH_TALENT} element={<SearchTalent />} />
          <Route path={routeName?.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route
            path={routeName?.TERMS_CONTIONS}
            element={<TermsConditions />}
          />
          <Route path={routeName?.DASHBOARD} element={<Dashboard />} />
          <Route path={routeName?.TALENT_PROFILE} element={<TalentProfile />} />
          <Route path={routeName?.EDIT_PROFILE} element={<EditProfile />} />
          <Route path={routeName?.CLIENT_PROFILE} element={<ClientProfile />} />
          <Route path={routeName?.FAQ} element={<Faq />} />
          <Route path={routeName?.JOB_DETAILS} element={<JobDetails />} />
          <Route path={routeName?.NOTIFICATION} element={<Notifications />} />
          <Route path={routeName?.JOB_PROPOSAL} element={<JobProposal />} />
          <Route path={routeName?.MODEL_JOBS} element={<ModelJobs />} />
          <Route path={`${routeName?.REGISTER}`} element={<ProfileSetup />} />
          <Route path={`${routeName?.PROFILE}`} element={<ProfileSetup />} />
          <Route
            path={`${routeName?.IDENTITY_VERIFY}`}
            element={<IdentityVerification />}
          />
          <Route
            path={
              userData?.refferID
                ? `/signup&reffer=${userData?.refferID}`
                : "/signup"
            }
            element={<Signup />}
          />
          <Route path={`${routeName?.RE_DIRECT}`} element={<Redirect />} />
          <Route path={`${routeName?.PAYOUT}`} element={<PayoutSetting />} />
          <Route path={`${routeName?.STRIPE}`} element={<StripeResponse />} />
          <Route path={`${routeName?.JOB_POST}`} element={<JobPost />} />
          <Route
            path={`${routeName?.CASTING_JOB}`}
            element={<PostCastingCallJob />}
          />
          <Route path={`${routeName?.CHAT}`} element={<Chat />} />
          <Route
            path={`${routeName?.CLIENT_PROJECTS}`}
            element={<ClientProjects />}
          />
          <Route
            path={`${routeName?.INSIGHT_DETAIL}`}
            element={<InsightDetail />}
          />
          <Route path={`${routeName?.PROPOSALS}`} element={<Proposals />} />
          <Route path={`${routeName?.PACKAGES}`} element={<Packages />} />
          <Route
            path={`${routeName?.ADD_PORTFOLIO}`}
            element={<AddPortfolio />}
          />
          <Route path={`${routeName?.ADD_POST}`} element={<AddPost />} />
          <Route
            path={`${routeName?.MANAGE_PORTFOLIO}`}
            element={<ManagePortfolio />}
          />
          <Route path={`${routeName?.MANAGE_POST}`} element={<ManagePost />} />
          <Route

            path={`${routeName?.SOCIAL_DETAIL}`}
            element={<SocialDetail />}
          />
          <Route
            path={`${routeName?.FOLLOW_DETAILS}`}
            element={<FollowDetails />}
          />
          <Route
            path={`${routeName?.SAVED_COLLECTION}`}
            element={<SavedCollection />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};
