import React, { useEffect, useState } from "react";
import { Button, Container, Form, Tooltip } from "react-bootstrap";
import { InputField } from "./InputField";
import { DropdownList } from "./DropdownList";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelectDropdown } from "./MultiSelectDropdown";
import {
  addDirectJobPost,
  getCountryList,
  postJob,
} from "../Redux/Services/OtherServices";
import { getData, storageKey } from "../Utility/Storage";
import { routeName, showToast } from "../Utility";
import moment from "moment";
import { genderTypes, kidGenderTypes } from "../Global";
import { useNavigate } from "react-router-dom";

let talentData = [
  {
    label: "Model",
    value: "Model",
  },
  {
    label: "Model Kid",
    value: "Model Kid",
  },
  {
    label: "Actor",
    value: "Actor",
  },
  {
    label: "Actor Kid",
    value: "Actor Kid",
  },
];

export const JobPostForm = (props) => {
  const { setLoading, type } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allOptionData = useSelector(
    (state) => state?.authReducer.allOptionData
  );

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [talentType, setTalentType] = useState("");
  const [projectLevel, setProjectLevel] = useState("");
  const [jobDuration, setJobDuration] = useState("");
  const [englishLevel, setEnglishLevel] = useState("");
  const [languages, setLanguages] = useState([]);
  const [skills, setSkills] = useState([]);
  const [projectLocationType, setProjectLocationType] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [clientprovider, setClientprovider] = useState("");
  const [otherContact, setOtherContact] = useState("");
  const [workType, setWorkType] = useState("");
  const [arrivalReq, setArrivalReq] = useState("");
  const [usageDuration, setUsageDuration] = useState("");
  const [usageLocation, setUsageLocation] = useState("");
  const [mediaType, setMediaType] = useState([]);
  const [usageFee, setUsageFee] = useState("");
  const [payType, setPayType] = useState("");
  const [whenPayExpect, setWhenPayExpect] = useState("");
  const [jobDesc, setJobDesc] = useState({
    title: "",
  });
  const [jobDetail, setJobDetail] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    location: "",
    address: "",
    adddressLine2: "",
    zipCode: "",
    arrivalTime: "",
    primaryClientContact: "",
    emergencyContact: "",
    reqNotes: "",
    locationContact: "",
    locationContactPhone: "",
    projectDesc: "",
    noOfModels: "",
  });
  const [prices, setPrices] = useState({
    projectRate: "",
    diemAmount: "",
    estimateHrs: "",
    hourlyRate: "",
  });
  const [message, setMessage] = useState("");
  const [customSkills, setCustomSkills] = useState("");
  const [error, setError] = useState(false);
  const [location, setLocation] = useState({
    countryID: "",
    countryList: "",
    stateList: "",
    cityList: "",
  });
  const [age, setAge] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [ethinicity, setEthinicity] = useState([]);
  const [gender, setGender] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [influencer, setInfluencer] = useState("");
  const [pantSize, setPantSize] = useState("");
  const [pantSizeW, setPantSizeW] = useState("");
  const [pantSizeL, setPantSizeL] = useState("");
  const [shirtSize, setShirtSize] = useState("");
  const [shirtSizeF, setShirtSizeF] = useState("");
  const [dressShirt, setDressShirt] = useState("");
  const [shirtSleeve, setShirtSleeve] = useState("");
  const [neckSize, setNeckSize] = useState("");
  const [chestSize, setChestSize] = useState("");
  const [jacket, setJacket] = useState("");
  const [braCup, setBraCup] = useState("");
  const [braSize, setBraSize] = useState("");
  const [dressSize, setDressSize] = useState("");
  const [shoeSize, setShoeSize] = useState("");
  const [shirtSizeC, setShirtSizeC] = useState("");
  const [pantSizeWC, setPantSizeWC] = useState("");
  const [dressSizeC, setDressSizeC] = useState("");
  const [shoeSizeF, setShoeSizeF] = useState("");
  const [shoeSizeC, setShoeSizeC] = useState("");

  const dateArr = generateDateArray(
    jobDetail?.startDate,
    jobDetail?.endDate,
    jobDetail?.startTime,
    jobDetail?.endTime
  );

  const [editedData, setEditedData] = useState(dateArr);
  console.log(editedData, jobDetail?.startTime, "dateArr>>>>>>");

  useEffect(() => {
    setEditedData(dateArr);
  }, [
    jobDetail?.endTime,
    jobDetail?.startTime,
    jobDetail?.startDate,
    jobDetail?.endDate,
  ]);

  const handleEditStartTime = (index, value) => {
    const newData = [...editedData];
    newData[index].value.start_time = convertTo12HourFormat(value);
    if (newData[index].value.end_time < value) {
      newData[index].value.end_time = convertTo12HourFormat(value);
    }
    setEditedData(newData);
  };

  const handleEditEndTime = (index, value) => {
    const newData = [...editedData];
    newData[index].value.end_time = convertTo12HourFormat(value);
    if (newData[index].value.start_time > value) {
      newData[index].value.start_time = convertTo12HourFormat(value);
    }
    setEditedData(newData);
  };
  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24?.split(":");
    let period = "AM";
    if (hours >= 12) {
      period = "PM";
    }
    const hours12 = hours % 12 || 12;
    const formattedTime = `${String(hours12)?.padStart(
      2,
      "0"
    )}:${minutes} ${period}`;
    return formattedTime;
  }

  const handleSelectCountry = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = location?.countryList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    getAllStateName(selectedOptionObject?.id);
    if (e.target.value == "") {
      setCountry("");
    } else {
      setCountry(e.target.value);
    }
  };

  const handleSelectState = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = location?.stateList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    getAllCityName(selectedOptionObject?.id);
    if (e.target.value == "") {
      setState("");
    } else {
      setState(e.target.value);
    }
  };

  const handleSelectCity = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = location?.cityList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    if (e.target.value == "") {
      setCity("");
    } else {
      setCity(e.target.value);
    }
  };

  function generateDateArray(startDate, endDate, startTime, endTime) {
    const result = [];
    const currentDate = new Date(startDate);
    const endDateTime = new Date(endDate + " " + endTime);

    while (currentDate <= endDateTime) {
      const formattedDate = formatDate(currentDate);
      const timeObj = {
        start_time: formatTime(currentDate, startTime),
        end_time: formatTime(currentDate, endTime),
      };

      result.push({
        label: formattedDate,
        value: timeObj,
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  }

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;
    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  function formatTime(date, time) {
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const timeParts = time.split(":");
    date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10));
    return date.toLocaleTimeString("en-US", options);
  }

  function convertTo24HourFormat(time12h) {
    // Extract hours, minutes, and meridiem from the time string
    const [timePart, meridiem] = time12h.split(" ");
    const [hours, minutes] = timePart.split(":").map(Number);

    // Convert hours to 24-hour format
    let hours24 = hours;
    if (meridiem === "PM" && hours !== 12) {
      hours24 += 12;
    } else if (meridiem === "AM" && hours === 12) {
      hours24 = 0;
    }

    // Pad single-digit hours and minutes with leading zeros
    const paddedHours = hours24.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");

    return `${paddedHours}:${paddedMinutes}`;
  }

  // // Example usage:
  // const time12h = "10:26 PM";
  // const time24h = convertTo24HourFormat(time12h);
  // console.log(time24h); // Output: "22:26"

  const handleForm = async (event) => {
    setError(true);
    event.preventDefault();

    if (!jobDesc?.title) {
      showToast("Project title is required", "error");
      return;
    }
    if (!talentType && type !== "castingCall") {
      showToast("Talent type is required", "error");
      return;
    }
    if (type === "castingCall" && !jobDetail?.noOfModels) {
      showToast("Number of models are required", "error");
      return;
    }
    if (!projectLevel) {
      showToast("Project level is required", "error");
      return;
    }
    if (!jobDuration) {
      showToast("Job duration is required", "error");
      return;
    }
    if (!type && !englishLevel) {
      showToast("English level is required", "error");
      return;
    }
    if (!projectLocationType) {
      showToast("Project location type is required", "error");
      return;
    }
    if (!timeZone) {
      showToast("Time zone is required", "error");
      return;
    }
    if (!jobDetail?.startDate) {
      showToast("Start Date is required", "error");
      return;
    }
    if (!jobDetail?.endDate) {
      showToast("End Date is required", "error");
      return;
    }
    if (!jobDetail?.startTime) {
      showToast("Start time is required", "error");
      return;
    }
    if (!jobDetail?.endTime) {
      showToast("End Time is required", "error");
      return;
    }
    if (!country) {
      showToast("Country is required", "error");
      return;
    }
    if (!state) {
      showToast("State is required", "error");
      return;
    }
    if (!city && location?.cityList?.length) {
      showToast("City is required", "error");
      return;
    }
    if (!jobDetail?.address) {
      showToast("Address is required", "error");
      return;
    }
    if (!jobDetail?.zipCode) {
      showToast("Zip Code is required", "error");
      return;
    }
    if (!jobDetail?.arrivalTime) {
      showToast("Arrival Time is required", "error");
      return;
    }
    if (!clientprovider?.length) {
      showToast("Client Provides are required", "error");
      return;
    }
    if (!workType) {
      showToast("Type of work is required", "error");
      return;
    }
    if (!payType) {
      showToast("Pay type is required", "error");
      return;
    }
    if (!whenPayExpect) {
      showToast("When to pay is required", "error");
      return;
    }
    if (!prices?.projectRate && payType !== "Hourly Rate") {
      showToast("Project Rate is required", "error");
      return;
    }
    if (!prices?.estimateHrs && payType === "Hourly Rate") {
      showToast("Estimated hours are required", "error");
      return;
    }
    if (!prices?.hourlyRate && payType === "Hourly Rate") {
      showToast("Hourly Rate is required", "error");
      return;
    }
    if (!mediaType?.length) {
      showToast("Media types are required", "error");
      return;
    }
    if (type) {
      if (!englishLevel) {
        showToast("Language Level is required", "error");
        return;
      }
      if (!languages?.length) {
        showToast("Language is required", "error");
        return;
      }
    }
    if (!type && !message) {
      showToast("Message is required", "error");
      return;
    }

    const form = event.currentTarget;
    const dateArr = generateDateArray(
      jobDetail?.startDate,
      jobDetail?.endDate,
      jobDetail?.startTime,
      jobDetail?.endTime
    );
    if (type === "jobPost") {
      if (form.checkValidity()) {
        let body = {
          // "reached_time": jobDetail?.arrivalTime,
          location_contact: jobDetail?.locationContact || "",
          location_contact_phone_number: jobDetail?.locationContactPhone || "",

          required_gender: gender
            ? gender === "female" || gender === "Female"
              ? "female"
              : gender === "Male" || gender === "male"
              ? "female"
              : "other"
            : "",
          model_age: age,
          height_req: height,
          weight_req: weight,
          ethnicity_req: ethinicity,
          hair_color_req: hairColor || "",
          influencer_req: "15-20k",
          pant_size: pantSize || [],
          bra_cup_size: braCup || null,
          bra_size: braSize || null,
          dress_size: dressSize || [],
          shoe_size: shoeSize || [],
          children_shirt_size: shirtSizeC || [],
          children_pant_size: pantSizeWC || [],
          children_shoe_size: shoeSizeC || [],
          children_dress_size: dressSizeC || [],
          toddler_shirt_size: shirtSizeC || [],
          toddler_pant_size: pantSizeWC || [],
          toddler_shoe_size: shoeSizeC || [],
          toddler_dress_size: shoeSizeC || [],
          infant_shirt_size: shirtSizeC || [],
          infant_dress_size: dressSizeC || [],
          infant_pant_size: pantSizeWC || [],
          infant_shoe_size: shoeSizeC || [],
          languages: languages?.length
            ? languages?.map(({ value, label }) => ({
                name: label,
                id: value,
              }))
            : [],
          description: jobDetail?.projectDesc,
          // "post_content: "Hdjd",
          skills_names: skills || [],
          add_custom_skills: customSkills,
          user_id: loggedInUser?.user_data?.user_id || "",
          project_title: jobDesc?.title || "",
          freelancer_level:
            talentType === "Model Kid"
              ? "Child"
              : talentType === "Actor Kid"
              ? "Actor child"
              : talentType === "model" || talentType === "Model"
              ? "Model"
              : talentType === "Actor"
              ? "Actor"
              : "",
          model_type_req:
            talentType === "Model Kid"
              ? "child"
              : talentType === "Actor Kid"
              ? "actor child"
              : talentType === "Model"
              ? "model"
              : talentType === "Actor"
              ? "actor"
              : "",
          project_level: projectLevel || "",
          project_duration: jobDuration || "",
          english_level: englishLevel || "",
          job_option: projectLocationType || "",
          time_zone: timeZone || "",
          starting_date: jobDetail?.startDate || "",
          end_date: jobDetail?.endDate || "",
          starting_time: jobDetail?.startTime
            ? convertTo12HourFormat(jobDetail?.startTime)
            : "",
          end_time: jobDetail?.endTime
            ? convertTo12HourFormat(jobDetail?.endTime)
            : "",
          set_custom_time: dateArr || [],
          location_description: jobDetail?.location || "",
          country: country || "",
          state: state || "",
          city: city || "",
          address_line: jobDetail?.address,
          address_line_2: jobDetail?.adddressLine2 || "",
          zipcode: jobDetail?.zipCode || "",
          // reached_time: formattedDate,
          client_provide: clientprovider || [],
          primary_client_contact: jobDetail?.primaryClientContact || "",
          primary_contact_emergency: jobDetail?.emergencyContact || "",
          other_contact: otherContact || "",
          type_of_work: workType || [],
          talent_requirements: arrivalReq || [],
          additional_information: jobDetail?.reqNotes || "",
          estimated_hours: prices?.estimateHrs || "",
          project_cost: prices?.projectRate,
          amount_of_per_diem_provided: prices?.diemAmount || "",
          duration_usage: usageDuration || "",
          location_usage: usageLocation || "",
          media_type_usage: mediaType || [],
          usage_fee: usageFee || "",
          project_type: payType || "",
          hourly_rate: prices?.hourlyRate || "",
        };
        console.log(body, "lplppp");
        // return
        setLoading(true);
        try {
          const res = await dispatch(postJob(body));
          setLoading(false);
          if (res?.status === 200) {
            showToast("Job send successfully", "success");
            navigate(routeName.DASHBOARD, { replace: true });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    } else if (type === "castingCall") {
      if (form.checkValidity()) {
        let body = {
          user_id: loggedInUser?.user_data?.user_id || "",
          project_title: jobDesc?.title || "",
          freelancer_level: "Casting calls",
          number_of_model: jobDetail?.noOfModels,
          model_type_req: "casting calls",
          project_level: projectLevel || "",
          project_duration: jobDuration || "",
          english_level: englishLevel || "",
          job_option: projectLocationType || "",
          time_zone: timeZone || "",
          starting_date: jobDetail?.startDate || "",
          end_date: jobDetail?.endDate || "",
          starting_time: jobDetail?.startTime
            ? convertTo12HourFormat(jobDetail?.startTime)
            : "",
          end_time: jobDetail?.endTime
            ? convertTo12HourFormat(jobDetail?.endTime)
            : "",
          set_custom_time: dateArr || [],
          location_description: jobDetail?.location || "",
          country: country || "",
          state: state || "",
          city: city || "",
          address_line: jobDetail?.address,
          address_line_2: jobDetail?.adddressLine2 || "",
          zipcode: jobDetail?.zipCode || "",
          description: jobDetail?.projectDesc,
          // "reached_time" : "02:05",
          client_provide: clientprovider || [],
          primary_client_contact: jobDetail?.primaryClientContact || "",
          primary_contact_emergency: jobDetail?.emergencyContact || "",
          other_contact: otherContact || "",
          type_of_work: workType || [],
          location_contact: jobDetail?.locationContact || "",
          location_contact_phone_number: jobDetail?.locationContactPhone || "",

          talent_requirements: arrivalReq || [],
          additional_information: jobDetail?.reqNotes || "",
          estimated_hours: prices?.estimateHrs || "",
          project_cost: prices?.projectRate,
          amount_of_per_diem_provided: prices?.diemAmount || "",
          duration_usage: usageDuration || "",
          location_usage: usageLocation || "",
          media_type_usage: mediaType || [],
          usage_fee: usageFee || "",
          project_type: payType || "",
          hourly_rate: prices?.hourlyRate || "",

          languages: languages || [],
          skills_names: skills || [],
          add_custom_skills: customSkills,
        };
        console.log(body, "lplppp");
        setLoading(true);
        try {
          const res = await dispatch(postJob(body));
          setLoading(false);
          if (res?.status === 200) {
            showToast("Job send successfully", "success");
            navigate(routeName.DASHBOARD, { replace: true });
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    } else {
      if (form.checkValidity()) {
        let body = {
          user_id: loggedInUser?.user_data?.user_id || "",
          receiver_id: props?.receiverId || "",
          action: "direct_offer",
          message: message || "",
          project_title: jobDesc?.title || "",
          freelancer_level:
            talentType === "Model Kid" || talentType === "Actor Kid"
              ? "child"
              : talentType,
          model_type_req:
            talentType === "Model Kid" || talentType === "Actor Kid"
              ? "child"
              : talentType === "Model"
              ? "model"
              : talentType === "Actor"
              ? "actor"
              : "",
          project_level: projectLevel || "",
          project_duration: jobDuration || "",
          english_level: englishLevel || "",
          job_option: projectLocationType || "",
          time_zone: timeZone || "",
          starting_date: jobDetail?.startDate || "",
          end_date: jobDetail?.endDate || "",
          starting_time: jobDetail?.startTime
            ? convertTo12HourFormat(jobDetail?.startTime)
            : "",
          end_time: jobDetail?.endTime
            ? convertTo12HourFormat(jobDetail?.endTime)
            : "",
          set_custom_time: dateArr || [],
          location_description: jobDetail?.location || "",
          country: country || "",
          state: state || "",
          city: city || "",
          address_line: jobDetail?.address,
          address_line_2: jobDetail?.adddressLine2 || "",
          zipcode: jobDetail?.zipCode || "",
          // reached_time: formattedDate,
          client_provide: clientprovider || [],
          primary_client_contact: jobDetail?.primaryClientContact || "",
          primary_contact_emergency: jobDetail?.emergencyContact || "",
          other_contact: otherContact || "",
          type_of_work: workType || [],
          talent_requirements: arrivalReq || [],
          additional_information: jobDetail?.reqNotes || "",
          estimated_hours: prices?.estimateHrs || "",
          project_cost: prices?.projectRate,
          amount_of_per_diem_provided: prices?.diemAmount || "",
          duration_usage: usageDuration || "",
          location_usage: usageLocation || "",
          media_type_usage: mediaType || [],
          usage_fee: usageFee || "",
          project_type: payType || "",
          hourly_rate: prices?.hourlyRate || "",
        };
        setLoading(true);
        try {
          const res = await dispatch(addDirectJobPost(body));
          setLoading(false);
          if (res?.status === 200) {
            showToast("Job send successfully", "success");
            if (props.setShow) {
              props.setShow(false);
            }
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      }
    }
  };

  const getAllCityName = async (id) => {
    const selectedOptionObject = location?.countryList?.find(
      (option) => option.value === country || option.name === country
    );
    var body = {
      country: selectedOptionObject?.id || "",
      state: id || "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        setLocation({
          ...location,
          cityList: data,
        });
      } else {
        setLocation({
          ...location,
          cityList: [],
        });
      }
    }
  };

  const getAllStateName = async (id) => {
    var body = {
      country: id || "",
      state: "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        setLocation({
          ...location,
          stateList: data,
          cityList: [],
        });
      } else {
        setLocation({
          ...location,
          stateList: [],
          cityList: [],
        });
      }
    }
  };

  const getAllCountryName = async (type, id) => {
    var body = {
      country: "",
      state: "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      data = res?.results;
      setLocation({
        ...location,
        countryList: data,
      });
    }
  };

  useEffect(() => {
    getAllCountryName();
  }, []);

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const renderAgeOptions = () => {
    if (talentType === "Actor Kid" || talentType === "Model Kid") {
      return allOptionData?.filterOption?.child_age_req?.field_meta?.choices?.map(
        (item, index) => (
          <option key={`month-${index}`} value={`${item?.label}`}>
            {`${item?.value}`}
          </option>
        )
      );
    } else if (talentType === "Model" || talentType === "Actor") {
      return allOptionData?.filterOption?.age_req?.field_meta?.choices?.map(
        (item, index) => (
          <option key={`month-${index}`} value={`${item?.label}`}>
            {`${item?.value}`}
          </option>
        )
      );
    } else {
      return null;
    }
  };

  return (
    <div className="pd-30">
      <div className="verification_contain_form wt-email-cont">
        <div>
          <Form onSubmit={handleForm}>
            <div class="form-section">
              <div className="form-section-title">Job Description</div>
              <div class="form-group form-group-50">
                <InputField
                  required
                  title="Project Title"
                  placeholder="Project Title"
                  type="text"
                  value={jobDesc?.title}
                  onChangeText={(e) => {
                    setJobDesc({
                      ...jobDesc,
                      title: e?.target?.value,
                    });
                  }}
                />
                {type === "castingCall" ? (
                  <InputField
                    required
                    title="Number of Models"
                    placeholder="Number of Models"
                    type="number"
                    value={jobDetail?.noOfModels}
                    onChangeText={(e) => {
                      setJobDetail({
                        ...jobDetail,
                        noOfModels: e?.target?.value,
                      });
                    }}
                  />
                ) : undefined}
                {type === "castingCall" ? undefined : (
                  <DropdownList
                    required
                    title="Talent type"
                    placeholder="Select Talent type"
                    options={talentData}
                    value={talentType}
                    setValue={setTalentType}
                  />
                )}
                <DropdownList
                  required
                  title="Project Level"
                  placeholder="Select Project Level"
                  options={allOptionData?.project_levels}
                  value={projectLevel}
                  setValue={setProjectLevel}
                />
                <DropdownList
                  required
                  title="Job Duration"
                  placeholder="Select Job Duration"
                  options={allOptionData?.job_durations}
                  value={jobDuration}
                  setValue={setJobDuration}
                />
                {type ? undefined : (
                  <DropdownList
                    required
                    title="English Level"
                    placeholder="Select English Level"
                    options={allOptionData?.englishLevel}
                    value={englishLevel}
                    setValue={setEnglishLevel}
                  />
                )}
                <DropdownList
                  required
                  title="Project Location Type"
                  placeholder="Select Project Location Type"
                  options={allOptionData?.project_location_type}
                  value={projectLocationType}
                  setValue={setProjectLocationType}
                />
                {type ? (
                  <InputField
                    title="Job Description"
                    placeholder="Job Description"
                    type="text"
                    value={jobDetail?.projectDesc}
                    onChangeText={(e) => {
                      setJobDetail({
                        ...jobDetail,
                        projectDesc: e?.target?.value,
                      });
                    }}
                  />
                ) : undefined}
                <Form.Group>
                  <Form.Label>
                    {allOptionData?.time_zone?.length ? (
                      <span className="astrik_req">*</span>
                    ) : undefined}
                    TimeZone
                  </Form.Label>
                  <Form.Select
                    className="form-control"
                    onChange={(e) => {
                      setTimeZone(e.target.value);
                    }}
                    value={timeZone}
                  >
                    <option value="">TimeZone</option>
                    {allOptionData?.time_zone?.length &&
                      allOptionData?.time_zone?.map((item, index) => {
                        return (
                          <>
                            <option value={item?.value} label={item?.value}>
                              {item?.value}
                            </option>
                          </>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>

            <div class="form-section">
              <div className="form-section-title">Job Details</div>
              <div class="form-group form-group-50">
                <InputField
                  required
                  title="Start Date"
                  type="date"
                  value={jobDetail?.startDate}
                  placeholder=""
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      startDate: e?.target?.value,
                      endDate: "",
                    });
                  }}
                  min={moment(new Date()).format("YYYY-MM-DD")}
                />
                <InputField
                  required
                  title="End Date"
                  type="date"
                  value={jobDetail?.endDate}
                  placeholder=""
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      endDate: e?.target?.value,
                    });
                  }}
                  min={moment(new Date(jobDetail?.startDate)).format(
                    "YYYY-MM-DD"
                  )}
                />
                <InputField
                  required
                  title="Start Time"
                  type="time"
                  value={jobDetail?.startTime}
                  placeholder=""
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      startTime: e?.target?.value,
                    });
                  }}
                />
                <InputField
                  required
                  title="End Time"
                  type="time"
                  value={jobDetail?.endTime}
                  placeholder=""
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      endTime: e?.target?.value,
                    });
                  }}
                />
                {jobDetail?.startDate &&
                jobDetail?.endDate &&
                jobDetail?.startTime &&
                jobDetail?.endTime &&
                editedData?.length > 1 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {editedData.map((item, index) => (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>
                            <input
                              type="time"
                              value={convertTo24HourFormat(
                                item.value.start_time
                              )}
                              onChange={(e) =>
                                handleEditStartTime(index, e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <input
                              type="time"
                              value={convertTo24HourFormat(item.value.end_time)}
                              min={item.value.start_time}
                              onChange={(e) =>
                                handleEditEndTime(index, e.target.value)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : undefined}
                <Form.Group>
                  <Form.Label>
                    <span className="astrik_req">*</span>Select Country
                  </Form.Label>
                  <Form.Select
                    className="form-control"
                    onChange={(e) => {
                      handleSelectCountry(e);
                    }}
                    value={country}
                  >
                    <option value="">Select Country</option>
                    {location?.countryList?.length &&
                      location?.countryList?.map((item, index) => {
                        return (
                          <>
                            <option value={item?.name} label={item?.name}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <span className="astrik_req">*</span>Select State
                  </Form.Label>
                  <Form.Select
                    className="form-control"
                    onChange={(e) => {
                      handleSelectState(e);
                    }}
                    value={state}
                  >
                    <option value="">Select State</option>
                    {location?.stateList?.length &&
                      location?.stateList?.map((item, index) => {
                        return (
                          <>
                            <option value={item?.name} label={item?.name}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {location?.cityList?.length ? (
                      <span className="astrik_req">*</span>
                    ) : undefined}
                    Select City
                  </Form.Label>
                  <Form.Select
                    className="form-control"
                    onChange={(e) => {
                      handleSelectCity(e);
                    }}
                    value={city}
                  >
                    <option value="">Select City</option>
                    {location?.cityList?.length &&
                      location?.cityList?.map((item, index) => {
                        return (
                          <>
                            <option value={item?.name} label={item?.name}>
                              {item?.name}
                            </option>
                          </>
                        );
                      })}
                  </Form.Select>
                </Form.Group>

                <InputField
                  title="Job Location"
                  placeholder="Job Location"
                  type="text"
                  value={jobDetail?.location}
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      location: e.target.value,
                    });
                  }}
                  toolTipText={"Please describe the Job Location."}
                />
                <InputField
                  required
                  title="Address Line"
                  placeholder="Address Line"
                  type="text"
                  value={jobDetail?.address}
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      address: e.target.value,
                    });
                  }}
                />
                <InputField
                  title="Address line 2 ( optional )"
                  placeholder="Address line 2 ( optional )"
                  type="text"
                  value={jobDetail?.adddressLine2}
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      adddressLine2: e.target.value,
                    });
                  }}
                />
                <InputField
                  required
                  title="Zip Code"
                  placeholder="Zip Code"
                  type="text"
                  value={jobDetail?.zipCode}
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      zipCode: e.target.value,
                    });
                  }}
                />
                <InputField
                  required
                  title="Arrival Time"
                  type="time"
                  value={jobDetail?.arrivalTime}
                  placeholder=""
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      arrivalTime: e?.target?.value,
                    });
                  }}
                />

                <MultiSelectDropdown
                  required
                  title="Client Provides"
                  placeholder="Client Provides"
                  options={
                    allOptionData?.postJobOptions?.client_provide?.field_meta
                      ?.choices
                  }
                  value={clientprovider}
                  setValue={setClientprovider}
                />
                <InputField
                  title="Primary client contact"
                  placeholder="Primary client contact"
                  type="text"
                  value={jobDetail?.primaryClientContact}
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      primaryClientContact: e.target.value,
                    });
                  }}
                />
                <InputField
                  title="Primary Client Phone Number"
                  placeholder="Primary Client Phone Number"
                  type="number"
                  value={jobDetail?.emergencyContact}
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      emergencyContact: e.target.value,
                    });
                  }}
                />

                {type ? (
                  <>
                    <InputField
                      title="Location Contact"
                      placeholder="Location Contact"
                      type="text"
                      value={jobDetail?.locationContact}
                      onChangeText={(e) => {
                        setJobDetail({
                          ...jobDetail,
                          locationContact: e.target.value,
                        });
                      }}
                      toolTipText={
                        "This is the person with whom your talent will contact at the location."
                      }
                    />
                    <InputField
                      title="Location Contact Phone Number"
                      placeholder="Location Contact Phone Number"
                      type="number"
                      value={jobDetail?.locationContactPhone}
                      onChangeText={(e) => {
                        setJobDetail({
                          ...jobDetail,
                          locationContactPhone: e.target.value,
                        });
                      }}
                      // toolTipText={
                      //   "This is the person with whom your talent will contact at the location."
                      // }
                    />
                  </>
                ) : undefined}
                <DropdownList
                  title="Other Contact"
                  placeholder="Other Contact"
                  options={
                    allOptionData?.postJobOptions?.other_contact?.field_meta
                      ?.choices
                  }
                  value={otherContact}
                  setValue={setOtherContact}
                />
                <div className="fullCol">
                  <span>
                    This contact information will not go public. It will be
                    viewed by the talent that is awarded
                  </span>
                </div>
                <DropdownList
                  required
                  title="Type of works"
                  placeholder="Type of works"
                  options={
                    allOptionData?.postJobOptions?.type_of_work?.field_meta
                      ?.choices
                  }
                  value={workType}
                  setValue={setWorkType}
                />
                <MultiSelectDropdown
                  title="Talent Arrival Requirement"
                  placeholder="Talent Arrival Requirement"
                  options={
                    allOptionData?.postJobOptions?.talent_requirements
                      ?.field_meta?.choices
                  }
                  value={arrivalReq}
                  setValue={setArrivalReq}
                />
                <InputField
                  title="Talent requirement notes"
                  placeholder="Talent requirement notes"
                  type="text"
                  value={jobDetail?.reqNotes}
                  onChangeText={(e) => {
                    setJobDetail({
                      ...jobDetail,
                      reqNotes: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            {type === "jobPost" ? (
              <div class="form-section">
                <div className="form-section-title">Required Details</div>
                <div class="form-group form-group-50">
                  <DropdownList
                    // required
                    title="Gender"
                    placeholder="Select Gender"
                    options={
                      talentType === "Model" || talentType === "Actor"
                        ? genderTypes
                        : kidGenderTypes
                    }
                    value={gender}
                    setValue={setGender}
                  />

                  <div className="wt-widgetcontent">
                    <div className="wt-select">
                      <Form.Label>
                        {/* <span className="astrik_req">*</span> */}
                        Age
                      </Form.Label>
                      <select name="age" value={age} onChange={handleAgeChange}>
                        <option value="">Select Age</option>
                        {renderAgeOptions()}
                      </select>
                    </div>
                  </div>

                  <DropdownList
                    // required
                    title="Height (Inches)"
                    placeholder="Select Height"
                    options={
                      allOptionData?.otherOptions?.height?.field_meta?.choices
                    }
                    value={height}
                    setValue={setHeight}
                  />
                  <DropdownList
                    // required
                    title="Weight (Pounds)"
                    placeholder="Select Weight"
                    options={
                      allOptionData?.otherOptions?.weight?.field_meta?.choices
                    }
                    value={weight}
                    setValue={setWeight}
                  />

                  <MultiSelectDropdown
                    // required
                    title="Select Ethnicity"
                    placeholder="Select Ethnicity"
                    options={
                      allOptionData?.otherOptions?.ethnicity?.field_meta
                        ?.choices
                    }
                    value={ethinicity}
                    setValue={setEthinicity}
                    // isValid={error && ethinicity?.length == 0}
                  />
                  <DropdownList
                    // required
                    title="Hair color"
                    placeholder="Select Hair color"
                    options={
                      allOptionData?.otherOptions?.hair_colour?.field_meta
                        ?.choices
                    }
                    value={hairColor}
                    setValue={setHairColor}
                  />
                </div>
              </div>
            ) : undefined}

            {type === "jobPost" &&
            gender &&
            (talentType === "Model Kid" || talentType === "Actor Kid") ? (
              <div class="form-section">
                <div className="form-section-title">
                  {talentType === "Model Kid"
                    ? "Child Model Measurement"
                    : "Child Actor Measurement"}
                </div>
                <div class="form-group form-group-50">
                  <MultiSelectDropdown
                    // required
                    options={
                      allOptionData?.childOptions?.toddler_shirt_size
                        ?.field_meta?.choices
                    }
                    title="Shirt size"
                    placeholder="Select Shirt size"
                    value={shirtSizeC}
                    setValue={setShirtSizeC}
                    // isValid={error && shirtSizeC?.length == 0}
                  />
                  <MultiSelectDropdown
                    // required
                    title="Pant Size"
                    placeholder="Select Pant Size"
                    value={pantSizeWC}
                    setValue={setPantSizeWC}
                    options={
                      allOptionData?.childOptions?.toddler_pant_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && pantSizeWC?.length == 0}
                  />

                  <MultiSelectDropdown
                    // required
                    title="Shoe Size"
                    placeholder="Select Shoe Size"
                    value={shoeSizeC}
                    setValue={setShoeSizeC}
                    options={
                      allOptionData?.childOptions?.toddler_shoe_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && shoeSizeC?.length == 0}
                  />
                  {gender == "Female" || gender == "female" ? (
                    <MultiSelectDropdown
                      // required
                      title={"Dress Size"}
                      placeholder={"Select Dress Size"}
                      value={dressSizeC}
                      setValue={setDressSizeC}
                      options={
                        allOptionData?.childOptions?.toddler_dress_size
                          ?.field_meta?.choices
                      }
                      // isValid={error && dressSizeC?.length == 0}
                    />
                  ) : null}
                </div>
              </div>
            ) : undefined}

            {type === "jobPost" &&
            gender === "Female" &&
            (talentType === "Model" || talentType === "Actor") ? (
              <div class="form-section">
                <div className="form-section-title">
                  {talentType === "Model"
                    ? "Female Model Measurement"
                    : "Female Actor Measurement"}
                </div>
                <div class="form-group form-group-50">
                  <MultiSelectDropdown
                    // required
                    title={"Shirt Size"}
                    placeholder={"Select Shirt Size"}
                    value={shirtSizeF}
                    setValue={setShirtSizeF}
                    options={
                      allOptionData?.femaleOptions?.shirt_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && shirtSizeF?.length == 0}
                  />
                  <MultiSelectDropdown
                    // required
                    title={"Pant Size"}
                    placeholder={"Select Pant Size"}
                    value={pantSize}
                    setValue={setPantSize}
                    options={
                      allOptionData?.femaleOptions?.pant_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && pantSize?.length == 0}
                  />
                  <div className="fullCol">
                    <span>
                      Bra size and Bra cup will only be visible to clients.
                    </span>
                  </div>
                  <DropdownList
                    // required
                    title="Bra Cup"
                    placeholder="Select Bra Cup"
                    value={braCup}
                    setValue={setBraCup}
                    options={
                      allOptionData?.femaleOptions?.bra_cup?.field_meta?.choices
                    }
                    // isValid={error && braCup?.length == 0}
                  />
                  <DropdownList
                    // required
                    title="Bra Size"
                    placeholder="Select Bra Size"
                    value={braSize}
                    setValue={setBraSize}
                    options={
                      allOptionData?.femaleOptions?.bra_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && braSize?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Dress Size"
                    placeholder="Select Dress Size"
                    value={dressSize}
                    setValue={setDressSize}
                    options={
                      allOptionData?.femaleOptions?.dress_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && dressSize?.length == 0}
                  />

                  <MultiSelectDropdown
                    // required
                    title={"Shoe Size"}
                    placeholder={"Select Shoe Size"}
                    value={shoeSizeF}
                    setValue={setShoeSizeF}
                    options={
                      allOptionData?.femaleOptions?.shoe_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && shoeSizeF?.length == 0}
                  />
                </div>
              </div>
            ) : undefined}

            {type === "jobPost" &&
            gender === "Male" &&
            (talentType === "Model" || talentType === "Actor") ? (
              <div class="form-section">
                <div className="form-section-title">
                  {talentType === "Model"
                    ? "Male Model Measurement"
                    : "Male Actor Measurement"}
                </div>
                <div class="form-group form-group-50">
                  <MultiSelectDropdown
                    // required
                    title={"Shirt Size"}
                    placeholder={"Select Shirt Size"}
                    value={shirtSize}
                    setValue={setShirtSize}
                    options={
                      allOptionData?.maleOptions?.shirt_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && shirtSize?.length == 0}
                  />
                  <MultiSelectDropdown
                    // required
                    title={"Pant Size (Waist)"}
                    placeholder={"Select Pant Size"}
                    value={pantSizeW}
                    setValue={setPantSizeW}
                    options={
                      allOptionData?.maleOptions?.pant_size_waist?.field_meta
                        ?.choices
                    }
                    // isValid={error && pantSizeW?.length == 0}
                  />
                  <MultiSelectDropdown
                    // required
                    title={"Pant Size (Length)"}
                    placeholder={"Select Pant Size"}
                    value={pantSizeL}
                    setValue={setPantSizeL}
                    options={
                      allOptionData?.maleOptions?.pant_size_length?.field_meta
                        ?.choices
                    }
                    // isValid={error && pantSizeL?.length == 0}
                  />

                  <MultiSelectDropdown
                    // required
                    title={"Shoe Size"}
                    placeholder={"Select Shoe Size"}
                    value={shoeSize}
                    setValue={setShoeSize}
                    options={
                      allOptionData?.maleOptions?.shoe_size?.field_meta?.choices
                    }
                    // isValid={error && shoeSize?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Dress Shirt Size"
                    placeholder="Select Dress Shirt Size"
                    value={dressShirt}
                    setValue={setDressShirt}
                    options={
                      allOptionData?.maleOptions?.dress_shirt_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && dressShirt?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Dress Shirt Sleeve"
                    placeholder="Select Dress Shirt Sleeve"
                    value={shirtSleeve}
                    setValue={setShirtSleeve}
                    options={
                      allOptionData?.maleOptions?.dress_shirt_sleeve?.field_meta
                        ?.choices
                    }
                    // isValid={error && shirtSleeve?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Neck Size"
                    placeholder="Select Neck Size"
                    value={neckSize}
                    setValue={setNeckSize}
                    options={
                      allOptionData?.maleOptions?.neck_size?.field_meta?.choices
                    }
                    // isValid={error && neckSize?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Chest Size"
                    placeholder="Select Chest Size"
                    value={chestSize}
                    setValue={setChestSize}
                    options={
                      allOptionData?.maleOptions?.chest_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && chestSize?.length == 0}
                  />
                  <DropdownList
                    // required
                    title="Jacket"
                    placeholder="Select Jacket"
                    value={jacket}
                    setValue={setJacket}
                    options={
                      allOptionData?.maleOptions?.jacket?.field_meta?.choices
                    }
                    // isValid={error && jacket?.length == 0}
                  />
                </div>
              </div>
            ) : undefined}

            {type === "jobPost" &&
            gender === "Non Binary" &&
            (talentType === "Model" || talentType === "Actor") ? (
              <div class="form-section">
                <div className="form-section-title">
                  {talentType === "Model"
                    ? "Non Binary Model Measurement"
                    : "Non Binary Actor Measurement"}
                </div>
                <div class="form-group form-group-50">
                  <MultiSelectDropdown
                    // required
                    title={"Shirt Size"}
                    placeholder={"Select Shirt Size"}
                    value={shirtSize}
                    setValue={setShirtSize}
                    options={
                      allOptionData?.maleOptions?.shirt_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && shirtSize?.length == 0}
                  />
                  <MultiSelectDropdown
                    // required
                    title={"Pant Size (Waist)"}
                    placeholder={"Select Pant Size"}
                    value={pantSizeW}
                    setValue={setPantSizeW}
                    options={
                      allOptionData?.maleOptions?.pant_size_waist?.field_meta
                        ?.choices
                    }
                    // isValid={error && pantSizeW?.length == 0}
                  />
                  <MultiSelectDropdown
                    // required
                    title={"Pant Size (Length)"}
                    placeholder={"Select Pant Size"}
                    value={pantSizeL}
                    setValue={setPantSizeL}
                    options={
                      allOptionData?.maleOptions?.pant_size_length?.field_meta
                        ?.choices
                    }
                    // isValid={error && pantSizeL?.length == 0}
                  />

                  <MultiSelectDropdown
                    // required
                    title={"Shoe Size"}
                    placeholder={"Select Shoe Size"}
                    value={shoeSize}
                    setValue={setShoeSize}
                    options={
                      allOptionData?.maleOptions?.shoe_size?.field_meta?.choices
                    }
                    // isValid={error && shoeSize?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Dress Shirt Size"
                    placeholder="Select Dress Shirt Size"
                    value={dressShirt}
                    setValue={setDressShirt}
                    options={
                      allOptionData?.maleOptions?.dress_shirt_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && dressShirt?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Dress Shirt Sleeve"
                    placeholder="Select Dress Shirt Sleeve"
                    value={shirtSleeve}
                    setValue={setShirtSleeve}
                    options={
                      allOptionData?.maleOptions?.dress_shirt_sleeve?.field_meta
                        ?.choices
                    }
                    // isValid={error && shirtSleeve?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Neck Size"
                    placeholder="Select Neck Size"
                    value={neckSize}
                    setValue={setNeckSize}
                    options={
                      allOptionData?.maleOptions?.neck_size?.field_meta?.choices
                    }
                    // isValid={error && neckSize?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Chest Size"
                    placeholder="Select Chest Size"
                    value={chestSize}
                    setValue={setChestSize}
                    options={
                      allOptionData?.maleOptions?.chest_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && chestSize?.length == 0}
                  />
                  <DropdownList
                    // required
                    title="Jacket"
                    placeholder="Select Jacket"
                    value={jacket}
                    setValue={setJacket}
                    options={
                      allOptionData?.maleOptions?.jacket?.field_meta?.choices
                    }
                    // isValid={error && jacket?.length == 0}
                  />

                  <MultiSelectDropdown
                    // required
                    title={"Shirt Size"}
                    placeholder={"Select Shirt Size"}
                    value={shirtSizeF}
                    setValue={setShirtSizeF}
                    options={
                      allOptionData?.femaleOptions?.shirt_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && shirtSizeF?.length == 0}
                  />
                  <MultiSelectDropdown
                    // required
                    title={"Pant Size"}
                    placeholder={"Select Pant Size"}
                    value={pantSize}
                    setValue={setPantSize}
                    options={
                      allOptionData?.femaleOptions?.pant_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && pantSize?.length == 0}
                  />
                  <div className="fullCol">
                    <span>
                      Bra size and Bra cup will only be visible to clients.
                    </span>
                  </div>
                  <DropdownList
                    // required
                    title="Bra Cup"
                    placeholder="Select Bra Cup"
                    value={braCup}
                    setValue={setBraCup}
                    options={
                      allOptionData?.femaleOptions?.bra_cup?.field_meta?.choices
                    }
                    // isValid={error && braCup?.length == 0}
                  />
                  <DropdownList
                    // required
                    title="Bra Size"
                    placeholder="Select Bra Size"
                    value={braSize}
                    setValue={setBraSize}
                    options={
                      allOptionData?.femaleOptions?.bra_size?.field_meta
                        ?.choices
                    }
                    // isValid={error && braSize?.length == 0}
                  />

                  <DropdownList
                    // required
                    title="Dress Size"
                    placeholder="Select Dress Size"
                    value={dressSize}
                    setValue={setDressSize}
                    options={
                      allOptionData?.femaleOptions?.dress_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && dressSize?.length == 0}
                  />

                  <MultiSelectDropdown
                    // required
                    title={"Shoe Size"}
                    placeholder={"Select Shoe Size"}
                    value={shoeSizeF}
                    setValue={setShoeSizeF}
                    options={
                      allOptionData?.femaleOptions?.shoe_size_f?.field_meta
                        ?.choices
                    }
                    // isValid={error && shoeSizeF?.length == 0}
                  />
                </div>
              </div>
            ) : undefined}

            <div class="form-section">
              <div className="form-section-title">Price</div>
              <div class="form-group form-group-50">
                <DropdownList
                  required
                  title="Pay Type"
                  placeholder="Select Pay Type"
                  options={allOptionData?.pay_type}
                  value={payType}
                  setValue={setPayType}
                />
                <DropdownList
                  required
                  title="when you expect payment"
                  placeholder="Select when pay"
                  options={allOptionData?.when_you_expect_payment}
                  value={whenPayExpect}
                  setValue={setWhenPayExpect}
                />
                {payType === "Hourly Rate" ? undefined : (
                  <InputField
                    required
                    title="Project Rate"
                    placeholder="Project Rate"
                    type="number"
                    value={prices?.projectRate}
                    onChangeText={(e) => {
                      setPrices({
                        ...prices,
                        projectRate: e.target.value,
                      });
                    }}
                  />
                )}
                {payType === "Hourly Rate" ? (
                  <>
                    <InputField
                      required
                      title="Estimated Hours"
                      placeholder="Estimated Hours"
                      type="number"
                      value={prices?.estimateHrs}
                      onChangeText={(e) => {
                        setPrices({
                          ...prices,
                          estimateHrs: e.target.value,
                        });
                      }}
                    />
                    <InputField
                      required
                      title="Hourly Rate"
                      placeholder="Hourly Rate"
                      type="number"
                      value={prices?.hourlyRate}
                      onChangeText={(e) => {
                        setPrices({
                          ...prices,
                          hourlyRate: e.target.value,
                        });
                      }}
                    />
                  </>
                ) : undefined}
                <InputField
                  title="Amount of per diem provided"
                  placeholder="Amount Provided ($)"
                  type="number"
                  value={prices?.diemAmount}
                  onChangeText={(e) => {
                    setPrices({
                      ...prices,
                      diemAmount: e.target.value,
                    });
                  }}
                  toolTipText={
                    "This is the amount provided by the client per day for personal expenses during the project. This might include meals and milage."
                  }
                />
              </div>
            </div>

            <div class="form-section">
              <div className="form-section-title">Usage</div>
              <div class="form-group form-group-50">
                <DropdownList
                  title="Usage Duration"
                  placeholder="Select Usage Duration"
                  options={
                    allOptionData?.postJobOptions?.duration_usage?.field_meta
                      ?.choices
                  }
                  value={usageDuration}
                  setValue={setUsageDuration}
                />
                <DropdownList
                  title="Usage Location"
                  placeholder="Select Usage Location"
                  options={
                    allOptionData?.postJobOptions?.location_usage?.field_meta
                      ?.choices
                  }
                  value={usageLocation}
                  setValue={setUsageLocation}
                />
                <MultiSelectDropdown
                  required
                  title="Media Type"
                  placeholder="Media Type"
                  options={
                    allOptionData?.postJobOptions?.media_type_usage?.field_meta
                      ?.choices
                  }
                  value={mediaType}
                  setValue={setMediaType}
                />
                <InputField
                  title="Usage Fee"
                  placeholder="Usage Fee"
                  type="number"
                  value={usageFee}
                  onChangeText={(e) => setUsageFee(e?.target?.value)}
                  toolTipText={
                    "This is the fee associated with the cost of “duration, location, and media type"
                  }
                />
              </div>
            </div>

            {type ? (
              <div class="form-section">
                <div className="form-section-title">Language</div>
                <div class="form-group form-group-50">
                  <DropdownList
                    required
                    title="Language Level"
                    placeholder="Select Language Level"
                    options={allOptionData?.englishLevel}
                    value={englishLevel}
                    setValue={setEnglishLevel}
                  />

                  <MultiSelectDropdown
                    required
                    title="Select Language"
                    placeholder="Select Language"
                    options={
                      allOptionData?.languages?.length
                        ? allOptionData?.languages?.map(({ name, id }) => ({
                            label: name,
                            value: id,
                          }))
                        : []
                    }
                    value={languages}
                    setValue={setLanguages}
                  />
                </div>
              </div>
            ) : undefined}

            {type ? (
              <div class="form-section">
                <div className="form-section-title">Skills Required</div>
                <div class="form-group form-group-50">
                  <MultiSelectDropdown
                    // required
                    title="Choose Skills"
                    placeholder="Choose Skills"
                    options={
                      allOptionData?.otherOptions?.add_new_skill?.length
                        ? allOptionData?.otherOptions?.add_new_skill?.field_meta
                            ?.choices
                        : []
                    }
                    value={skills}
                    setValue={setSkills}
                  />
                </div>
              </div>
            ) : undefined}

            {type ? (
              <div class="form-section">
                <div className="form-section-title">Add Custom Skills</div>
                <div class="form-group form-group-50">
                  <InputField
                    // required
                    title="Add Custom Skills"
                    placeholder="Add Custom Skills"
                    type="textarea"
                    value={customSkills}
                    onChangeText={(e) => setCustomSkills(e?.target?.value)}
                  />
                </div>
              </div>
            ) : undefined}

            {type ? undefined : (
              <div class="form-section lasfield">
                <div className="form-section-title">Message</div>
                <div class="form-group">
                  <InputField
                    required
                    title="Message"
                    placeholder="Type your Message"
                    type="textarea"
                    value={message}
                    onChangeText={(e) => setMessage(e?.target?.value)}
                  />
                </div>
              </div>
            )}
            <Button onClick={handleForm} class="wt-btn hide_org" type="button">
              Save
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
