import React, { useEffect, useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink, useNavigate } from "react-router-dom";
import { clearData, getData, storageKey } from "../../Utility/Storage";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { routeName, showToast } from "../../Utility";
import { Button, Modal } from "react-bootstrap";
const Sidebar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state?.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [userData, setUserData] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState("");
  const [accountApproval, setAccountApproval] = useState(true);
  const [show, setShow] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null);

  const toggleSubItems = (key) => {
    if (expandedKey === key) {
      setExpandedKey(null); // Close if already open
    } else {
      setExpandedKey(key); // Open if closed
    }
  };

  useEffect(() => {
    getApprovalStatus();
    getUserData();
  }, []);
  const getApprovalStatus = async () => {
    let accountApproval = await getData(storageKey?.APPROVAL_STATUS);
    setAccountApproval(JSON?.parse(accountApproval));
  };

  useEffect(() => {
    if (props?.load) {
      getUserData();
    }
  }, [props?.load]);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    setUserId(userID);
    let userRole = await getData(storageKey?.USER_ROLE);
    setUserRole(userRole);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status == 200) {
        setUserData(res.results);
      }
    } else {
      setUserData("");
      setUserId("");
    }
  };

  const logout = async () => {
    clearData();
    showToast("Logged out successfully!", "success");
    setShow(false);
    getUserData();
    navigate(routeName?.HOME);
  };

  const dropItems = [
    {
      key: 1,
      name: "Dashboard",
      path: routeName?.DASHBOARD,
      icon: "th-large",
      hide: false,
    },
    {
      key: 2,
      name: "Inbox",
      path: routeName?.INBOX,
      icon: "envelope",
      hide: accountApproval ? false : true,
    },
    {
      key: 3,
      name: accountApproval ? "Edit Profile" : "Edit Application",
      path: routeName?.EDIT_PROFILE,
      icon: "pencil-square",
      hide: false,
    },

    {
      key: 4,
      name: "Identity Verification",
      path: routeName?.IDENTITY_VERIFY,
      icon: "check-square",
      hide: loggedInUser?.user_data?.profile_approval ? false : true,
    },
    {

      key: 5,
      name: "Payout Settings",  
      path: routeName?.PAYOUT,
      icon: "money",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        loggedInUser?.user_data?.profile_approval
          ? false
          : true,
    },
    
    {
      key: 6,
      name: "Post Job",
      path: routeName?.JOB_POST,
      icon: "plus-square",
      hide:
        (Number(loggedInUser?.user_data?.user_role) == 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14) &&
        loggedInUser?.user_data?.profile_approval &&
        loggedInUser?.user_data?.identity_status !== 0
          ? false
          : true,
    },
    {
      key: 7,
      name: "Post Calling Calls",
      path: routeName?.CASTING_JOB,
      icon: "plus-square",
      hide:
        (Number(loggedInUser?.user_data?.user_role) == 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14) &&
        loggedInUser?.user_data?.profile_approval &&
        loggedInUser?.user_data?.identity_status !== 0
          ? false
          : true,
    },
    {
      key: 11,
      name: "Manage Portfolio",
      path: "",
      icon: "camera",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        Number(loggedInUser?.user_data?.user_role) !== 14 &&
        loggedInUser?.user_data?.profile_approval &&
        loggedInUser?.user_data?.identity_status !== 0
          ? false
          : true,
      subItem: [
        {
          key: 1,
          name: "Add Portfolio",
          path: routeName?.ADD_PORTFOLIO,
          icon: "plus-square",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval &&
            loggedInUser?.user_data?.identity_status !== 0
              ? false
              : true,
        },
        {
          key: 2,
          name: "Portfolio Listing",
          path: routeName?.MANAGE_PORTFOLIO,
          icon: "tasks",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval &&
            loggedInUser?.user_data?.identity_status !== 0
              ? false
              : true,
        },
      ],
    },
    {
      key: 12,
      name: "Manage Posts",
      path: "",
      icon: "camera",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        Number(loggedInUser?.user_data?.user_role) !== 14 &&
        loggedInUser?.user_data?.profile_approval &&
        loggedInUser?.user_data?.identity_status !== 0
          ? false
          : true,
      subItem: [
        {
          key: 1,
          name: "Posts Listing",
          path: routeName?.MANAGE_POST,
          icon: "list",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval &&
            loggedInUser?.user_data?.identity_status !== 0
              ? false
              : true,
        },
        {
          key: 2,
          name: "Add Social Post",
          path: routeName?.ADD_POST,
          icon: "plus-square",
          hide:
            Number(loggedInUser?.user_data?.user_role) !== 12 &&
            Number(loggedInUser?.user_data?.user_role) !== 14 &&
            loggedInUser?.user_data?.profile_approval &&
            loggedInUser?.user_data?.identity_status !== 0
              ? false
              : true,
        },
      ],
    },

    {
      key: 8,
      name: "Manage Jobs",
      path: routeName?.MODEL_JOBS,
      icon: "tasks",
      hide:
        (Number(loggedInUser?.user_data?.user_role) == 11 ||
          Number(loggedInUser?.user_data?.user_role) === 14) &&
        loggedInUser?.user_data?.profile_approval &&
        loggedInUser?.user_data?.identity_status !== 0
          ? false
          : true,
    },
    {
      key: 9,
      name: "Manage Projects",
      path: routeName?.CLIENT_PROJECTS,
      icon: "tasks",
      hide:
        (Number(loggedInUser?.user_data?.user_role) == 12 ||
          Number(loggedInUser?.user_data?.user_role) === 14) &&
        loggedInUser?.user_data?.profile_approval &&
        loggedInUser?.user_data?.identity_status !== 0
          ? false
          : true,
    },
    {
      key: 10,
      name: "Packages",
      path: routeName?.PACKAGES,
      icon: "tags",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        Number(loggedInUser?.user_data?.user_role) !== 14 &&
        loggedInUser?.user_data?.profile_approval &&
        loggedInUser?.user_data?.identity_status !== 0
          ? false
          : true,
    },
    {

      key: 11,
      name: "Saved collection",  
      path: routeName?.SAVED_COLLECTION,
      icon: "save",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        loggedInUser?.user_data?.profile_approval
          ? false
          : true,
    },
    {
      key: 12,
      name: "Follow details",  
      path: routeName?.FOLLOW_DETAILS,
      icon: "save",
      hide:
        Number(loggedInUser?.user_data?.user_role) !== 12 &&
        loggedInUser?.user_data?.profile_approval
          ? false
          : true,
    },
  ];
  return (
    <div
      style={{ display: "flex", height: "100vh", overflow: "scroll initial" }}
      className="sideBarLeft"
    >
      <CDBSidebar textColor="#fff" backgroundColor="#333">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a
            href=""
            className="text-decoration-none"
            style={{ color: "inherit" }}
          >
            {userData?.user_data?.full_name
              ? userData?.user_data?.full_name
              : userData?.user_data?.first_name +
                userData?.user_data?.last_name}
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            {dropItems?.length > 0 &&
              dropItems.map((item) => (
                <React.Fragment key={item.key}>
                  {!item.hide && (
                    <NavLink
                      onClick={() => {
                        if (!item.subItem) {
                          navigate(item.path);
                        } else {
                          toggleSubItems(item.key);
                        }
                      }}
                      exact
                      to={item.path || "#"}
                      activeClassName="activeClicked"
                    >
                      <CDBSidebarMenuItem icon={item.icon}>
                        <span>{item.name}</span>
                        {item.subItem && (
                          <i
                            className={
                              expandedKey
                                ? "fa fa-angle-up"
                                : "fa fa-angle-down"
                            }
                            style={{
                              position: "absolute",
                              right: 5,
                              top: 14,
                            }}
                          ></i>
                        )}
                      </CDBSidebarMenuItem>
                    </NavLink>
                  )}
                  {item.subItem &&
                    item.key === expandedKey &&
                    item.subItem.map((sub) => (
                      <NavLink
                        key={sub.key}
                        onClick={() => navigate(sub.path)}
                        // exact
                        to={sub.path}
                        activeClassName="activeClicked sub_item_show"
                      >
                        <CDBSidebarMenuItem icon={sub.icon} style={{   marginLeft:50}}>
                          {sub.name}
                        </CDBSidebarMenuItem>
                      </NavLink>
                    ))}
                </React.Fragment>
              ))}

            <NavLink
              onClick={() => setShow(true)}
              exact
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="fa fa-sign-out">
                Log Out
              </CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>

        {/* <CDBSidebarFooter style={{ textAlign: "center" }}>
          <div
            style={{
              padding: "20px 5px",
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter> */}
      </CDBSidebar>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to Log out for now. You can login to this account again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Not Now
          </Button>
          <Button variant="primary" onClick={() => logout()}>
            Yes, Log Out
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
