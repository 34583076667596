import React, { useState } from "react";
import { initLightboxJS } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { SlideshowLightbox } from "lightbox.js-react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PortfolioData = ({
  portfolioData,
  setSlideIndex2,
  setlightPortbox,
  lightPortbox,
  slideIndex2,
  setlightbox,
  setlightActorbox,
  setSlideActorIndex,
  setSlideIndex,
  setSelectedImages,
}) => {
  // const [slideIndex2, setSlideIndex2] = useState(-1)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div className="post-card-grid ">
      <div className="row">
        {portfolioData?.length ? (
          portfolioData?.map((item, cardIndex) => (
            <div key={item.id} className="col-md-6">
              <Slider {...settings}>
                {Object?.values(item?.media)?.map((image, index) => {
                  return (
                    <div
                      onClick={() => {
                        setSlideIndex2(-1);
                        setSelectedImages(-1);
                        setlightbox(false);
                        setSlideIndex(-1);
                        setSlideActorIndex(-1);
                        setlightActorbox(false);
                        setlightPortbox(true);
                        setSlideIndex2(index);
                        setSelectedImages(cardIndex);
                      }}
                      key={index}
                    >
                      <img src={image?.url} alt={`Image ${index}`} />
                    </div>
                  );
                })}
              </Slider>
              <div className="postDetails">
                {item?.post_details?.post_title  ?  <h5>{item?.post_details?.post_title || ""}</h5> : undefined}
 
              {item?.post_details?.post_content ?  <p>{item?.post_details?.post_content || ""}</p> : undefined}
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-12">No Data Found</div>
        )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioData;
