import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const LoginAlert = (props) => {
  const { setVisible, visible,  setLoginModal } = props;

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You need to Login to perform this action!</p>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setVisible(false);
          }}
        >
          Close
        </Button>
        <Button
          onClick={() => {
            setVisible(false);
            setTimeout(() => {
              setLoginModal(true);
            }, 100);
          }}
        >
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
