export const Images = {
  logo: require("../Assets/Images/PNG/logo.png"),
  hero1: require("../Assets/Images/madelyn.png"),
  hero2: require("../Assets/Images/brook.png"),
  banner: require("../Assets/Images/banner.jpeg"),
  newsletter: require("../Assets/Images/newsletter.png"),
  notFound: require("../Assets/Images/404_page-not-found.webp"),
  inboxImage: require("../Assets/Images/thumbnail/img-19.png"),
  latestProposals: require("../Assets/Images/thumbnail/img-20.png"),
  packageExpiry: require("../Assets/Images/thumbnail/img-21.png"),
  followers: require("../Assets/Images/thumbnail/img-22.png"),
  tiktok: require("../Assets/Images/tiktok.png"),
  bag: require("../Assets/Images/bag.png")
};
