import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip, InputGroup } from "react-bootstrap";
import { storageKey, storeData } from "../Utility/Storage";
import Select from "react-select";
import { BsSearch } from "react-icons/bs";
export const DropdownList = (props) => {
  const {
    type,
    title,
    options,
    placeholder,
    value,
    setValue,
    required,
    tooltip,
    disabled,
    onSelect,
    toolTipText,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const filteredOptions = "";

  const handleSelectOption = (ele) => {
    const selectedValue = ele.target.value;
    const selectedOptionObject = options.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    if (type) {
      if (type == "country") {
        storeData(
          storageKey?.COUNTRY_ID,
          JSON?.stringify(selectedOptionObject?.id)
        );
      } else if (type == "state") {
        storeData(
          storageKey?.STATE_ID,
          JSON?.stringify(selectedOptionObject?.id)
        );
      }
    }
    if (onSelect) {
      onSelect();
    }
    if (ele.target.value == "") {
      setValue("");
    } else {
      setValue(ele.target.value);
    }
  };

  return (
    <>
      <Form.Group>
        {toolTipText ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">{toolTipText}</Tooltip>}
          >
            <Form.Label>
              {required && <span className="astrik_req">*</span>}
              {title}
              <span className="tooltipOverlay">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </span>
            </Form.Label>
          </OverlayTrigger>
        ) : (
          title && (
            <Form.Label>
              {required && <span className="astrik_req">*</span>} {title}
            </Form.Label>
          )
        )}
        <Form.Select
          required={required}
          className="form-control"
          onChange={(e) => {
            handleSelectOption(e);
          }}
          value={value}
          isValid={(required && value) || (!required && value)}
          // isInvalid={!required && value}
          // isInvalid={required && !value }
          disabled={disabled}
        >
          <option value="">{placeholder}</option>
          {options?.length != 0 &&
            options?.map((item, index) => {
              return (
                <>
                  <option
                    value={item?.value ? item?.value : item?.name}
                    label={
                      item?.value
                        ? item?.value == "Model Kid"
                          ? item?.value + " (13 years and under)"
                          : item?.value == "Model"
                          ? item?.value + " (14 years and above)"
                          : item?.value == "Actor Kid"
                          ? item?.value + " (13 years and under)"
                          : item?.value == "Actor"
                          ? item?.value + " (14 years and above)"
                          : item?.value
                        : item?.name
                    }
                  >
                    {item?.value
                      ? item?.value == "Model Kid"
                        ? item?.value + " (13 years and under)"
                        : item?.value == "Model"
                        ? item?.value + " (14 years and above)"
                        : item?.value == "Actor Kid"
                        ? item?.value + " (13 years and under)"
                        : item?.value == "Actor"
                        ? item?.value + " (14 years and above)"
                        : item?.value
                      : item?.name}
                  </option>
                </>
              );
            })}
        </Form.Select>
        {required && value ? (
          <i className="fa fa-angle-down dropDownIcon"></i>
        ) : null}

        {required && value?.length === 0 ? (
          <Form.Control.Feedback type={"invalid"}>
            This field is required !
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};
