import React from "react";
import { Header, Footer } from "../../Components";
import Accordion from "react-bootstrap/Accordion";
import { getFaqList, getJobDetails } from "../../Redux/Services/OtherServices";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const Faq = () => {
  const dispatch = useDispatch();
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFaq = async () => {
    let body = [];
    setLoading(true);
    let res = await dispatch(getFaqList(body));
    if (res?.status == 200) {
      setFaqList(res?.results);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getFaq();
  }, []);
  return (
    <div>
      <Header />
      <div className="pageTitle">
        <div className="wt-title">
          <h1>Frequently Asked Question</h1>
        </div>
      </div>
      <div className="container">
        <div class="wt-userprofileholder contactForm">
          <div className="row">
            <div className="col-md-12">
              <Accordion defaultActiveKey="0">
                {faqList?.map((item, index) => {
                  return (
                    <>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          {item?.faq_question}
                        </Accordion.Header>
                        <Accordion.Body>{item?.faq_answer}</Accordion.Body>
                      </Accordion.Item>
                    </>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
