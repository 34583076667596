// Filename - Home.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import {
  Header,
  Footer,
  Loader,
  Filter,
  AppliedFilters,
} from "../../Components";
import { getJobFilter, getJobsList, savePostApi, unsavePostApi } from "../../Redux/Services/OtherServices";
import { getOptionsData, getUserDetail } from "../../Redux/Services/AuthServices";
import { SitePagination } from "../../Components/filtersComponents";
import { routeName, showToast } from "../../Utility";
import moment from "moment";
import { getData, storageKey } from "../../Utility/Storage";
import { LoginAlert } from "../../Components/Popups/LoginAlert";
import { Login } from "../../Components/Popups";

export const SearchJobs = () => {
  const dispatch = useDispatch();

  const other = useSelector((state) => state?.otherReducer);
  const auth = useSelector((state) => state?.authReducer);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [jobsList, setJobsList] = useState([]);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [showFilter, setShowFilter] = useState(true);
  const resultsPerPage = 20;
  let totalPages = Math.ceil(totalCount / resultsPerPage);
  const [selectedSizes, setSelectedSizes] = useState({
    shirtSize: [],
    pantSize: [],
    shoeSize: [],
    dressShirtSize: [],
    braCup: [],
    braSize: [],
    dressSize: [],
    pantSizeL: [],
    pantSizeW: [],
    dressShirtSleeve: [],
    neckSize: [],
    chestSize: [],
    jacket: [],
    dressSizeFemale: [],
    shirtSizeFemail: [],
    pantSizeFemale: [],
    shoeSizeFemale: [],
  });
  const [isSpace, setIsSpace] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [mileRange, setMileRange] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({
    hair_colour: [],
    weight: [],
    ethnicities: [],
    height: [],
    language: [],
    english_level: [],
    influencers: [],
    // gender: "",
    // age: "",
    // talentType: "model",
    hourlyRate: [],
    skills: [],
    // keyword: "",
    projectType: "",
    projectLength: [],
    projectLocation: [],
  });
  const [selectedAge, setSelectedAge] = useState('');
  const [selectedGender, setSelectedGender] = useState(
    ""
  );
  const [selectedTalent, setSelectedTalent] = useState(
     ""
  );
  const [selectedkeyword, setSelectedkeyword] = useState(
     ""
  );

  const startRange = jobsList?.length ? (page - 1) * resultsPerPage + 1 : 0;
  const endRange = Math.min(page * resultsPerPage, totalCount);

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  const getAllJobsList = async () => {
    const userId = await getData(storageKey.USER_ID);

    console.log(userId,"Lplplppl");
    var body = {
      page_number: page,
      per_page: resultsPerPage,
      user_id: userId ? JSON.parse(userId) : ""
    };
      setLoading(true);
    let res = await dispatch(getJobsList(body));
    setLoading(false);
    if (res?.status == 200) {
      setJobsList(res?.results?.list);
      setTotalCount(res?.results?.pagination?.total_result);
    }
  };

  // const getAllFiltersData = async () => {
  //   let res = await dispatch(getOptionsData());
  // };

  const handleApplyFilter = async (type) => {
    const userId = await getData(storageKey.USER_ID);
    setLoading(true);
    var body = {
      page_number: page,
      per_page: resultsPerPage,
      user_id: userId ? JSON.parse(userId) : ""
    };

    if (mileRange !== 0) {
      body.searchmilescity = mileRange;
    }

    if (selectedTalent) {
      // body.talent_type =
      //   selectedFilters?.talentType === "kid" ? "child" : "model";
      body.user_type =
        selectedTalent === "model" || selectedTalent === "modelKid"
          ? "talent"
          : "actor";
      body.talent_type =
        selectedTalent === "modelKid" || selectedTalent === "actorKid"
          ? "child"
          : selectedTalent;
    }

    const addIfDefined = (key, value) => {
      if (value?.length) {
        body[key] = value;
      }
    };

    addIfDefined("project_type", selectedFilters?.projectType);

    addIfDefined("min_price", minPrice);

    addIfDefined("max_price", maxPrice);

    addIfDefined(
      "job_option",
      selectedFilters?.projectLocation?.map((item) => item.value)
    );

    addIfDefined("age", selectedAge);
    addIfDefined(
      "bra_cup",
      selectedSizes?.braCup?.map((item) => item.value)
    );
    addIfDefined(
      "bra_size",
      selectedSizes?.braSize?.map((item) => item.value)
    );
    addIfDefined("country", country);
    addIfDefined("state", state);
    addIfDefined("city", city);
    addIfDefined("searchmilescity", mileRange);

    addIfDefined(
      "dress_shirt_size",
      selectedSizes?.dressShirtSize?.map((item) => item.value)
    );
    addIfDefined(
      "dress_shirt_sleeve",
      selectedSizes?.dressShirtSleeve?.map((item) => item.value)
    );
    addIfDefined(
      "dress_size",
      selectedSizes?.dressSize?.map((item) => item.value)
    );
    addIfDefined(
      "dress_size_female",
      selectedSizes?.dressSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "english_level",
      selectedFilters?.english_level?.map((item) => item.value)
    );

    addIfDefined("gender", selectedGender === "Non Binary" ? "other" : selectedGender);

    addIfDefined(
      "hourly_rate",
      selectedFilters?.hourlyRate?.map((item) =>
        item.value?.includes("+") ? item.value?.replace(/\+/, "") : item.value
      )
    );
    addIfDefined(
      "project_duration",
      selectedFilters?.projectLength?.map((item) => item.value)
    );
    addIfDefined(
      "jacket",
      selectedSizes?.jacket?.map((item) => item.value)
    );
    addIfDefined(
      "neck_size",
      selectedSizes?.neckSize?.map((item) => item.value)
    );
    addIfDefined(
      "chest_size",
      selectedSizes?.chestSize?.map((item) => item.value)
    );
    addIfDefined(
      "language",
      selectedFilters?.language?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_female",
      selectedSizes?.pantSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_length",
      selectedSizes?.pantSizeL?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_waist",
      selectedSizes?.pantSizeW?.map((item) => item.value)
    );
    addIfDefined(
      "shirt_size",
      selectedSizes?.shirtSize?.map((item) => item.value)
    );
    addIfDefined(
      "shoe_size",
      selectedSizes?.shoeSize?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size",
      selectedSizes?.pantSize?.map((item) => item.value)
    );
    addIfDefined(
      "shirt_size_female",
      selectedSizes?.shirtSizeFemail?.map((item) => item.value)
    );
    addIfDefined(
      "shoe_size_female",
      selectedSizes?.shoeSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "skill",
      selectedFilters?.skills?.map((item) => item.value)
    );

    addIfDefined("keyword", selectedkeyword);
    console.log("selectedFiltersbody-----", body);
    let res = await dispatch(getJobFilter(body));
    setLoading(false);
    if (res?.status == 200) {
      console.log("getSearchResultsgetSearchResults-----", res);
      setJobsList(res?.results?.search);
      setTotalCount(res?.results?.pagination?.total_result);
    }
  };

  useEffect(() => {
    if(isSubmit){

      handleApplyFilter();
    }else {
      getAllJobsList()
    }
  }, [page, isSubmit]);

  useEffect(() => {
    // setLoading(true);
    // getAllJobsList();
  }, []);

  // useEffect(() => {
  //   getAllFiltersData();
  // }, []);

  return (
    <div>
      <Header />
      <Loader
        loading={
          loading
            ? loading
            : other?.isLoading
            ? other?.isLoading
            : auth?.isLoading
            ? auth?.isLoading
            : false
        }
      />

      <div className="pageTitle">
        <div className="wt-title">
          <h1>Search Jobs</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <a>Home</a>
            </li>
            <li className="last-item">Search Jobs</li>
          </ol>
        </div>
      </div>

      <section className="searchtalentList">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-3 float-left sidebarFilterFixed">
            <div className="wt-usersidebaricon" onClick={() => setShowFilter(!showFilter)} >
                Refine your search
              </div>
              <Filter
               showFilter={showFilter}
               setShowFilter={setShowFilter}
                setSelectedSizes={setSelectedSizes}
                selectedSizes={selectedSizes}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                setCountry={setCountry}
                country={country}
                state={state}
                setState={setState}
                city={city}
                setCity={setCity}
                mileRange={mileRange}
                setMileRange={setMileRange}
                handleApplyFilter={handleApplyFilter}
                setPage={setPage}
                type="job"
                setIsSubmit={setIsSubmit}
                setMinPrice={setMinPrice}
                minPrice={minPrice}
                setMaxPrice={setMaxPrice}
                maxPrice={maxPrice}
                selectedAge={selectedAge}
                setSelectedAge={setSelectedAge}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                selectedkeyword={selectedkeyword}
                setSelectedkeyword={setSelectedkeyword}
                setSelectedTalent={setSelectedTalent}
                selectedTalent={selectedTalent}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-9 float-left">
              <div className="wt-userlistingholder wt-userlisting wt-haslayout">
                <div className="wt-userlistingtitle">
                  {loading ? undefined : (
                    <span>
                      {`${startRange} - ${endRange} of ${totalCount} results for`}{" "}
                      <em>"Jobs"</em>
                    </span>
                  )}
                </div>
                <div className={isSpace ? "wt-filterholder" : ""}>
                  <AppliedFilters
                    setSelectedSizes={setSelectedSizes}
                    selectedSizes={selectedSizes}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    setCountry={setCountry}
                    country={country}
                    state={state}
                    setState={setState}
                    city={city}
                    setCity={setCity}
                    mileRange={mileRange}
                    setMileRange={setMileRange}
                    getAllModelsList={getAllJobsList}
                    setIsSpace={setIsSpace}
                    setIsSubmit={setIsSubmit}
                    setMinPrice={setMinPrice}
                    minPrice={minPrice}
                    setMaxPrice={setMaxPrice}
                    maxPrice={maxPrice}
                    selectedAge={selectedAge}
                    setSelectedAge={setSelectedAge}
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                    selectedkeyword={selectedkeyword}
                    setSelectedkeyword={setSelectedkeyword}
                    setSelectedTalent={setSelectedTalent}
                    selectedTalent={selectedTalent}
                  />
                </div>
                <section className="joblisting">
                  {jobsList?.map((item, index) => {
                    return (
                      <>
                        <JobCard cardData={item} handleApplyFilter={isSubmit ? handleApplyFilter : getAllJobsList}  />
                      </>
                    );
                  })}
                </section>
                {!loading && !jobsList?.length ? (
                  <section className="no_data">
                    <div>No Data Found</div>
                  </section>
                ) : (
                  <></>
                )}

                <SitePagination
                  module={jobsList}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

const JobCard = (props) => {
  const { cardData, handleApplyFilter } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [isJobSaved, setIsJobSaved] = useState(false);

  const handleSaveJob = async (postId, status) => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      let body = {
        user_id: JSON.parse(user),
        post_id: postId,
        type: status == 1 ? 'unsaved' : "saved",
        post_type: "213",
      };
      let res = '';
      if(status == 1){
        res = await dispatch(unsavePostApi(body))
      }else{
       res= await dispatch(savePostApi(body))
      }
      if (res?.status == 200) {
        handleApplyFilter()
        showToast(status == 1 ? "Job Removed Successfully" : 'Job Saved Successfully', 'success')
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
    } else {
    }
  };

  return (
    <>
      <div class="wt-userlistinghold wt-userlistingholdvtwo">
        <div class="wt-userlistingcontent">
          <div class="wt-contenthead">
            <div class="wt-title">
              <div className="wrTitle">
                <small>{moment(cardData?.profile?.post_date).fromNow()}</small>
                <h2>
                  {/* <img
                decoding="async"
                src={cardData?.profile_image}
                alt="Testaccount test"
              /> */}

                  {cardData?.profile?.post_title}
                </h2>
                {cardData?.post_meta_details?.model_type_req ===
                "casting calls" ? (
                  <small className="castingCalls">Casting calls</small>
                ) : undefined}
              </div>

              <div onClick={() => handleSaveJob(cardData?.profile?.ID, cardData?.post_meta_details?.saved_status)} class="wt-clicklike">
                <i class={cardData?.post_meta_details?.saved_status == 1 ? 'fa fa-bookmark' : "fa fa-bookmark-o"}></i>
              </div>

              {/* <i class="fa-solid fa-heart"></i> */}

              <div class="wt-description">
                <p>
                  {cardData?.profile?.post_content && (
                    <p>{cardData?.profile?.post_content}</p>
                  )}
                </p>
              </div>
            </div>

            {/* <div class="wt-tag wt-widgettag">
              <a href="javascript:void(0);">PHP</a>
              <a href="javascript:void(0);">HTML</a>
              <a href="javascript:void(0);">JQuery</a>
            </div> */}
          </div>
          <div class="wt-viewjobholder">
            <ul>
              <li>
                <span>
                  <i class="fa fa-flag" aria-hidden="true"></i>{" "}
                  {cardData?.post_meta_details?.country}/{" "}
                  {cardData?.post_meta_details?.city}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-cogs" aria-hidden="true"></i>Expert: All level
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-gavel" aria-hidden="true"></i>Proposal:{" "}
                  {cardData?.post_meta_details?.proposal_count || 0}
                </span>
              </li>
              <li>
                <span>
                  <i class="far fa-folder "></i>Type:{" "}
                  {cardData?.post_meta_details?._project_type}
                </span>
              </li>
              <li>
                <span>
                  <i class="fa fa-money "></i>Budget:{" "}
                  {/* {cardData?.post_meta_details?._hourly_rate
                    ? `Hourly Rate - $${cardData?.post_meta_details?._hourly_rate}`
                    : cardData?.post_meta_details?._estimated_hours
                    ? `Estimated Hours - $${cardData?.post_meta_details?._estimated_hours}`
                    : "0/hr"} */}
                     {cardData?.post_meta_details?.project_type?.project_cost
                            ? "$" + cardData?.post_meta_details?.project_type?.project_cost
                            : cardData?.post_meta_details?._hourly_rate
                            ? "$" +
                              cardData?.post_meta_details?._hourly_rate 
                              + " (" + "Estimated Hours: " +
                              cardData?.post_meta_details?._estimated_hours +
                              " hours)"
                            : 0}
                </span>
              </li>
              <li class="wt-btnarea">
                <div
                  onClick={() => {
                    const queryParams = new URLSearchParams({
                      id: cardData?.profile?.ID,
                    });
                    navigate(
                      `${routeName.JOB_DETAILS}?${queryParams.toString()}`
                    );
                  }}
                  // onClick={() => {
                  //    navigate('/jobdetails', {replace: true})
                  // }}
                  class="wt-btn"
                >
                  View Job
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {loginModalVisible && (
          <LoginAlert
            title="hhiiii"
            text="hdfj"
            setVisible={setLoginModalVisible}
            visible={loginModalVisible}
            setLoginModal={setLoginModal}
            loginModal={loginModal}
          />
        )}

{loginModal && (
          <Login
            show={loginModal}
            setShow={setLoginModal}
            getUserData={getUserData}
          />
        )}
    </>
  );
};
