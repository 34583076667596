import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  addComment,
  getPortfolioDetails,
  getSavedPost,
  getSocialPostDetails,
  likeDislike,
  savePostApi,
  unsavePostApi,
} from "../../Redux/Services/OtherServices";
import SidebarLayout from "../../layout/SidebarLayout";
import { getData, storageKey } from "../../Utility/Storage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Footer, Header } from "../../Components";
import { Container } from "react-bootstrap";
import moment from "moment";

const SocialDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  const type = queryParams?.get("type");

  const [postDetail, setPostDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [replyIndex, setReplyIndex] = useState(null);
  const [replies, setReplies] = useState({});
  const [savedItems, setSavedItems] = useState([]);

  const handleReplyChange = (index, text) => {
    setReplies({ ...replies, [index]: text });
  };

  const handleReply = async (e, index, commentId) => {
    e.stopPropagation();
    if (replyIndex === index) {
      // console.log("Reply:", replies[index]);
      let userId = await getData(storageKey?.USER_ID);
      try {
        let body = {
          portfolio_id: id,
          user_id: JSON.parse(userId),
          type: "reply",
          comment_id: commentId,
          comment: replies[index],
        };
        setLoading(true);
        let res = await dispatch(addComment(body));
        setLoading(false);
        if (res?.status === 200) {
          setReplies({ ...replies, [index]: "" });
          if (type === "post") {
            getSinglePostData();
          } else {
            getSinglePortfolioData();
          }
        }
      } catch (err) {
        setLoading(false);
      }
    } else {
      if (replyIndex === index) {
        setReplyIndex("");
      } else {
        setReplyIndex(index);
      }
      if (!(index in replies)) {
        setReplies({ ...replies, [index]: "" });
      }
    }
  };

  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img src={`${postDetail?.media?.[i]?.url}`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getSinglePostData = async () => {
    try {
      let body = {
        social_id: id,
      };
      setLoading(true);
      let res = await dispatch(getSocialPostDetails(body));
      setLoading(false);
      if (res?.status === 200) {
        setPostDetail(res?.results?.[0]);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getSinglePortfolioData = async () => {
    let userId = await getData(storageKey?.USER_ID);
    try {
      let body = {
        port_id: id,
        user_id: JSON.parse(userId),
      };
      setLoading(true);
      let res = await dispatch(getPortfolioDetails(body));
      setLoading(false);
      if (res?.status === 200) {
        // console.log("resssssssss", res);
        setPostDetail(res?.results?.[0]);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  // getSavedPost

  const getSavedItems = async () => {
    let userId = await getData(storageKey?.USER_ID);
    try {
      let body = {
        post_type: type === "post" ? "211" : "212",
        user_id: JSON.parse(userId),
      };
      setLoading(true);
      let res = await dispatch(getSavedPost(body));
      setLoading(false);
      if (res?.status === 200) {
        setSavedItems(res?.results);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const postComment = async (e) => {
    e.stopPropagation();
    if (commentText) {
      let userId = await getData(storageKey?.USER_ID);
      try {
        let body = {
          portfolio_id: id,
          user_id: JSON.parse(userId),
          type: "comment",
          comment_id: "",
          comment: commentText,
        };
        setLoading(true);
        let res = await dispatch(addComment(body));
        setLoading(false);
        if (res?.status === 200) {
          setCommentText("");
          if (type === "post") {
            getSinglePostData();
          } else {
            getSinglePortfolioData();
          }
        }
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      postComment();
    }
  };

  const handleLikeUnlike = async (type1) => {
    let userId = await getData(storageKey?.USER_ID);
    try {
      let body = {
        type: type1,
        post_id: id,
        user_id: JSON.parse(userId),
      };
      setLoading(true);
      let res = await dispatch(likeDislike(body));
      setLoading(false);
      if (res?.status === 200) {
        if (type === "post") {
          getSinglePostData();
        } else {
          getSinglePortfolioData();
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSaveUnsave = async (type1) => {
    let userId = await getData(storageKey?.USER_ID);
    try {
      let body = {
        post_id: id,
        user_id: JSON.parse(userId),
        type: type1 === "save" ? "saved" : "unsaved",
        post_type: type == "post" ? "211" : "212",
      };
      setLoading(true);
      let res = {};
      if (type1 === "save") {
        res = await dispatch(savePostApi(body));
      } else {
        res = await dispatch(unsavePostApi(body));
      }
      setLoading(false);
      if (res?.status === 200) {
        getSavedItems()
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      if (type === "post") {
        getSinglePostData();
      } else {
        getSinglePortfolioData();
      }
      getSavedItems();
    }
  }, []);

  function convertToRelativeTime(dateString) {
    if (dateString) {
      const formattedDateString = dateString.replace(" ", "T");
      return moment(formattedDateString).fromNow();
    } else {
      return "";
    }
  }

  return (
    <section className="singlePostSlide">
      <Header />

      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <Slider {...settings}>
              {postDetail?.media?.length
                ? postDetail?.media?.map((item, index) => (
                    <div key={index}>
                      <img src={item?.url} />
                    </div>
                  ))
                : undefined}
            </Slider>
          </div>
          <div className="col-md-5">
            <div className="suthandSaved">

           

            <div className="autherdetail">
              <img src={`${postDetail?.user_data?.attachment?.url}`} />
              <div>
                <h4>
                  -<small>Posted by</small> {postDetail?.user_data?.user_name}
                </h4>
                <small>
                  {moment.utc(postDetail?.post_details?.post_date).fromNow() ||
                    ""}
                </small>
              </div>
            </div>
            <div
              onClick={() =>
                handleSaveUnsave(
                  savedItems?.some(
                    (item) =>
                      item?.post_details?.ID === postDetail?.post_details?.ID
                  )
                    ? "unsave"
                    : "save"
                )
              }
              class="wt-clicklike"
            >
              <i
                class={
                  savedItems?.some(
                    (item) =>
                      item?.post_details?.ID === postDetail?.post_details?.ID
                  )
                    ? "fa fa-bookmark"
                    : "fa fa-bookmark-o"
                }
              ></i>
            </div>
            </div>
            <div className="wt-title">
              <div className="wt-titletags">
                <a className="wt-articleby" target="_blank">
                  {postDetail?.post_details?.post_title || ""}
                </a>
              </div>
              <h3>
                <a>{postDetail?.post_details?.post_content || ""}</a>
              </h3>
            </div>
            <ul className="wt-moreoptions">
              <li
                onClick={() =>
                  handleLikeUnlike(
                    Number(postDetail?.extra?.like_status) === 1
                      ? "unlike"
                      : "like"
                  )
                }
              >
                <i
                  className={
                    Number(postDetail?.extra?.like_status) === 1
                      ? "fa fa-heart"
                      : "fa fa-heart-o"
                  }
                ></i>{" "}
                {postDetail?.extra?.likes_count}{" "}
                {postDetail?.extra?.likes_count <= 1 ? "Like" : "Likes"}
              </li>
              <li>
                <a>
                  <i className="fa fa-comment"> </i>{" "}
                  {postDetail?.extra?.comments_count}{" "}
                  {postDetail?.extra?.comments_count <= 1
                    ? "Comment"
                    : "Comments"}
                </a>
              </li>
            </ul>

            <div className="commentSection">
              {postDetail?.comment_result?.length ? (
                postDetail?.comment_result?.map((item, index) => (
                  <div key={index}>
                    <div className="autherdetail">
                      <img src={`${postDetail?.user_data?.attachment?.url}`} />
                      <div>
                        <small>{item?.comment?.comment_author || ""}</small>
                        <p>{item?.comment?.comment_content || ""}</p>
                      </div>
                    </div>
                    <ul className="replyarea">
                      <li>
                        {moment.utc(item?.comment?.comment_date).fromNow()}
                      </li>
                      {/* <li>Like</li> */}
                      <li
                        onClick={(e) =>
                          handleReply(e, index, item?.comment?.comment_ID)
                        }
                      >
                        Reply
                      </li>
                    </ul>
                    {item?.reply?.length
                      ? item?.reply?.map((ele, in2) => (
                          <div key={in2}>
                            <div
                              style={{ marginLeft: 50 }}
                              className="autherdetail reply_side"
                            >
                              <img
                                src={`${postDetail?.user_data?.attachment?.url}`}
                              />
                              <div>
                                <small>{ele?.comment_author || ""}</small>
                                <p>{ele?.comment_content || ""}</p>
                              </div>
                            </div>
                            <ul className="replyarea">
                              <li>{moment.utc(ele?.comment_date).fromNow()}</li>
                              {/* <li>Like</li> */}
                              {/* <li
                                onClick={(e) =>
                                  handleReply(e, index, ele?.comment_ID)
                                }
                              >
                                Reply
                              </li> */}
                            </ul>
                          </div>
                        ))
                      : undefined}
                    {replyIndex === index && (
                      <form className="postComment">
                        <input
                          type="text"
                          value={replies[index] || ""}
                          onChange={(e) =>
                            handleReplyChange(index, e.target.value)
                          }
                          placeholder="Write a reply..."
                        />
                        <button
                          type="button"
                          onClick={(e) =>
                            handleReply(e, index, item?.comment?.comment_ID)
                          }
                        >
                          Submit
                        </button>
                      </form>
                    )}
                  </div>
                ))
              ) : (
                <span>No Comments Found</span>
              )}

              <form className="postComment">
                <input
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                  type="text"
                  placeholder="Write Comment"
                  // onKeyDown={handleKeyPress}
                />
                <button onClick={postComment} type="button">
                  Post
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  );
};

export default SocialDetail;
