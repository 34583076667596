import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const WithdrawlAmount = (props) => {
  const { setVisible, visible, amount, setAmount, handleClick } = props;

  const [selectDrop, setselectDrop] = useState("");

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Withdraw earnings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Please select one of the payment gateway to withdraw your earnings
        </p>
        <div className="form-group">
          <select
            name="verification_number"
            className="form-control"
            onChange={(e) => setselectDrop(e.target.value)}
            value={selectDrop}
          >
            <option value="1">Select Payment Gateway</option>
            <option value={"2"}>Direct Bank Transfer(BACS)</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="number"
            name="amount"
            class="form-control"
            placeholder="Add Amount"
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                setAmount(val.target.value);
              }
            }}
            value={amount}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            // setVisible(false);
            handleClick();
          }}
        >
          Withdraw Money
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
