import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap"; 

import { Header, Footer, Loader } from "../../Components";
import SidebarLayout from "../../layout/SidebarLayout";
import {
  getLatestProposals,
  getModelProjects,
  getPostedListing,
  getProposalListing,
} from "../../Redux/Services/OtherServices";
import { useDispatch } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import { routeName } from "../../Utility";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Constants";

const ModelJobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jobsList, setJobsList] = useState([]);
  const [proposalList, setProposalList] = useState([]);
  const [tab, setTab] = useState("proposals");

  useEffect(() => {
    getAllJobsList("proposals");
    getLatestProposalsList();
    setTab("proposals");
  }, []);

  const handleTabs = async (type) => {
    if (type == "proposals") {
      getLatestProposalsList(type);
    } else {
      getAllJobsList(type);
    }
  };
  const getAllJobsList = async (type) => {
    let profileId = await getData(storageKey?.PROFILE_ID);
    let userID = await getData(storageKey?.USER_ID);
    var body = {
      user_id: userID,
      profile_id: profileId,
      job_status: type,
    };
    let res = await dispatch(getModelProjects(body));
    console.log("resdggres", res);
    if (res?.status == 200) {
      setTab(type);
      setJobsList(res?.results);
    } else {
      setJobsList([]);
    }
  };
  const options = [
    {
      name: "Applied Jobs",
      type: "proposals",
    },
    {
      name: "Ongoing Jobs",
      type: "ongoing",
    },
    {
      name: "Completed Jobs",
      type: "completed",
    },
    {
      name: "Direct Jobs",
      type: "direct",
    },
  ];
  
  const getLatestProposalsList = async () => {
    let userID = await getData(storageKey?.USER_ID);
    let body = {
      user_id: userID,
    };
    let res = await dispatch(getLatestProposals(body));
    console.log("bjkbjh", res);
    if (res?.status == 200) {
      setTab("proposals");
      setProposalList(res?.results);
    }
  };
  return (
    <>
      <SidebarLayout>
        <div class="dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="posted"
              id="jobs"
              activeKey={tab}
              onSelect={handleTabs}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  <div class="wt-managejobcontent">
                    {item?.type == "proposals"
                      ? proposalList?.map((ele, ind) => {
                          return (
                            <>
                              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                                  <div class="wt-contenthead">
                                    <div class="wt-title">
                                      <h2>{ele?.post_title}</h2>
                                    </div>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="fa fa-clock"></i> Estimated
                                          Hours :{" "}
                                          {ele?.fw_options?.estimeted_time}
                                        </span>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> Amount
                                          per hour :{" "}
                                          {ele?.fw_options?.per_hour_amount}
                                        </span>
                                      </li>
                                    </ul>

                                    <div class="wt-btnarea-btn">
                                      <span
                                        class="wt-bt"
                                        onClick={() => {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: ele?.profile?.ID,
                                            });
                                          navigate(
                                            `${
                                              routeName.JOB_DETAILS
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        View Job
                                      </span>

                                      <span class="wt-bt">Edit Proposal</span>
                                    </div>
                                  </div>
                                  <div class="wt-rightarea">
                                    <div class="wt-hireduserstatus">
                                      <h4>{ele?.amount}</h4>
                                      <span>Quoted Price</span>
                                      <ul class="wt-hireduserimgs">
                                        <li>
                                          <h5
                                            style={{
                                              textTransform: "capitalize",
                                              color: "red",
                                            }}
                                          >
                                            {ele?.status}
                                          </h5>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : jobsList?.map((ele, ind) => {
                          return (
                            <>
                              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                                  <div class="wt-contenthead">
                                    <div class="wt-title">
                                      {ele?.post_meta_details
                                        ?.project_status && (
                                        <a
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <i
                                            style={{
                                              color:
                                                ele?.post_meta_details
                                                  ?.project_status ==
                                                "completed"
                                                  ?Colors?.green
                                                  : ele?.post_meta_details
                                                      ?.project_status ==
                                                    "cancelled"
                                                  ? Colors?.red
                                                  :Colors?.blue,
                                            }}
                                            class={
                                              ele?.post_meta_details
                                                ?.project_status == "completed"
                                                ? "fa fa-check-circle"
                                                : ele?.post_meta_details
                                                    ?.project_status ==
                                                  "cancelled"
                                                ? "fa fa-times-circle"
                                                : "fa fa-check-fa fa-spinner"
                                            }
                                          ></i>
                                          {"  "}
                                          {
                                            ele?.post_meta_details
                                              ?.project_status
                                          }
                                        </a>
                                      )}
                                      <h2>{ele?.profile?.post_title}</h2>
                                    </div>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span>
                                          <i class="far fa-flag"></i> Location :{" "}
                                          {ele?.post_meta_details?.country} |{" "}
                                          {ele?.post_meta_details?.city}
                                        </span>
                                      </li>
                                      <li>
                                        <a class="wt-clicksavefolder">
                                          <i class="far fa-folder"></i> Type:{" "}
                                          {
                                            ele?.post_meta_details
                                              ?._project_type
                                          }
                                        </a>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="fa fa-usd"></i> Hourly Rate
                                          :{" "}
                                          {ele?.post_meta_details?._hourly_rate}
                                        </span>
                                      </li>
                                    </ul>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> Start
                                          Date :{" "}
                                          {
                                            ele?.post_meta_details
                                              ?.starting_date
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> End
                                          Date :{" "}
                                          {ele?.post_meta_details?.end_date}
                                        </span>
                                      </li>
                                    </ul>
                                    <div class="wt-btnarea-btn">
                                      <span
                                        class="wt-bt"
                                        onClick={() => {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: ele?.profile?.ID,
                                            });
                                          navigate(
                                            `${
                                              routeName.JOB_DETAILS
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        View
                                      </span>
                                      {item?.type != "completed" ? (
                                        <span class="wt-bt">
                                          Request Payment
                                        </span>
                                      ) : null}

                                      <span
                                        class="wt-bt"
                                        onClick={() => {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: ele?.profile?.ID,
                                            });
                                          navigate(
                                            `${
                                              routeName.CHAT
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        Message
                                      </span>
                                    </div>
                                  </div>
                                  <div class="wt-rightarea">
                                    <div class="wt-hireduserstatus">
                                      <span>Published By</span>
                                      <h4>
                                        {ele?.project_author?.author_title}
                                      </h4>
                                      <ul class="wt-hireduserimgs">
                                        <li>
                                          <figure>
                                            <img
                                              src={
                                                ele?.project_author?.profile_url
                                                  ?.url
                                              }
                                              alt="img description"
                                              class="mCS_img_loaded"
                                            />
                                          </figure>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                  </div>
                </Tab>
              ))}
            </Tabs>

            {/* <Tabs defaultActiveKey="posted" id="jobs">
              {options?.map((item, index) => {
                return (
                  <>
                    <Tab eventKey={item?.type} title={item?.name}>
                    Applied Jobs
                    </Tab>
                  </>
                );
              })}
          <Tab eventKey="ongoing_jobs" title="Ongoing Jobs">
                <div class="wt-managejobcontent">
                  <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                    <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                      <div class="wt-contenthead">
                        <div class="wt-title">
                          <a href="usersingle.html">
                            <i class="fa fa-check-circle"></i> Louanne Mattioli
                          </a>
                          <h2>
                            I want some customization and installation on
                            wordpress
                          </h2>
                        </div>
                        <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                          <li>
                            <span class="wt-dashboraddoller">
                              <i class="fa fa-dollar-sign"></i> Professional
                            </span>
                          </li>
                          <li>
                            <span>
                              <img
                                src="http://amentotech.com/htmls/worktern/images/flag/img-04.png"
                                alt="img description"
                              />{" "}
                              England
                            </span>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              class="wt-clicksavefolder"
                            >
                              <i class="far fa-folder"></i> Type: Per Fixed
                            </a>
                          </li>
                          <li>
                            <span class="wt-dashboradclock">
                              <i class="far fa-clock"></i> Duration: 15 Days
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="wt-rightarea">
                        <div class="wt-btnarea">
                          <span> Project Complete</span>
                          <a href="javascript:void(0);" class="wt-btn">
                            VIEW DETAILS
                          </a>
                        </div>
                        <div class="wt-hireduserstatus">
                          <h4>Hired</h4>
                          <span>Terrence Tynan</span>
                          <ul class="wt-hireduserimgs">
                            <li>
                              <figure>
                                <img
                                  src="http://amentotech.com/htmls/worktern/images/user/userlisting/img-01.jpg"
                                  alt="img description"
                                />
                              </figure>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                    <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                      <div class="wt-contenthead">
                        <div class="wt-title">
                          <a href="usersingle.html">
                            <i class="fa fa-check-circle"></i> Louanne Mattioli
                          </a>
                          <h2>
                            I want some customization and installation on
                            wordpress
                          </h2>
                        </div>
                        <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                          <li>
                            <span class="wt-dashboraddoller">
                              <i class="fa fa-dollar-sign"></i> Professional
                            </span>
                          </li>
                          <li>
                            <span>
                              <img
                                src="http://amentotech.com/htmls/worktern/images/flag/img-04.png"
                                alt="img description"
                                class="mCS_img_loaded"
                              />{" "}
                              England
                            </span>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0);"
                              class="wt-clicksavefolder"
                            >
                              <i class="far fa-folder"></i> Type: Per Fixed
                            </a>
                          </li>
                          <li>
                            <span class="wt-dashboradclock">
                              <i class="far fa-clock"></i> Duration: 15 Days
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="wt-rightarea">
                        <div class="wt-btnarea">
                          <span> Project Complete</span>
                          <a href="javascript:void(0);" class="wt-btn">
                            VIEW DETAILS
                          </a>
                        </div>
                        <div class="wt-hireduserstatus">
                          <h4>Hired</h4>
                          <span>Herlinda Hundley</span>
                          <ul class="wt-hireduserimgs">
                            <li>
                              <figure>
                                <img
                                  src="http://amentotech.com/htmls/worktern/images/user/userlisting/img-05.jpg"
                                  alt="img description"
                                  class="mCS_img_loaded"
                                />
                              </figure>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Applied_jobs" title="Applied Jobs">
                Applied Jobs
              </Tab>
              <Tab eventKey="Completed_jobs" title="Completed Jobs">
                Completed Jobs
              </Tab>
              <Tab eventKey="direct_jobs" title="Direct Jobs">
                Direct Jobs
              </Tab> */}
            {/* </Tabs> */}
          </div>
        </div>
      </SidebarLayout>
    </>
  );
};

export default ModelJobs;
