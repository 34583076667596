import { Images } from "../Constants/Images";
import { Login } from "./Popups";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { routeName, showToast } from "../Utility";
import React, { useEffect, useState } from "react";
import { clearData, getData, storageKey, storeData } from "../Utility/Storage";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetail, userLogout } from "../Redux/Services/AuthServices";
import { GET_CHAT_BADGE, GET_NOTIFICATION_BADGE } from "../API Services/Url";
import { Button, Modal } from "react-bootstrap";
import { Loader } from "./Loader";
import {
  getNotificationBadge,
  getNotificationList,
  updateNotifyStatus,
} from "../Redux/Services/OtherServices";
import moment from "moment";
import { NotifPopup } from "./Popups/NotifPopUp";
import { loggedInUsedAction } from "../Redux/Actions/AuthActions";
import { AuthButton, NotificationList } from "../features/header";
import { doubleEncodedData } from "../Utility/helper";

export const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginModal, setLoginModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState("");

  const auth = useSelector((state) => state?.authReducer);
  const badgeCount = useSelector((state) => state.otherReducer.badgeCount);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  // console.log(loggedInUser,"loggedInUserloggedInUser");

  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState("");
  const [image, setImage] = useState({});
  const [listItems, setListItems] = useState("");
  const [show, setShow] = useState(false);
  const [accountApproval, setAccountApproval] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");

  const [count, setCount] = useState({
    notification: "0",
    chat: "0",
    badge: false,
  });

  useEffect(() => {
    getApprovalStatus();
    getUserData();
  }, []);

  const getApprovalStatus = async () => {
    let accountApproval = await getData(storageKey?.APPROVAL_STATUS);
    setAccountApproval(JSON?.parse(accountApproval));
  };

  // useEffect(() => {
  //   if (props?.load) {
  //     getUserData();
  //   }
  // }, [props?.load]);

  const getNotifCount = async () => {
    let userID = await getData(storageKey?.USER_ID);
    // if (userID) {
      let body = {
        user_id: loggedInUser?.user_data?.user_id || JSON?.parse(userID) || '',
      };
      let response = await dispatch(
        getNotificationBadge(body, GET_NOTIFICATION_BADGE)
      );
      // let resp = await dispatch(getNotificationBadge(body, GET_CHAT_BADGE));
      if (response?.status == 200) {
        setCount({
          ...count,
          // badge: response?.results.count_status,
          notification: response?.results?.count,
          // chat: resp?.results?.unread_count,
        });
      }
    // }
  };

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    setUserId(userID);
    console.log(userId,"lplpllpUSerrIDDDDDDD");
    let userRole = await getData(storageKey?.USER_ROLE);
    setUserRole(userRole);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));

      if (res?.status == 200) {
        storeData(storageKey.USER_DATA, JSON.stringify(res?.results?.user_data))
        dispatch(loggedInUsedAction(res?.results ? res?.results : null));
        getNotifCount();
      }
    } else {
      setUserId("");
    }
  };

  const handleViewAction = async (notifId, msg, status, item) => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      if (status === "true") {
        setModalData(msg);
        setIsModalOpen(true);
        setSelectedItem(item)
      } else {
        // setLoading(true);
        let body = {
          notification_id: notifId,
          user_id: userID ? JSON.parse(userID) : "",
          status: "update",
        };
        let res = await dispatch(updateNotifyStatus(body));
        // setLoading(false);
        if (res?.status === 200) {
          getNotificationHistory();
          setModalData(msg);
          setIsModalOpen(true);
          setSelectedItem(item)
          // setNotificationData(res?.results);
        }
      }
    }
  };

  const logout = async () => {
    clearData();
    dispatch(loggedInUsedAction(null));
    showToast("Logged out successfully !", "success");
    setShow(false);
    getUserData();
    let body = {
      user_id: loggedInUser?.user_data?.user_id ||  ''
    }
    console.log(body,"bodyyyy???????????");
    let encoded = doubleEncodedData(body);
    console.log(encoded,"encodeddddyy???????????");
    if (window) {
      window.open(`https://booksculp.com/redirect/?code=logout&redirect=${encoded}`,"_self")
    }
    // navigate(routeName?.HOME);
    // let userID = await getData(storageKey?.USER_ID);
    // let fcmToken = await getData(storageKey?.FCM_TOKEN);
    // var body = {
    //   user_id: userID,
    //   device_token: JSON?.parse(fcmToken),
    // };
    // let res = await dispatch(userLogout(body));
    // if (res?.status == 200) {
    //   setShow(false);
    // }
  };
  const getUserImage = async () => {
    let USER_IMAGE = await getData(storageKey?.PROFILE_IMG);
    setImage(JSON.parse(USER_IMAGE));
  };

  const getUserRole = async () => {
    let userRole = await getData(storageKey?.USER_ROLE);
    setUserRole(userRole);
    if (userRole == 11 || userRole == 12) {
      setListItems(dropItems);
    } else if (userRole == 13) {
      setListItems(dropItems);
    }
  };

  const dropItems = [
    // {
    //   key: 1,
    //   name: "Inbox",
    //   path: routeName?.INBOX,
    //   hide: accountApproval ? false : true,
    // },
    {
      key: 2,
      name: "Dashboard",
      path: routeName?.DASHBOARD,
      hide: false,
    },

    // {
    //   key: 3,
    //   name: "Edit Application",
    //   path: routeName?.EDIT_PROFILE,
    //   hide: false,
    // },
  ];

  const getNotificationHistory = async () => {
    let userID = await getData(storageKey?.USER_ID);
    // if (userID) {
      let body = {
        user_id: loggedInUser?.user_data?.user_id || userID ? JSON.parse(userID) : "",
      };
      let res = await dispatch(getNotificationList(body));
      if (res?.status === 200) {
        setNotificationData(res?.results);
      }
    // }
  };

  useEffect(() => {
    if(loggedInUser?.user_data?.user_id){

      getNotificationHistory();
    }
  }, [loggedInUser]);

  return (
    <>
      <Loader loading={auth?.isLoading} />
      <Navbar collapseOnSelect expand="lg" className="">
        <Container>
          <Navbar.Brand
            // onClick={() => navigate(routeName?.HOME)}
            onClick={() => {
              if (window) {
                window.open("https://booksculp.com","_self");
              }
            }}
          >
            <img src={Images?.logo} height={120} width={120} />
          </Navbar.Brand>

          <div class="mobNav">
            <AuthButton
              setShow={setShow}
              setLoginModal={setLoginModal}
              userId={userId}
              dropItems={dropItems}
              notificationData={notificationData}
              handleViewAction={handleViewAction}
              type="mobile"
              userRole={userRole}
            />

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </div>

          <Navbar.Collapse
            id="responsive-navbar-nav "
            className="flex-row-reverse desktopNav"
          >
            <AuthButton
              setShow={setShow}
              setLoginModal={setLoginModal}
              userId={userId}
              dropItems={dropItems}
              notificationData={notificationData}
              handleViewAction={handleViewAction}
              type="pc"
              userRole={userRole}
            />
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <div className="pageTitle">
        <div className="wt-title">
          <h1>Register</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <a>Home</a>
            </li>
            <li className="last-item">Register</li>
          </ol>
        </div>
      </div> */}
      {isModalOpen && (
        <NotifPopup
          show={isModalOpen}
          setShow={setIsModalOpen}
          text={modalData}
          selectedItem={selectedItem}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to Log out for now. You can login to this account again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Not Now
          </Button>
          <Button variant="primary" onClick={() => logout()}>
            Yes, Log Out
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
