// import React, { useEffect, useState } from "react";
// import { Button, Container, Form, Tooltip } from "react-bootstrap";
// import { InputField } from "./InputField";
// import { DropdownList } from "./DropdownList";
// import { useDispatch, useSelector } from "react-redux";
// import { MultiSelectDropdown } from "./MultiSelectDropdown";
// import {
//   addDirectJobPost,
//   getCountryList,
// } from "../Redux/Services/OtherServices";
// import { getData, storageKey } from "../Utility/Storage";
// import { showToast } from "../Utility";
// import moment from "moment";

// let talentData = [
//   {
//     label: "Model",
//     value: "Model",
//   },
//   {
//     label: "Model Kid",
//     value: "Model Kid",
//   },
//   // {
//   //   label: "Actor",
//   //   value: "Actor",
//   // },
//   // {
//   //   label: "Actor Kid",
//   //   value: "Actor Kid",
//   // },
// ];

// export const PostCastingCallJob = (props) => {
//   const {setLoading} = props
//   const dispatch = useDispatch();
//   const allOptionData = useSelector(
//     (state) => state?.authReducer.allOptionData
//   );

//   const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

//   const [talentType, setTalentType] = useState("");
//   const [projectLevel, setProjectLevel] = useState("");
//   const [jobDuration, setJobDuration] = useState("");
//   const [englishLevel, setEnglishLevel] = useState("");
//   const [projectLocationType, setProjectLocationType] = useState("");
//   const [timeZone, setTimeZone] = useState("");
//   const [country, setCountry] = useState("");
//   const [state, setState] = useState("");
//   const [city, setCity] = useState("");
//   const [clientprovider, setClientprovider] = useState("");
//   const [otherContact, setOtherContact] = useState("");
//   const [workType, setWorkType] = useState("");
//   const [arrivalReq, setArrivalReq] = useState("");
//   const [usageDuration, setUsageDuration] = useState("");
//   const [usageLocation, setUsageLocation] = useState("");
//   const [mediaType, setMediaType] = useState([]);
//   const [usageFee, setUsageFee] = useState("");
//   const [payType, setPayType] = useState("");
//   const [whenPayExpect, setWhenPayExpect] = useState("");
//   const [jobDesc, setJobDesc] = useState({
//     title: "",
//   });
//   const [jobDetail, setJobDetail] = useState({
//     startDate: "",
//     endDate: "",
//     startTime: "",
//     endTime: "",
//     location: "",
//     address: "",
//     adddressLine2: "",
//     zipCode: "",
//     arrivalTime: "",
//     primaryClientContact: "",
//     emergencyContact: "",
//     reqNotes: "",
//   });
//   const [prices, setPrices] = useState({
//     projectRate: "",
//     diemAmount: "",
//     estimateHrs: "",
//     hourlyRate: "",
//   });
//   const [message, setMessage] = useState("");
//   const [error, setError] = useState(false);
//   const [location, setLocation] = useState({
//     countryID: "",
//     countryList: "",
//     stateList: "",
//     cityList: "",
//   });

//   function convertTo12HourFormat(time24) {
//     const [hours, minutes] = time24?.split(":");
//     let period = "AM";
//     if (hours >= 12) {
//       period = "PM";
//     }
//     const hours12 = hours % 12 || 12;
//     const formattedTime = `${String(hours12)?.padStart(
//       2,
//       "0"
//     )}:${minutes} ${period}`;
//     return formattedTime;
//   }

//   const handleSelectCountry = (e, type) => {
//     const selectedValue = e.target.value;
//     const selectedOptionObject = location?.countryList?.find(
//       (option) =>
//         option.value === selectedValue || option.name === selectedValue
//     );
//     getAllStateName(selectedOptionObject?.id);
//     if (e.target.value == "") {
//       setCountry("");
//     } else {
//       setCountry(e.target.value);
//     }
//   };

//   const handleSelectState = (e, type) => {
//     const selectedValue = e.target.value;
//     const selectedOptionObject = location?.stateList?.find(
//       (option) =>
//         option.value === selectedValue || option.name === selectedValue
//     );
//     getAllCityName(selectedOptionObject?.id);
//     if (e.target.value == "") {
//       setState("");
//     } else {
//       setState(e.target.value);
//     }
//   };

//   const handleSelectCity = (e, type) => {
//     const selectedValue = e.target.value;
//     const selectedOptionObject = location?.cityList?.find(
//       (option) =>
//         option.value === selectedValue || option.name === selectedValue
//     );
//     if (e.target.value == "") {
//       setCity("");
//     } else {
//       setCity(e.target.value);
//     }
//   };

//   function generateDateArray(startDate, endDate, startTime, endTime) {
//     const result = [];
//     const currentDate = new Date(startDate);
//     const endDateTime = new Date(endDate + " " + endTime);

//     while (currentDate <= endDateTime) {
//       const formattedDate = formatDate(currentDate);
//       const timeObj = {
//         start_time: formatTime(currentDate, startTime),
//         end_time: formatTime(currentDate, endTime),
//       };

//       result.push({
//         label: formattedDate,
//         value: timeObj,
//       });

//       currentDate.setDate(currentDate.getDate() + 1);
//     }

//     return result;
//   }

//   function formatDate(date) {
//     const day = date.getDate();
//     const month = date.getMonth() + 1;
//     const year = date.getFullYear();
//     const formattedDay = day < 10 ? "0" + day : day;
//     const formattedMonth = month < 10 ? "0" + month : month;
//     return `${formattedDay}/${formattedMonth}/${year}`;
//   }

//   function formatTime(date, time) {
//     const options = { hour: "2-digit", minute: "2-digit", hour12: true };
//     const timeParts = time.split(":");
//     date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10));
//     return date.toLocaleTimeString("en-US", options);
//   }

//   const handleForm = async (event) => {
//     setError(true);
//     event.preventDefault();

//     if (!jobDesc?.title) {
//       showToast("Project title is required", "error");
//       return;
//     }
//     if (!talentType) {
//       showToast("Talent type is required", "error");
//       return;
//     }
//     if (!projectLevel) {
//       showToast("Project level is required", "error");
//       return;
//     }
//     if (!jobDuration) {
//       showToast("Job duration is required", "error");
//       return;
//     }
//     if (!englishLevel) {
//       showToast("English level is required", "error");
//       return;
//     }
//     if (!projectLocationType) {
//       showToast("Project location type is required", "error");
//       return;
//     }
//     if (!timeZone) {
//       showToast("Time zone is required", "error");
//       return;
//     }
//     if (!jobDetail?.startDate) {
//       showToast("Start Date is required", "error");
//       return;
//     }
//     if (!jobDetail?.endDate) {
//       showToast("End Date is required", "error");
//       return;
//     }
//     if (!jobDetail?.startTime) {
//       showToast("Start time is required", "error");
//       return;
//     }
//     if (!jobDetail?.endTime) {
//       showToast("End Time is required", "error");
//       return;
//     }
//     if (!country) {
//       showToast("Country is required", "error");
//       return;
//     }
//     if (!state) {
//       showToast("State is required", "error");
//       return;
//     }
//     if (!city && location?.cityList?.length) {
//       showToast("City is required", "error");
//       return;
//     }
//     if (!jobDetail?.address) {
//       showToast("Address is required", "error");
//       return;
//     }
//     if (!jobDetail?.zipCode) {
//       showToast("Zip Code is required", "error");
//       return;
//     }
//     if (!jobDetail?.arrivalTime) {
//       showToast("Arrival Time is required", "error");
//       return;
//     }
//     if (!clientprovider?.length) {
//       showToast("Client Provides are required", "error");
//       return;
//     }
//     if (!workType) {
//       showToast("Type of work is required", "error");
//       return;
//     }
//     if (!payType) {
//       showToast("Pay type is required", "error");
//       return;
//     }
//     if (!whenPayExpect) {
//       showToast("When to pay is required", "error");
//       return;
//     }
//     if (!prices?.projectRate && payType !== "Hourly Rate") {
//       showToast("Project Rate is required", "error");
//       return;
//     }
//     if (!prices?.estimateHrs && payType === "Hourly Rate") {
//       showToast("Estimated hours are required", "error");
//       return;
//     }
//     if (!prices?.hourlyRate && payType === "Hourly Rate") {
//       showToast("Hourly Rate is required", "error");
//       return;
//     }
//     if (!mediaType?.length) {
//       showToast("Media types are required", "error");
//       return;
//     }
//     if (!message) {
//       showToast("Message is required", "error");
//       return;
//     }

//     const form = event.currentTarget;
//     const dateArr = generateDateArray(
//       jobDetail?.startDate,
//       jobDetail?.endDate,
//       jobDetail?.startTime,
//       jobDetail?.endTime
//     );
//     if (form.checkValidity()) {
//       let body = {
//         user_id: loggedInUser?.user_data?.user_id || "",
//         receiver_id: props?.receiverId || "",
//         action: "direct_offer",
//         message: message || "",
//         project_title: jobDesc?.title || "",
//         freelancer_level:
//           talentType === "Model Kid" || talentType === "Actor Kid"
//             ? "child"
//             : talentType,
//         model_type_req:
//           talentType === "Model Kid" || talentType === "Actor Kid"
//             ? "child"
//             : talentType === "Model"
//             ? "model"
//             : talentType === "Actor"
//             ? "actor"
//             : "",
//         project_level: projectLevel || "",
//         project_duration: jobDuration || "",
//         english_level: englishLevel || "",
//         job_option: projectLocationType || "",
//         time_zone: timeZone || "",
//         starting_date: jobDetail?.startDate || "",
//         end_date: jobDetail?.endDate || "",
//         starting_time: jobDetail?.startTime
//           ? convertTo12HourFormat(jobDetail?.startTime)
//           : "",
//         end_time: jobDetail?.endTime
//           ? convertTo12HourFormat(jobDetail?.endTime)
//           : "",
//         set_custom_time: dateArr || [],
//         location_description: jobDetail?.location || "",
//         country: country || "",
//         state: state || "",
//         city: city || "",
//         address_line: jobDetail?.address,
//         address_line_2: jobDetail?.adddressLine2 || "",
//         zipcode: jobDetail?.zipCode || "",
//         // reached_time: formattedDate,
//         client_provide: clientprovider || [],
//         primary_client_contact: jobDetail?.primaryClientContact || "",
//         primary_contact_emergency: jobDetail?.emergencyContact || "",
//         other_contact: otherContact || "",
//         type_of_work: workType || [],
//         talent_requirements: arrivalReq || [],
//         additional_information: jobDetail?.reqNotes || "",
//         estimated_hours: prices?.estimateHrs || "",
//         project_cost: prices?.projectRate,
//         amount_of_per_diem_provided: prices?.diemAmount || "",
//         duration_usage: usageDuration || "",
//         location_usage: usageLocation || "",
//         media_type_usage: mediaType || [],
//         usage_fee: usageFee || "",
//         project_type: payType || "",
//         hourly_rate: prices?.hourlyRate || "",
//       };
//       setLoading(true);
//       try {
//         const res = await dispatch(addDirectJobPost(body));
//         setLoading(false);
//         if (res?.status === 200) {
//           showToast("Job send successfully", "success");
//           props.setShow(false);
//         }
//       } catch (error) {
//         setLoading(false);
//         console.log(error);
//       }
//     }
//   };

//   const getAllCityName = async (id) => {
//     const selectedOptionObject = location?.countryList?.find(
//       (option) => option.value === country || option.name === country
//     );
//     var body = {
//       country: selectedOptionObject?.id || "",
//       state: id || "",
//       city: "",
//     };
//     setLoading(true);
//     let res = await dispatch(getCountryList(body));
//     setLoading(false);
//     if (res?.status == 200) {
//       let data;
//       if (res?.results?.length != 0) {
//         data = JSON?.parse(res?.results);
//         setLocation({
//           ...location,
//           cityList: data,
//         });
//       } else {
//         setLocation({
//           ...location,
//           cityList: [],
//         });
//       }
//     }
//   };

//   const getAllStateName = async (id) => {
//     var body = {
//       country: id || "",
//       state: "",
//       city: "",
//     };
//     setLoading(true);
//     let res = await dispatch(getCountryList(body));
//     setLoading(false);
//     if (res?.status == 200) {
//       let data;
//       if (res?.results?.length != 0) {
//         data = JSON?.parse(res?.results);
//         setLocation({
//           ...location,
//           stateList: data,
//           cityList: [],
//         });
//       } else {
//         setLocation({
//           ...location,
//           stateList: [],
//           cityList: [],
//         });
//       }
//     }
//   };

//   const getAllCountryName = async (type, id) => {
//     var body = {
//       country: "",
//       state: "",
//       city: "",
//     };
//     setLoading(true);
//     let res = await dispatch(getCountryList(body));
//     setLoading(false);
//     if (res?.status == 200) {
//       let data;
//       data = res?.results;
//       setLocation({
//         ...location,
//         countryList: data,
//       });
//     }
//   };

//   useEffect(() => {
//     getAllCountryName();
//   }, []);

//   return (
//     <SidebarLayout>
//     <main>
//       <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
//     <Container>
//       {/* <div className="registerForm full-size"> */}
//       <div className="verification_contain_form wt-email-cont">
//         <div>
//           <Form onSubmit={handleForm}>
//             <div class="form-section">
//               <div className="form-section-title">Job Description</div>
//               <div class="form-group form-group-50">
//                 <InputField
//                   required
//                   title="Project Title"
//                   placeholder="Project Title"
//                   type="text"
//                   value={jobDesc?.title}
//                   onChangeText={(e) => {
//                     setJobDesc({
//                       ...jobDesc,
//                       title: e?.target?.value,
//                     });
//                   }}
//                   // isValid={!error && jobDesc?.title}
//                 />
//                 <DropdownList
//                   required
//                   title="Talent type"
//                   placeholder="Select Talent type"
//                   options={talentData}
//                   value={talentType}
//                   setValue={setTalentType}
//                 />
//                 <DropdownList
//                   required
//                   title="Project Level"
//                   placeholder="Select Project Level"
//                   options={allOptionData?.project_levels}
//                   value={projectLevel}
//                   setValue={setProjectLevel}
//                 />
//                 <DropdownList
//                   required
//                   title="Job Duration"
//                   placeholder="Select Job Duration"
//                   options={allOptionData?.job_durations}
//                   value={jobDuration}
//                   setValue={setJobDuration}
//                 />
//                 <DropdownList
//                   required
//                   title="English Level"
//                   placeholder="Select English Level"
//                   options={allOptionData?.englishLevel}
//                   value={englishLevel}
//                   setValue={setEnglishLevel}
//                 />
//                 <DropdownList
//                   required
//                   title="Project Location Type"
//                   placeholder="Select Project Location Type"
//                   options={allOptionData?.project_location_type}
//                   value={projectLocationType}
//                   setValue={setProjectLocationType}
//                 />
//                 {/* <DropdownList
//                   required
//                   title="Time Zone"
//                   placeholder="Select Time Zone"
//                   options={allOptionData?.time_zone}
//                   value={timeZone}
//                   setValue={setTimeZone}
//                 /> */}
//                 <Form.Group>
//                   <Form.Label>
//                     {allOptionData?.time_zone?.length ? (
//                       <span className="astrik_req">*</span>
//                     ) : undefined}
//                     TimeZone
//                   </Form.Label>
//                   <Form.Select
//                     className="form-control"
//                     onChange={(e) => {
//                       setTimeZone(e.target.value);
//                     }}
//                     value={timeZone}
//                     // isValid={!error && timeZone}
//                   >
//                     <option value="">TimeZone</option>
//                     {allOptionData?.time_zone?.length &&
//                       allOptionData?.time_zone?.map((item, index) => {
//                         return (
//                           <>
//                             <option value={item?.value} label={item?.value}>
//                               {item?.value}
//                             </option>
//                           </>
//                         );
//                       })}
//                   </Form.Select>
//                 </Form.Group>
//               </div>
//             </div>

//             <div class="form-section">
//               <div className="form-section-title">Job Details</div>
//               <div class="form-group form-group-50">
//                 <InputField
//                   required
//                   title="Start Date"
//                   type="date"
//                   value={jobDetail?.startDate}
//                   placeholder=""
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       startDate: e?.target?.value,
//                       endDate: "",
//                     });
//                   }}
//                   min={moment(new Date()).format("YYYY-MM-DD")}
//                 />
//                 <InputField
//                   required
//                   title="End Date"
//                   type="date"
//                   value={jobDetail?.endDate}
//                   placeholder=""
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       endDate: e?.target?.value,
//                     });
//                   }}
//                   min={moment(new Date(jobDetail?.startDate)).format(
//                     "YYYY-MM-DD"
//                   )}
//                 />
//                 <InputField
//                   required
//                   title="Start Time"
//                   type="time"
//                   value={jobDetail?.startTime}
//                   placeholder=""
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       startTime: e?.target?.value,
//                     });
//                   }}
//                 />
//                 <InputField
//                   required
//                   title="End Time"
//                   type="time"
//                   value={jobDetail?.endTime}
//                   placeholder=""
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       endTime: e?.target?.value,
//                     });
//                   }}
//                 />
//                 <Form.Group>
//                   <Form.Label>
//                     <span className="astrik_req">*</span>Select Country
//                   </Form.Label>
//                   <Form.Select
//                     className="form-control"
//                     onChange={(e) => {
//                       handleSelectCountry(e);
//                     }}
//                     value={country}
//                     // isValid={!error && country}
//                   >
//                     <option value="">Select Country</option>
//                     {location?.countryList?.length &&
//                       location?.countryList?.map((item, index) => {
//                         return (
//                           <>
//                             <option value={item?.name} label={item?.name}>
//                               {item?.name}
//                             </option>
//                           </>
//                         );
//                       })}
//                   </Form.Select>
//                 </Form.Group>
//                 <Form.Group>
//                   <Form.Label>
//                     <span className="astrik_req">*</span>Select State
//                   </Form.Label>
//                   <Form.Select
//                     className="form-control"
//                     onChange={(e) => {
//                       handleSelectState(e);
//                     }}
//                     value={state}
//                     // isValid={!error && state}
//                   >
//                     <option value="">Select State</option>
//                     {location?.stateList?.length &&
//                       location?.stateList?.map((item, index) => {
//                         return (
//                           <>
//                             <option value={item?.name} label={item?.name}>
//                               {item?.name}
//                             </option>
//                           </>
//                         );
//                       })}
//                   </Form.Select>
//                 </Form.Group>
//                 <Form.Group>
//                   <Form.Label>
//                     {location?.cityList?.length ? (
//                       <span className="astrik_req">*</span>
//                     ) : undefined}
//                     Select City
//                   </Form.Label>
//                   <Form.Select
//                     className="form-control"
//                     onChange={(e) => {
//                       handleSelectCity(e);
//                     }}
//                     value={city}
//                     // isValid={!error && city}
//                   >
//                     <option value="">Select City</option>
//                     {location?.cityList?.length &&
//                       location?.cityList?.map((item, index) => {
//                         return (
//                           <>
//                             <option value={item?.name} label={item?.name}>
//                               {item?.name}
//                             </option>
//                           </>
//                         );
//                       })}
//                   </Form.Select>
//                 </Form.Group>

//                 <InputField
//                   title="Job Location"
//                   placeholder="Job Location"
//                   type="text"
//                   value={jobDetail?.location}
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       location: e.target.value,
//                     });
//                   }}
//                 />
//                 <InputField
//                   required
//                   title="Address Line"
//                   placeholder="Address Line"
//                   type="text"
//                   value={jobDetail?.address}
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       address: e.target.value,
//                     });
//                   }}
//                   // isValid={!error && jobDetail?.address}
//                 />
//                 <InputField
//                   title="Address line 2 ( optional )"
//                   placeholder="Address line 2 ( optional )"
//                   type="text"
//                   value={jobDetail?.adddressLine2}
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       adddressLine2: e.target.value,
//                     });
//                   }}
//                 />
//                 <InputField
//                   required
//                   title="Zip Code"
//                   placeholder="Zip Code"
//                   type="text"
//                   value={jobDetail?.zipCode}
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       zipCode: e.target.value,
//                     });
//                   }}
//                   // isValid={!error && jobDetail?.zipCode}
//                 />
//                 <InputField
//                   required
//                   title="Arrival Time"
//                   type="time"
//                   value={jobDetail?.arrivalTime}
//                   placeholder=""
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       arrivalTime: e?.target?.value,
//                     });
//                   }}
//                 />

//                 <MultiSelectDropdown
//                   required
//                   title="Client Provides"
//                   placeholder="Client Provides"
//                   options={
//                     allOptionData?.postJobOptions?.client_provide?.field_meta
//                       ?.choices
//                   }
//                   value={clientprovider}
//                   setValue={setClientprovider}
//                 />
//                 <InputField
//                   title="Primary client contact"
//                   placeholder="Primary client contact"
//                   type="text"
//                   value={jobDetail?.primaryClientContact}
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       primaryClientContact: e.target.value,
//                     });
//                   }}
//                 />
//                 <InputField
//                   title="Primary emergency contact"
//                   placeholder="Primary emergency contact"
//                   type="text"
//                   value={jobDetail?.emergencyContact}
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       emergencyContact: e.target.value,
//                     });
//                   }}
//                 />
//                 <DropdownList
//                   title="Other Contact"
//                   placeholder="Other Contact"
//                   options={
//                     allOptionData?.postJobOptions?.other_contact?.field_meta
//                       ?.choices
//                   }
//                   value={otherContact}
//                   setValue={setOtherContact}
//                 />
//                 <div className="fullCol">
//                   <span>
//                     This contact information will not go public. It will be
//                     viewed by the talent that is awarded
//                   </span>
//                 </div>
//                 <DropdownList
//                   required
//                   title="Type of works"
//                   placeholder="Type of works"
//                   options={
//                     allOptionData?.postJobOptions?.type_of_work?.field_meta
//                       ?.choices
//                   }
//                   value={workType}
//                   setValue={setWorkType}
//                 />
//                 <MultiSelectDropdown
//                   // required
//                   title="Talent Arrival Requirement"
//                   placeholder="Talent Arrival Requirement"
//                   options={
//                     allOptionData?.postJobOptions?.talent_requirements
//                       ?.field_meta?.choices
//                   }
//                   value={arrivalReq}
//                   setValue={setArrivalReq}
//                 />
//                 <InputField
//                   title="Talent requirement notes"
//                   placeholder="Talent requirement notes"
//                   type="text"
//                   value={jobDetail?.reqNotes}
//                   onChangeText={(e) => {
//                     setJobDetail({
//                       ...jobDetail,
//                       reqNotes: e.target.value,
//                     });
//                   }}
//                 />
//               </div>
//             </div>

//             <div class="form-section">
//               <div className="form-section-title">Price</div>
//               <div class="form-group form-group-50">
//                 <DropdownList
//                   required
//                   title="Pay Type"
//                   placeholder="Select Pay Type"
//                   options={allOptionData?.pay_type}
//                   value={payType}
//                   setValue={setPayType}
//                 />
//                 <DropdownList
//                   required
//                   title="when you expect payment"
//                   placeholder="Select when pay"
//                   options={allOptionData?.when_you_expect_payment}
//                   value={whenPayExpect}
//                   setValue={setWhenPayExpect}
//                 />
//                 {payType === "Hourly Rate" ? undefined : (
//                   <InputField
//                     required
//                     title="Project Rate"
//                     placeholder="Project Rate"
//                     type="number"
//                     value={prices?.projectRate}
//                     onChangeText={(e) => {
//                       setPrices({
//                         ...prices,
//                         projectRate: e.target.value,
//                       });
//                     }}
//                     // isValid={!error && prices?.projectRate}
//                   />
//                 )}
//                 {payType === "Hourly Rate" ? (
//                   <>
//                     <InputField
//                       required
//                       title="Estimated Hours"
//                       placeholder="Estimated Hours"
//                       type="number"
//                       value={prices?.estimateHrs}
//                       onChangeText={(e) => {
//                         setPrices({
//                           ...prices,
//                           estimateHrs: e.target.value,
//                         });
//                       }}
//                       // isValid={!error && prices?.estimateHrs}
//                     />
//                     <InputField
//                       required
//                       title="Hourly Rate"
//                       placeholder="Hourly Rate"
//                       type="number"
//                       value={prices?.hourlyRate}
//                       onChangeText={(e) => {
//                         setPrices({
//                           ...prices,
//                           hourlyRate: e.target.value,
//                         });
//                       }}
//                       // isValid={!error && prices?.hourlyRate}
//                     />
//                   </>
//                 ) : undefined}
//                 <InputField
//                   title="Amount of per diem provided"
//                   placeholder="Amount Provided ($)"
//                   type="number"
//                   value={prices?.diemAmount}
//                   onChangeText={(e) => {
//                     setPrices({
//                       ...prices,
//                       diemAmount: e.target.value,
//                     });
//                   }}
//                   toolTipText={
//                     "This is the amount provided by the client per day for personal expenses during the project. This might include meals and milage."
//                   }
//                 />
//               </div>
//             </div>

//             <div class="form-section">
//               <div className="form-section-title">Usage</div>
//               <div class="form-group form-group-50">
//                 <DropdownList
//                   title="Usage Duration"
//                   placeholder="Select Usage Duration"
//                   options={
//                     allOptionData?.postJobOptions?.duration_usage?.field_meta
//                       ?.choices
//                   }
//                   value={usageDuration}
//                   setValue={setUsageDuration}
//                 />
//                 <DropdownList
//                   title="Usage Location"
//                   placeholder="Select Usage Location"
//                   options={
//                     allOptionData?.postJobOptions?.location_usage?.field_meta
//                       ?.choices
//                   }
//                   value={usageLocation}
//                   setValue={setUsageLocation}
//                 />
//                 <MultiSelectDropdown
//                   required
//                   title="Media Type"
//                   placeholder="Media Type"
//                   options={
//                     allOptionData?.postJobOptions?.media_type_usage?.field_meta
//                       ?.choices
//                   }
//                   value={mediaType}
//                   setValue={setMediaType}
//                 />
//                 <InputField
//                   title="Usage Fee"
//                   placeholder="Usage Fee"
//                   type="number"
//                   value={usageFee}
//                   onChangeText={(e) => setUsageFee(e?.target?.value)}
//                   toolTipText={
//                     "This is the fee associated with the cost of “duration, location, and media type"
//                   }
//                 />
//               </div>
//             </div>

//             <div class="form-section">
//               <div className="form-section-title">Message</div>
//               <div class="form-group">
//                 <InputField
//                   required
//                   title="Message"
//                   placeholder="Type your Message"
//                   type="textarea"
//                   value={message}
//                   onChangeText={(e) => setMessage(e?.target?.value)}
//                   // isValid={!error && message}
//                 />
//               </div>
//             </div>
//             <Button
//               onClick={handleForm}
//               variant=" wt-btn"
//               class="wt-btn hide_org"
//               type="button"
//             >
//               Save
//             </Button>
//           </Form>
//         </div>
//       </div>

//       {/* </div> */}
//     </Container>
//     </div>
//     </main>
//     </SidebarLayout>
//   );
// };

import React, { useState } from "react";
import { JobPostForm } from "../../Components";
import SidebarLayout from "../../layout/SidebarLayout";

const PostCastingCallJob = () => {
  const [loading, setLoading] = useState(false);

  return (
    <SidebarLayout>
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <JobPostForm type="castingCall" setLoading={setLoading} />
        </div>
      </main>
    </SidebarLayout>
  );
};

export default PostCastingCallJob;
