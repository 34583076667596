export const routeName = {
  HOME: "/",
  ABOUT: "/about",
  SEARCH_JOBS: "/searchJobs",
  LOGIN: "/login",
  REGISTER: "/register",
  PROFILE:'/profile',
  SIGN_UP: "/signup",
  CONTACT_US: "/contactUs",
  SEARCH_TALENT: "/searchtalent",
  INSIGHTS: "/insights",
  DASHBOARD: "/dashboard",
  PRIVACY_POLICY: "/privacypolicy",
  TERMS_CONTIONS: "/termsconditions",
  TALENT_PROFILE: "/talentprofile",
  EDIT_PROFILE: "/editProfile",
  CLIENT_PROFILE: "/clientProfile",
  FAQ: "/faq",
  JOB_DETAILS: "/jobdetails",
  NOTIFICATION:"/notifications",
  JOB_PROPOSAL:"/jobproposal",
  IDENTITY_VERIFY:'/identityVerify',
  RE_DIRECT:'/redirect',
  PAYOUT:'/payout',
  STRIPE:"/stripe-responce",
  JOB_POST:'/jobPost',
  CASTING_JOB:'/postCastingJob',
  MODEL_JOBS:'/manage-jobs',
  CHAT:'/chat',
  CLIENT_PROJECTS:'/manage-projects',
  PROPOSALS: '/job-proposals',
  PACKAGES: '/packages',
  ADD_PORTFOLIO:'/add-portfolio',
  ADD_POST:'/add-post',
  MANAGE_PORTFOLIO:'/manage-portfolio',
  MANAGE_POST:'/manage-post',
  SOCIAL_DETAIL:'/social-detail',
  INSIGHT_DETAIL:'/insight-detail',
  FOLLOW_DETAILS:'/follow-details',
  SAVED_COLLECTION: '/saved-collection'
};
