import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeName } from "../Utility";

export const BlogCard = (props) => {
  const { cardData } = props;
  const navigate = useNavigate();

  return (
    <>
      <div
        className="col-md-4"
        // onClick={()=> window?.open(`${cardData?.post_url}`)}
        onClick={() => {
          const queryParams = new URLSearchParams({
            id: cardData?.post_id,
          });
          navigate(
            `${
              routeName?.INSIGHT_DETAIL
            }?${queryParams.toString()}`
          );
        }}
      >
        <figure className="wt-articleimg">
          <a>
            <img
              decoding="async"
              src={cardData?.thumbnail?.url}
              alt="Model: Alex C-Book Sculp"
            />
          </a>
        </figure>
        <div className="wt-articlecontents">
          <div className="wt-title">
            <div className="wt-titletags">
              <a
                className="wt-articleby"
                href=""
                target="_blank"
                onClick={() =>
                  window?.open("https://booksculp.com/category/model/")
                }
              >
                Model
              </a>
            </div>
            <h3>
              <a>{cardData?.post_title}</a>
            </h3>
            <span className="wt-datetime">
              <i className="fa fa-calendar"></i>
              {moment(cardData?.post_post_date).format("MMMM DD, YYYY")}
            </span>
          </div>
          <ul className="wt-moreoptions">
            <li>
              <a>
                <i className="fa fa-comment"> </i> {cardData?.post_comments}{" "}
                Comments
              </a>
            </li>
            <li>
              <i className="fa fa-eye"></i> {cardData?.post_views}&nbsp;Views
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
