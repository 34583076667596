// Filename - Home.jsx
import React, { useEffect, useState } from "react";
import { Header, Footer, Loader } from "../../Components";
import Sidebar from "./Sidebar";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { Colors, Images } from "../../Constants";

import { NavLink } from "react-router-dom";
import { getData, storageKey } from "../../Utility/Storage";
import { routeName } from "../../Utility";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../layout/SidebarLayout";
import { DashboardPopUp } from "../../Components/Popups";

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [accountApproval, setAccountApproval] = useState(true);
  const [userData, setUserData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userLoggedInData, setUserLoggedInData] = useState("");

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status === 200) {
        setUserData(res?.results);
      }
    } else {
    }
  };

  const getApprovalStatus = async () => {
    let accountApproval = await getData(storageKey?.APPROVAL_STATUS);
    setAccountApproval(JSON?.parse(accountApproval));
    let userData = await getData(storageKey.USER_DATA);
    if (userData) {
      console.log(JSON.parse(userData), "LLLLLLLLuserrrrr");
      setUserLoggedInData(JSON.parse(userData));
    }
  };

  const handleNavigate = async () => {
    if (
      userData?.user_data?.user_role === 11 ||
      userData?.user_data?.user_role === 15
    ) {
      const queryParams = new URLSearchParams({
        id: userData?.user_data?.user_id,
      });
      navigate(`${routeName.TALENT_PROFILE}?${queryParams.toString()}`);
    } else if (userData?.user_data?.user_role === 12) {
      const queryParams = new URLSearchParams({
        id: userData?.user_data?.user_id,
      });
      navigate(`${routeName.CLIENT_PROFILE}?${queryParams.toString()}`);
    }
  };

  useEffect(() => {
    // if(!accountApproval){
    //   // if(!loggedInUser?.user_data?.profile_approval){
    //     setIsModalVisible(true)
    //   // }
    // }
    getApprovalStatus();
  }, [loggedInUser]);

  useEffect(() => {
    if (userLoggedInData?.user_role) {
      if (
        !userLoggedInData?.profile_approval ||
        userLoggedInData?.identity_status === 0
      ) {
        setIsModalVisible(true);
      }
    }
  }, [userLoggedInData]);

  return (
    <SidebarLayout>
      <main>
        <div className="container">
          {!userLoggedInData?.profile_approval && (
            <div className="row">
              <div className="col-md-8">
                <div className="Verificationinprogress wt-dashboardbox wt-insightnoticon">
                  <h2>Notification</h2>
                  <h4>Application Verification in-progress</h4>
                  <p>
                    Thank you for submitting an application to Book Sculp. Our
                    team will review your application within two weeks. If we
                    think you are a good fit then we’ll be in touch.
                  </p>
                  <button
                    className="edit_app"
                    onClick={() => navigate(routeName?.EDIT_PROFILE)}
                  >
                    Edit Application
                  </button>
                </div>
              </div>
            </div>
          )}
          {userLoggedInData?.profile_approval && (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="wt-insightsitem wt-dashboardbox">
                  <figure className="wt-userlistingimg">
                    <img
                      src={Images.latestProposals}
                      alt="image description"
                      className="mCS_img_loaded"
                    />
                  </figure>
                  <div className="wt-insightdetails">
                    <div className="wt-title">
                      <h3>View Profile</h3>
                      <a onClick={handleNavigate} href="javascript:void(0)">
                        Click To View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="wt-insightsitem wt-dashboardbox wt-insightnoticon">
                  <figure className="wt-userlistingimg">
                    <img
                      src={Images.inboxImage}
                      alt="image description"
                      className="mCS_img_loaded"
                    />
                  </figure>
                  <div className="wt-insightdetails">
                    <div className="wt-title">
                      <h3>New Messages</h3>
                      <a href="javascript:void(0)">Click To View</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="wt-insightsitem wt-dashboardbox">
                  <figure className="wt-userlistingimg">
                    <img
                      src={Images.latestProposals}
                      alt="image description"
                      className="mCS_img_loaded"
                    />
                  </figure>
                  <div className="wt-insightdetails">
                    <div className="wt-title">
                      <h3>Latest Proposals</h3>
                      <a href="javascript:void(0)">Click To View</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="wt-insightsitem wt-dashboardbox">
                  <figure className="wt-userlistingimg">
                    <img
                      src={Images.packageExpiry}
                      alt="image description"
                      className="mCS_img_loaded"
                    />
                  </figure>
                  <div className="wt-insightdetails">
                    <div className="wt-title">
                      <h3>Check Package Expiry</h3>
                      <a href="javascript:void(0)">Click To View</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="wt-insightsitem wt-dashboardbox">
                  <figure className="wt-userlistingimg">
                    <img
                      src={Images.followers}
                      alt="image description"
                      className="mCS_img_loaded"
                    />
                  </figure>
                  <div className="wt-insightdetails">
                    <div className="wt-title">
                      <h3>View Saved Items</h3>
                      <a href="javascript:void(0)">Click To View</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
      {isModalVisible ? (
        <DashboardPopUp
          setVisible={setIsModalVisible}
          visible={isModalVisible}
          text={
            !userLoggedInData?.profile_approval
              ? "Thank you for submitting an application to Book Sculp. Our team will review your application within two weeks. If we think you are a good fit then we’ll be in touch."
              : userLoggedInData?.identity_status === 0
              ? "You must verify your identity to get booked by a client"
              : ""
          }
          title={
            !userLoggedInData?.profile_approval
              ? "Application Verification in-progress"
              : userLoggedInData?.identity_status === 0
              ? "ID Verification Missing"
              : ""
          }
        />
      ) : undefined}
    </SidebarLayout>
  );
};
