import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch } from "react-redux";
import { getProposalListing } from "../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../Utility/Storage";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../Constants";

const Proposals = () => {
  const dispatch = useDispatch();
  const [proposalList, setProposalList] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  console.log('queryParams-----',queryParams)
  const project_id = queryParams?.get("id");

  useEffect(() => {
    getProposalsList();
  }, []);

  const getProposalsList = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let body = {
      user_id: userId,
      project_id: project_id,
      get_result: "proposal",
    };
    let res = await dispatch(getProposalListing(body));
    console.log('res?.resultsres?.results-------',res)
    if (res?.status == 200) {
      if (res?.results && res?.results?.length != 0) {
        setProposalList(res?.results.reverse());
        // res?.results?.map((item) => {
        //   if (item?.proposal_details?.proposal_status == "hired") {
        //     setProposalStatus("hired");
        //   }
        // });
      } else {
        setProposalList([]);
      }
    }
  };

  return (
    <>
      <SidebarLayout>
        <div class="dashboard_main">
          <div className="myJobs">
            <div class="wt-managejobcontent">
              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                  <div class="wt-contenthead">
                    <div class="wt-title">
                      <span className="clName">
                        <i class="fa fa-check-circle"></i> Terrence Tynan
                      </span>
                      <h2 >Test casting calls</h2>
                    </div>
                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                      <li>
                        <span>
                          <i class="far fa-flag"></i> Location : Albania |
                          Bashkia Poliçan
                        </span>
                      </li>
                      <li>
                        <a class="wt-clicksavefolder">
                          <i class="far fa-folder"></i> Type: full_day_rate
                        </a>
                      </li>
                      <li>
                        <span class="wt-dashboradclock">
                          <i class="fa fa-usd"></i> Hourly Rate :{" 20"}
                        </span>
                      </li>
                    </ul>
                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                      <li>
                        <span class="wt-dashboradclock">
                          <i class="far fa-calendar"></i> Start Date :{" 24-04-2024"}
                        </span>
                      </li>
                      <li>
                        <span class="wt-dashboradclock">
                          <i class="far fa-calendar"></i> End Date :{" 26-04-2024"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="wt-rightarea">
                    <div class="wt-hireduserstatus">
                      <h4>18</h4>
                      <span>Proposal count</span>
                      <h4>Ongoing</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="listproposal">
                <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                  {proposalList?.map((item, index) => {
                    return (
                      <>
                      <div class="wt-userlistingcontent wt-userlistingcontentvtwo proposalDetails">
                        <div class="wt-contenthead">
                          <figure class="wt-userlistingimg">
                            <img
                              src={item?.proposal_details?.profile_image}
                              alt="image description"
                              class="mCS_img_loaded"
                            />
                          </figure>
                          <div className="tArea">
                            <div class="wt-title">
                              <h2>{item?.proposal_details?.display_name}</h2>
                              <h6 style={{ color: Colors?.blue }}>{item?.proposal_details?.proposal_status}</h6>
                            </div>

                            <div class="wt-proposalfeedback">
                              <span class="wt-starsvtwo">
                                <i class="fa fa-star fill"></i>
                              </span>
                              <span class="wt-starcontent">
                                {" "}
                                {item?.proposal_details?.user_rating}/<i>5</i> 
                                {/* <em> (860 Feedback)</em> */}
                              </span>
                            </div>

                            <div class="wt-btnarea-btn">
                              <span class="wt-bt">View Details</span>
                              <span class="wt-bt">Message</span>
                            </div>
                             
                          </div>
                        </div>
                        <div class="wt-rightarea">
                          <div class="wt-hireduserstatus">
                            <h4>$ {item?.proposal_details?.proposal_amount}</h4>
                            <span> Quoted Price</span>
                            <span className="coverletter"> View Message</span>
                          </div>
                        </div>
                      </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarLayout>
    </>
  );
};

export default Proposals;
