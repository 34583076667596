import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { routeName } from "../../Utility";
import { useNavigate } from "react-router-dom";

export const NotifPopup = (props) => {
  const { text, show, setShow, selectedItem } = props;
  const navigate = useNavigate()
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer className="footer-center">
        
       {/* {selectedItem?.notification_type === 'send_offer' ? <Button
         onClick={() => {
          const queryParams = new URLSearchParams({
            id: selectedItem?.post_id,
            type:'notification'
          });
          setShow(false)
          navigate(
            `${routeName.JOB_DETAILS}?${queryParams.toString()}`
          );
        }}
        >
          Continue
        </Button> : undefined} */}
      </Modal.Footer>
    </Modal>
  );
};
