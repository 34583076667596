import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { routeName } from "../../Utility";
import { useSelector } from "react-redux";
import { doubleEncodedData } from "../../Utility/helper";

const NotificationList = (props) => {
  const { notificationData, handleViewAction } = props;
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  return (
    <nav class="wt-usernav">
      <div class="notify-wrap-start dropdown-menu-right">
        <span>Notifications</span>
        <span 
        // onClick={() => navigate(routeName.NOTIFICATION)}
        onClick={() => {
          let body = {
            user_id: loggedInUser?.user_data?.user_id || ''
          }
          let encoded = doubleEncodedData(body)
          if (window) {
            window.open(`https://booksculp.com/redirect/?code=notification&redirect=${encoded}`,"_self")
          }
        }}
        >View all</span>
      </div>
      <ul>
        {notificationData?.length
          ? notificationData?.slice(0, 10)?.map((item, index) => (
              <li
                className={
                  item?.notify_status !== "true"
                    ? "list_noti_active"
                    : "list_noti"
                }
                onClick={() =>
                  handleViewAction(
                    item?.notify_id,
                    item?.notify_msg,
                    item?.notify_status,
                    item
                  )
                }
                key={index}
              >
                <a>
                  <span>
                    {item?.notify_msg?.length > 80
                      ? item?.notify_msg?.slice(0, 80) + "..."
                      : item?.notify_msg}
                  </span>
                  <small>{moment(item?.date).format("DD MMM YYYY")}</small>
                </a>
              </li>
            ))
          : undefined}
      </ul>
    </nav>
  );
};

export default NotificationList;
