import React, { useEffect, useState } from "react";
import { Container, OverlayTrigger } from "react-bootstrap";
import { Form, Button, ProgressBar } from "react-bootstrap";
import {
  Header,
  Footer,
  InputField,
  DropdownList,
  MultiSelectDropdown,
  Loader,
} from "../../Components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bytesToKB, isValidEmail, routeName, showToast } from "../../Utility";
import {
  expertises,
  followers,
  genderTypes,
  kidGenderTypes,
  languages,
} from "../../Global";
import { getCountryList } from "../../Redux/Services/OtherServices";
import Tooltip from "@mui/material/Tooltip";
import {
  getOptionsData,
  getUserDetail,
  photographerRegister,
  profileSetup,
  removeImage,
  updatePhotographerProfile,
  uploadGalleryProfile,
  userRegister,
} from "../../Redux/Services/AuthServices";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import moment from "moment";
import { ImageCropper } from "../../Components/ImageCropper";
import { PARTNER_REGISTER, PARTNER_UPDATE } from "../../API Services/Url";
import { doubleEncodedData } from "../../Utility/helper";

export const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useLocation();
  let currentDate = new Date();
  const auth = useSelector((state) => state?.authReducer);
  const verificationData = auth?.verificationData?.verificationData;
  const optionsType = useSelector((state) => state.authReducer.allOptionData);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const laoding = useSelector((state) => state?.otherReducer);

  const data = params?.state?.data;
  const queryParams = new URLSearchParams(params.search);
  const user_email = queryParams.get("email");
  const user_type = queryParams.get("type");

  const [userRole, setUserRole] = useState("");
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(user_email);
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [legal, setLegal] = useState(false);
  const [agree, setAgree] = useState(false);
  const [talent, setTalent] = useState(user_type);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [expertise, setExpertise] = useState("");
  const [experience, setExperience] = useState("");
  const [twitterFollowers, setTwitterFollowers] = useState("");
  const [facebookFollowers, setFacebookFollowers] = useState("");
  const [youtubeFollowers, setYoutubeFollowers] = useState("");
  const [tiktokFollowers, setTiktokFollowers] = useState("");
  const [vimeoFollowers, setVimeoFollowers] = useState("");
  const [instagramFollowers, setInstagramFollowers] = useState("");
  const [mySkill, setMySkill] = useState("");
  const [customSkills, setCustomSkills] = useState("");
  const [awards, setAwards] = useState("");
  const [tattooLoc, setTattoLoc] = useState("");
  const [memberOf, setMemberOf] = useState("");
  const [educationTraining, setEducationTraining] = useState("");
  const [theaterExp, setTheaterExp] = useState("");
  const [commercialExp, setCommercialExp] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [reelLink, setReelLink] = useState("");
  const [language, setLanguage] = useState([]);
  const [englishLevel, setEnglishLevel] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [hairColor, setHairColor] = useState("");
  const [pantSize, setPantSize] = useState("");
  const [pantSizeW, setPantSizeW] = useState("");
  const [pantSizeL, setPantSizeL] = useState("");
  const [shirtSize, setShirtSize] = useState("");
  const [shirtSizeF, setShirtSizeF] = useState("");
  const [dressShirt, setDressShirt] = useState("");
  const [shirtSleeve, setShirtSleeve] = useState("");
  const [neckSize, setNeckSize] = useState("");
  const [chestSize, setChestSize] = useState("");
  const [jacket, setJacket] = useState("");
  const [braCup, setBraCup] = useState("");
  const [braSize, setBraSize] = useState("");
  const [dressSize, setDressSize] = useState("");
  const [shoeSize, setShoeSize] = useState("");
  const [eyeColor, setEyeColor] = useState("");
  const [shirtSizeC, setShirtSizeC] = useState("");
  const [pantSizeWC, setPantSizeWC] = useState("");
  const [dressSizeC, setDressSizeC] = useState("");
  const [shoeSizeC, setShoeSizeC] = useState("");
  const [steps, setSteps] = useState(0);
  const [validated, setValidated] = useState(false);
  const [userData, setUserData] = useState("");
  const [userId, setUserId] = useState("");
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [waistImage, setWaistImage] = useState("");

  const [shoulderUpImage, setShoulderUpImage] = useState("");
  const [shoulderUpImageData, setShoulderUpImageData] = useState("");
  const [happy, setHappy] = useState("");
  const [happyData, setHappyData] = useState("");
  const [sad, setSad] = useState("");
  const [sadData, setSadData] = useState("");
  const [angry, setangry] = useState("");
  const [AngryData, setAngryData] = useState("");
  const [shoeSizeF, setShoeSizeF] = useState("");
  const [shoulderDownImage, setShoulderDownImage] = useState("");
  const [shoulderDownImageData, setShoulderDownImageData] = useState("");
  const [waistImageData, setWaistImageData] = useState(null);
  const [cropped, setCropped] = useState(false);
  const [galleryCropper, setGalleryCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [headerLoad, setHeaderLoad] = useState(false);
  const [relationStatus, setRelationStatus] = useState("");
  const [childrenCount, setChildrenCount] = useState("");
  const [auditions, setAuditions] = useState({
    occupation: "",
    threeThings: "",
    showOn: "",
    realityShow: "",
  });
  const [basicDetails, setBasicDetails] = useState({
    fname: "",
    lname: "",
    mobileNumber: "",
    birthDate: "",
    age: "",
    displayName: "",
    hourlyRate: "",
    fullRate: "",
    halfRate: "",
    tagLine: "",
    profilePhoto: "",
    guardianName: "",
    yearsExperience: "",
    cameraType: "",
    retouchConcent: "",
    listedFor: "",
    customExpertise: "",
  });
  const [address, setAddress] = useState({
    addressLine: "",
    addressLine2: "",
    postalCode: "",
  });
  const [socialLinks, setSocialLinks] = useState({
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    vimeo: "",
    tikTok: "",
  });
  const [bioDetails, setBioDetails] = useState({
    professionalBio: "",
    personalBio: "",
  });
  const [location, setLocation] = useState({
    countryID: "",
    countryList: "",
    stateList: "",
    cityList: "",
  });
  const [cityOptions, setCityOptions] = useState("");
  const [countryOptions, setCountryOptions] = useState("");
  const [stateOptions, setStateOptions] = useState("");
  const [clientDetails, setClientDetails] = useState({
    organization: "",
    title: "",
    businessEmail: "",
    businessWebsite: "",
    businessSince: "",
    description: "",
  });
  const emailValid = isValidEmail(email);

  useEffect(() => {
    // getUserData();
    if (loggedInUser) {
      setUserData(loggedInUser);
      let userData = loggedInUser?.user_data;
      let company_details = loggedInUser?.company_details;
      let post_meta_details = loggedInUser?.post_meta_details;
      let measurment_male = loggedInUser?.measurment_male;
      let social_followers = loggedInUser?.social_followers;
      let measurment = loggedInUser?.measurment;
      storeData(storageKey?.COUNTRY_ID, post_meta_details?.country_id);
      storeData(storageKey?.STATE_ID, post_meta_details?.state_id);
      setEmail(userData?.user_email || "");
      setUserRole(loggedInUser?.user_data?.user_role);
      setBasicDetails({
        ...basicDetails,
        fname: userData?.first_name,
        lname: userData?.last_name,
        mobileNumber: loggedInUser?.fw_option[0]?.user_phone_number,
        birthDate:
          post_meta_details?.dob ||
          loggedInUser?.personal_details?.date_of_birth ||
          new Date(),
        age:
          post_meta_details?.age != "00"
            ? post_meta_details?.age
            : loggedInUser?.extra?.age_months
              ? loggedInUser?.extra?.age_months
              : "",
        model_age: post_meta_details?.age,
        displayName: userData?.display_name,
        hourlyRate:
          post_meta_details?.perhour_rate ||
          loggedInUser?.personal_details?.perhour_rate,
        tagLine: loggedInUser?.fw_option[0]?.tag_line
          ? loggedInUser?.fw_option[0]?.tag_line
          : loggedInUser?.personal_details?.tag_line
          ? loggedInUser?.personal_details?.tag_line
          : post_meta_details?.tag_line,
        guardianName: loggedInUser?.guardian_detail?.guardian_name,
        customExpertise: loggedInUser?.personal_details?.custom_expertise,
        yearsExperience: loggedInUser?.personal_details?.years_experience,
        halfRate: loggedInUser?.personal_details?.half_day_rate,
        fullRate: loggedInUser?.personal_details?.full_day_rate,
      });
      setEyeColor(post_meta_details?.eye_color);
      setExperience(post_meta_details?.experience_level || loggedInUser?.personal_details?.experience_level);

      setLanguage(
        post_meta_details?.languages?.map((item) => {
          return {
            value: item?.name,
            label: item?.name,
          };
        })
      );
      setTattoLoc(post_meta_details?.tattoo_location || "");
      setTheaterExp(post_meta_details?.theater_exp || "");
      setEducationTraining(post_meta_details?.edu_training || "");
      setAwards(post_meta_details?.list_awards || "");
      setCommercialExp(post_meta_details?.comm_exp || "");
      setMemberOf(post_meta_details?.member_of || "");
      // setReelLink(social_followers?.link_to_reel || "");
      setVideoLink(post_meta_details?.video_link || "");
      setTalent(
        post_meta_details?.user_type === "actor" &&
          post_meta_details?.freelancer_type === "child" &&
          userData?.user_role == 15
          ? "Actor Kid"
          : post_meta_details?.freelancer_type === "child" &&
            userData?.user_role == 11
          ? "Model Kid"
          : userData?.user_role == 11
          ? "Model"
          : userData?.user_role == 15 &&
            post_meta_details?.user_type === "actor"
          ? "Actor"
          : userData?.user_role == 14
          ? "Partners"
          : userData?.user_role == 12
          ? "Client"
          : userData?.user_role == 13
          ? "Photographer"
          : post_meta_details?.freelancer_type
          ? post_meta_details?.freelancer_type
          : ""
      );
      setCountry(post_meta_details?.country);
      // getAllCityName(post_meta_details?.country_id, post_meta_details?.state_id)
      setState(post_meta_details?.state);
      setCity(post_meta_details?.city);
      setAddress({
        ...address,
        addressLine:
          post_meta_details?.address_line || post_meta_details?.address,
        addressLine2: post_meta_details?.address_line_2,
        postalCode: post_meta_details?.postal_code,
      });
      // setGender(userData?.gender);
      if (
        loggedInUser?.user_data?.gender == "male" ||
        loggedInUser?.personal_details?.gender === "male"
      ) {
        setGender("Male");
      } else if (
        loggedInUser?.user_data?.gender == "female" ||
        loggedInUser?.personal_details?.gender === "female"
      ) {
        setGender("Female");
      } else if (
        loggedInUser?.user_data?.gender == "other" ||
        loggedInUser?.personal_details?.gender === "other"
      ) {
        setGender("Non Binary");
      }
      setRelationStatus(loggedInUser?.user_data?.relationship_status || "");
      setChildrenCount(post_meta_details?.have_children || "");
      setAuditions({
        occupation: post_meta_details?.occupation || "",
        threeThings: post_meta_details?.about_you_nobody_knows || "",
        showOn: post_meta_details?.reality_show_yourself_why || "",
        realityShow: post_meta_details?.reality_show_what_when || "",
      });

      setEnglishLevel(post_meta_details?.english_level);
      setEthnicity(post_meta_details?.ethnicity);
      setHairColor(post_meta_details?.hair_colour);
      setHeight(post_meta_details?.height);
      setWeight(post_meta_details?.weight);
      setNeckSize(measurment?.neck_size);
      setShoulderUpImageData(
        loggedInUser?.fw_option?.[0]?.shoulders_up_straight
          ? loggedInUser?.fw_option?.[0]?.shoulders_up_straight
          : ""
      );
      setAngryData(
        loggedInUser?.fw_option?.[0]?.angry
          ? loggedInUser?.fw_option?.[0]?.angry
          : ""
      );
      setHappyData(
        loggedInUser?.fw_option?.[0]?.happy
          ? loggedInUser?.fw_option?.[0]?.happy
          : ""
      );
      setSadData(
        loggedInUser?.fw_option?.[0]?.sad
          ? loggedInUser?.fw_option?.[0]?.sad
          : ""
      );
      setWaistImageData(
        loggedInUser?.fw_option?.[0]?.waist_up
          ? loggedInUser?.fw_option?.[0]?.waist_up
          : ""
      );
      setShoulderDownImageData(
        loggedInUser?.fw_option?.[0]?.shoulders_up_turn
          ? loggedInUser?.fw_option?.[0]?.shoulders_up_turn
          : ""
      );
      setBioDetails({
        ...bioDetails,
        personalBio:
          post_meta_details?.personal_bio ||
          loggedInUser?.personal_details?.personal_bio,
        professionalBio:
          post_meta_details?.professional_bio ||
          loggedInUser?.personal_details?.professional_bio,
      });
      setMySkill(post_meta_details?.skills_names);
      setCustomSkills(post_meta_details?.custom_skills);
      setExpertise(loggedInUser?.personal_details?.expertise);
      // setExperience();
      // follow details
      setSocialLinks({
        ...socialLinks,
        facebook: social_followers?.facebook_profile_link,
        instagram: social_followers?.instagram_profile_link,
        tikTok: social_followers?.tiktok_profile_link
          ? social_followers?.tiktok_profile_link
          : social_followers?.ticktok_profile_link,
        youtube: social_followers?.youtube_profile_link,
        twitter: social_followers?.twitter_profile_link,
        vimeo: social_followers?.vimeo_profile_link,
        website: post_meta_details?.personal_website,
      });
      setFacebookFollowers(social_followers?.facebook_follower);
      setInstagramFollowers(social_followers?.instagram_follower);
      setYoutubeFollowers(social_followers?.youtube_follower);
      setTwitterFollowers(social_followers?.twitter_follower);
      setTiktokFollowers(social_followers?.tiktok_follower);
      setVimeoFollowers(social_followers?.vimeo_follower);
      // measurement
      if (
        userData?.gender == "male" ||
        userData?.gender == "female" ||
        userData?.gender == "Male" ||
        userData?.gender == "Female" ||
        userData?.gender == "non binary" ||
        userData?.gender == "Non binary" ||
        userData?.gender == "other"
      ) {
        if (measurment) {
          setShirtSize(
            measurment?.shirt_size
              ? measurment?.shirt_size
              : measurment?.shirt_size_m
          );
          setPantSizeW(measurment?.pant_size_waist);
          setPantSizeL(measurment?.pant_size_length);
          setJacket(measurment?.jacket);
          setChestSize(measurment?.chest_size);
          setDressShirt(measurment?.dress_shirt_size);
          setShirtSleeve(measurment?.dress_shirt_sleeve);
          setShoeSize(
            measurment?.shoe_size
              ? measurment?.shoe_size
              // : measurment?.shoe_size_f
              // ? measurment?.shoe_size_m
              : measurment?.shoe_size_m
          );
          setShoeSizeF( measurment?.shoe_size_f
            ? measurment?.shoe_size_f : measurment?.shoe_size )
          setShirtSizeF(
            measurment?.shirt_size
              ? measurment?.shirt_size
              : measurment?.shirt_size_f
          );
          setBraCup(measurment?.bra_cup);
          setBraSize(measurment?.bra_size);
          setDressSize( 
            measurment?.dress_size?.length
              ? measurment?.dress_size?.map((item) => item?.value)
              : measurment?.dress_size
          );
          setPantSize(
            measurment?.pant_size
              ? measurment?.pant_size
              : measurment?.pant_size_f
          );
        }
      }

      // child
      if (
        post_meta_details?.freelancer_type == "Child" ||
        post_meta_details?.freelancer_type == "child" ||
        post_meta_details?.freelancer_type == "Kid" ||
        post_meta_details?.freelancer_type == "kid"
      ) {
        // setGender(userData?.gender);
        if (userData?.gender == "male") {
          setGender("Male");
        } else if (userData?.gender == "female") {
          setGender("Female");
        } else if (userData?.gender == "other") {
          setGender("Non Binary");
        }
        if (measurment) {
          setShirtSizeC(measurment?.shirt_size);
          setPantSizeWC(
            measurment?.pant_size
              ? measurment?.pant_size
              : measurment?.pant_size_waist
                ? measurment?.pant_size_waist
                : measurment?.pant_size_length
          );
          setDressSizeC(measurment?.dress_size);

          setShoeSizeC(measurment?.shoe_size);
          setPantSize(
            measurment?.pant_size
              ? measurment?.pant_size
              : measurment?.pant_size_waist
                ? measurment?.pant_size_waist
                : measurment?.pant_size_length
          );
        }
      }

      if (

        loggedInUser?.user_data?.user_role == 12 ||
        loggedInUser?.user_data?.user_role == 14

      ) {
        setBasicDetails({
          ...basicDetails,
          fname: userData?.first_name,
          lname: userData?.last_name,
          mobileNumber: loggedInUser?.fw_option[0]?.user_phone_number,
          tagLine: company_details?.tag_line,
          displayName: userData?.display_name,

          age:
            post_meta_details?.age != "00"
              ? post_meta_details?.age
              : loggedInUser?.extra?.age_months
                ? loggedInUser?.extra?.age_months
                : "",
        });

        // setGender(res?.results?.fw_option[0]?.gender);
        setClientDetails({
          ...clientDetails,
          organization: company_details?.organization,
          title: company_details?.title,
          businessEmail: company_details?.bussiness_email_address,
          businessWebsite: company_details?.bussiness_website,
          businessSince: company_details?.bussiness_since,
          description: company_details?.description,
        });
        setCountry(post_meta_details?.country);
        setState(post_meta_details?.state);
        setCity(post_meta_details?.city);
        // getAllCityName(post_meta_details?.country_id, post_meta_details?.state_id)

        setAddress({
          ...address,
          addressLine: post_meta_details?.address,
          addressLine2: post_meta_details?.address_line_2,
          postalCode: post_meta_details?.postal_code,
        });
      }
    }
  }, [loggedInUser]);

  useEffect(() => {
    getUserImages();
    getAllCountryName("country", 1);
  }, []);

  useEffect(() => {
    if (optionsType === "error") {
      getAllOptionsData();
    }
  }, [optionsType]);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    let userRoles = await getData(storageKey?.USER_ROLE);
    setUserRole(userRoles);
    setUserId(JSON?.parse(userID));
    let body = {
      user_id: JSON?.parse(userID),
    };
    let res = await dispatch(getUserDetail(body));
    console?.log('getUserDetail response------', res?.results)
    if (res?.status == 200) {
      // setUserData(res.results);
      // let userData = res?.results?.user_data;
      // let company_details = res?.results?.company_details;
      // let post_meta_details = res?.results?.post_meta_details;
      // let measurment_male = res?.results?.measurment_male;
      // let social_followers = res?.results?.social_followers;
      // let measurment = res?.results?.measurment;
      // storeData(storageKey?.COUNTRY_ID, post_meta_details?.country_id);
      // storeData(storageKey?.STATE_ID, post_meta_details?.state_id);
      // setEmail(userData?.user_email || "");
      // setUserRole(res?.results?.user_data?.user_role);
      // setBasicDetails({
      //   ...basicDetails,
      //   fname: userData?.first_name,
      //   lname: userData?.last_name,
      //   mobileNumber: res?.results?.fw_option[0]?.user_phone_number,
      //   birthDate: post_meta_details?.dob ? post_meta_details?.dob : new Date(),
      //   age: post_meta_details?.age
      //     ? post_meta_details?.age
      //     : res?.results?.extra?.age_months
      //     ? res?.results?.extra?.age_months
      //     : "",
      //   model_age: post_meta_details?.age,
      //   displayName: userData?.display_name,
      //   hourlyRate: post_meta_details?.perhour_rate || loggedInUser?.personal_details?.perhour_rate,
      //   tagLine: res?.results?.fw_option[0]?.tag_line
      //     ? res?.results?.fw_option[0]?.tag_line
      //     : post_meta_details?.tag_line,
      //   guardianName: res?.results?.guardian_detail?.guardian_name,
      // });
      // setEyeColor(post_meta_details?.eye_color);
      // setExperience(post_meta_details?.experience_level);
      // setLanguage(
      //   post_meta_details?.languages?.map((item) => {
      //     return {
      //       value: item?.name,
      //       label: item?.name,
      //     };
      //   })
      // );
      // setTattoLoc(post_meta_details?.tattoo_location || "");
      // setTheaterExp(post_meta_details?.theater_exp || "");
      // setEducationTraining(post_meta_details?.edu_training || "");
      // setAwards(post_meta_details?.list_awards || "");
      // setCommercialExp(post_meta_details?.comm_exp || "");
      // setMemberOf(post_meta_details?.member_of || "");
      // // setReelLink(social_followers?.link_to_reel || "");
      // setVideoLink(post_meta_details?.video_link || "");
      // setTalent(
      //   post_meta_details?.user_type === "actor" &&
      //     post_meta_details?.freelancer_type === "child" &&
      //     userData?.user_role == 15
      //     ? "Actor Kid"
      //     : post_meta_details?.freelancer_type === "child" &&
      //       userData?.user_role == 11
      //     ? "Model Kid"
      //     : userData?.user_role == 11
      //     ? "Model"
      //     : userData?.user_role == 15 &&
      //       post_meta_details?.user_type === "actor"
      //     ? "Actor"
      //     : userData?.user_role == 14
      //     ? "Partners"
      //     : post_meta_details?.freelancer_type
      //     ? post_meta_details?.freelancer_type
      //     : ""
      // );
      // setCountry(post_meta_details?.country);
      // // setUserRole(userData?.user_role)
      // setState(post_meta_details?.state);
      // // getAllCityName(post_meta_details?.country_id, post_meta_details?.state_id)
      // setCity(post_meta_details?.city);
      // setAddress({
      //   ...address,
      //   addressLine: post_meta_details?.address_line,
      //   addressLine2: post_meta_details?.address_line_2,
      //   postalCode: post_meta_details?.postal_code,
      // });
      // // setGender(userData?.gender);
      // if (res?.results?.user_data?.gender == "male") {
      //   setGender("Male");
      // } else if (res?.results?.user_data?.gender == "female") {
      //   setGender("Female");
      // } else if (res?.results?.user_data?.gender == "other") {
      //   setGender("Non Binary");
      // }
      // setRelationStatus(res?.results?.user_data?.relationship_status || "");
      // setChildrenCount(post_meta_details?.have_children || "");
      // setAuditions({
      //   occupation: post_meta_details?.occupation || "",
      //   threeThings: post_meta_details?.about_you_nobody_knows || "",
      //   showOn: post_meta_details?.reality_show_yourself_why || "",
      //   realityShow: post_meta_details?.reality_show_what_when || "",
      // });
      // setEnglishLevel(post_meta_details?.english_level);
      // setEthnicity(post_meta_details?.ethnicity);
      // setHairColor(post_meta_details?.hair_colour);
      // setHeight(post_meta_details?.height);
      // setWeight(post_meta_details?.weight);
      // setNeckSize(measurment?.neck_size);
      // setShoulderUpImageData(
      //   res?.results?.fw_option?.[0]?.shoulders_up_straight
      //     ? res?.results?.fw_option?.[0]?.shoulders_up_straight
      //     : ""
      // );
      // setAngryData(
      //   res?.results?.fw_option?.[0]?.angry
      //     ? res?.results?.fw_option?.[0]?.angry
      //     : ""
      // );
      // setHappyData(
      //   res?.results?.fw_option?.[0]?.happy
      //     ? res?.results?.fw_option?.[0]?.happy
      //     : ""
      // );
      // setSadData(
      //   res?.results?.fw_option?.[0]?.sad
      //     ? res?.results?.fw_option?.[0]?.sad
      //     : ""
      // );
      // setWaistImageData(
      //   res?.results?.fw_option?.[0]?.waist_up
      //     ? res?.results?.fw_option?.[0]?.waist_up
      //     : ""
      // );
      // setShoulderDownImageData(
      //   res?.results?.fw_option?.[0]?.shoulders_up_turn
      //     ? res?.results?.fw_option?.[0]?.shoulders_up_turn
      //     : ""
      // );
      // setBioDetails({
      //   ...bioDetails,
      //   personalBio: post_meta_details?.personal_bio,
      //   professionalBio: post_meta_details?.professional_bio,
      // });
      // setMySkill(post_meta_details?.skills_names);
      // setCustomSkills(post_meta_details?.custom_skills);
      // // follow details
      // setSocialLinks({
      //   ...socialLinks,
      //   facebook: social_followers?.facebook_profile_link,
      //   instagram: social_followers?.instagram_profile_link,
      //   tikTok: social_followers?.tiktok_profile_link
      //     ? social_followers?.tiktok_profile_link
      //     : social_followers?.ticktok_profile_link,
      //   youtube: social_followers?.youtube_profile_link,
      //   twitter: social_followers?.twitter_profile_link,
      //   vimeo: social_followers?.vimeo_profile_link,
      //   website: post_meta_details?.personal_website,
      // });
      // setFacebookFollowers(social_followers?.facebook_follower);
      // setInstagramFollowers(social_followers?.instagram_follower);
      // setYoutubeFollowers(social_followers?.youtube_follower);
      // setTwitterFollowers(social_followers?.twitter_follower);
      // setTiktokFollowers(social_followers?.tiktok_follower);
      // setVimeoFollowers(social_followers?.vimeo_follower);
      // // measurement
      // if (
      //   userData?.gender == "male" ||
      //   userData?.gender == "female" ||
      //   userData?.gender == "Male" ||
      //   userData?.gender == "Female" ||
      //   userData?.gender == "non binary" ||
      //   userData?.gender == "Non binary" ||
      //   userData?.gender == "other"
      // ) {
      //   if (measurment) {
      //     setShirtSize(
      //       measurment?.shirt_size
      //         ? measurment?.shirt_size
      //         : measurment?.shirt_size_m
      //     );
      //     setPantSizeW(measurment?.pant_size_waist);
      //     setPantSizeL(measurment?.pant_size_length);
      //     setJacket(measurment?.jacket);
      //     setChestSize(measurment?.chest_size);
      //     setDressShirt(measurment?.dress_shirt_size);
      //     setShirtSleeve(measurment?.dress_shirt_sleeve);
      //     setShoeSize(
      //       measurment?.shoe_size
      //         ? measurment?.shoe_size
      //         : measurment?.shoe_size_f
      //         ? measurment?.shoe_size_f
      //         : measurment?.shoe_size_m
      //     );
      //     setShirtSizeF(
      //       measurment?.shirt_size
      //         ? measurment?.shirt_size
      //         : measurment?.shirt_size_f
      //     );
      //     setBraCup(measurment?.bra_cup);
      //     setBraSize(measurment?.bra_size);
      //     setDressSize(
      //       userData?.gender == "female" || userData?.gender == "Female"
      //         ? measurment?.dress_size?.map((item) => item.value)
      //         : measurment?.dress_size
      //     );
      //     setPantSize(
      //       measurment?.pant_size
      //         ? measurment?.pant_size
      //         : measurment?.pant_size_f
      //     );
      //   }
      // }
      // // child
      // if (
      //   post_meta_details?.freelancer_type == "Child" ||
      //   post_meta_details?.freelancer_type == "child" ||
      //   post_meta_details?.freelancer_type == "Kid" ||
      //   post_meta_details?.freelancer_type == "kid"
      // ) {
      //   // setGender(userData?.gender);
      //   if (userData?.gender == "male") {
      //     setGender("Male");
      //   } else if (userData?.gender == "female") {
      //     setGender("Female");
      //   } else if (userData?.gender == "other") {
      //     setGender("Non Binary");
      //   }
      //   if (measurment) {
      //     setShirtSizeC(measurment?.shirt_size);
      //     setPantSizeWC(
      //       measurment?.pant_size
      //         ? measurment?.pant_size
      //         : measurment?.pant_size_waist
      //         ? measurment?.pant_size_waist
      //         : measurment?.pant_size_length
      //     );
      //     setDressSizeC(measurment?.dress_size);
      //     setShoeSizeC(measurment?.shoe_size);
      //     setPantSize(
      //       measurment?.pant_size
      //         ? measurment?.pant_size
      //         : measurment?.pant_size_waist
      //         ? measurment?.pant_size_waist
      //         : measurment?.pant_size_length
      //     );
      //   }
      // }
      // if (
      //   res?.results?.user_data?.user_role == "12" ||
      //   res?.results?.user_data?.user_role == 12 ||
      //   res?.results?.user_data?.user_role == 14 ||
      //   res?.results?.user_data?.user_role == "14"
      // ) {
      //   setBasicDetails({
      //     ...basicDetails,
      //     fname: userData?.first_name,
      //     lname: userData?.last_name,
      //     mobileNumber: res?.results?.fw_option[0]?.user_phone_number,
      //     tagLine: company_details?.tag_line,
      //     displayName: userData?.display_name,
      //     age: post_meta_details?.age,
      //   });
      // setGender(res?.results?.fw_option[0]?.gender);
      // setClientDetails({
      //   ...clientDetails,
      //   organization: company_details?.organization,
      //   title: company_details?.title,
      //   businessEmail: company_details?.bussiness_email_address,
      //   businessWebsite: company_details?.bussiness_website,
      //   businessSince: company_details?.bussiness_since,
      //   description: company_details?.description,
      // });
      // setCountry(post_meta_details?.country);
      // setState(post_meta_details?.state);
      // setCity(post_meta_details?.city);
      // // getAllCityName(post_meta_details?.country_id, post_meta_details?.state_id)
      // setAddress({
      //   ...address,
      //   addressLine: post_meta_details?.address,
      //   addressLine2: post_meta_details?.address_line_2,
      //   postalCode: post_meta_details?.postal_code,
      // });
    }
  };

  const getAllCountryName = async (type, step) => {
    var body = {};
    let res = await dispatch(getCountryList(body));
    if (res?.status == 200) {
      let data;
      if (type == "country") {
        data = res?.results;
        setCountryOptions(data);
      }
    }
  };

  const getAllStateName = async () => {
    const selectedOptionObject = countryOptions?.length
      ? countryOptions?.find(
          (option) => option.value === country || option.name === country
        )
      : {};
    var body = {
      country: selectedOptionObject?.id || "",
      city: "",
    };
    let res = await dispatch(getCountryList(body));
    setCityOptions([]);
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        setStateOptions(data);
        // setCityOptions([])
      } else {
        setStateOptions([]);
        // setCityOptions([])
      }
    }
  };

  const getAllCityName = async (countryId, stateId) => {
    const selectedCountry =
      !countryId && countryOptions?.length
        ? countryOptions?.find(
            (option) => option.value === country || option.name === country
          )
        : {};
    const selectedState =
      !stateId && stateOptions?.length
        ? stateOptions?.find(
            (option) => option.value === state || option.name === state
          )
        : {};
    var body = {
      country: countryId || selectedCountry?.id || "",
      state: stateId || selectedState?.id || "",
      city: "",
    };
    let res = await dispatch(getCountryList(body));
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        setCityOptions(data);
      } else {
        setCityOptions([]);
      }
    }
  };

  const getAllOptionsData = async () => {
    let userRole = await getData(storageKey?.USER_ROLE);
    setUserRole(userRole);
    await dispatch(getOptionsData());
  };

  useEffect(() => {
    if (country && countryOptions?.length) {
      getAllStateName();
    }
  }, [country, countryOptions]);

  useEffect(() => {
    if (state && stateOptions?.length) {
      getAllCityName();
    }
  }, [state, stateOptions]);

  const handleForm = async (event) => {
    setError(true);
    event.preventDefault();
    if (
      talent === "Actor" ||
      talent === "Model" ||
      talent === "Model Kid" ||
      talent === "Actor Kid"
    ) {
      if (!gender) {
        showToast("Gender is required", "error");
        return;
      }
    }
    if (!basicDetails?.fname) {
      showToast("First Name is required", "error");
      return;
    }
    if (!basicDetails?.lname) {
      showToast("Last Name is required", "error");
      return;
    }
    if (!basicDetails?.displayName) {
      showToast("Display Name is required", "error");
      return;
    }
    if (!basicDetails?.displayName) {
      showToast("Display Name is required", "error");
      return;
    }
    if (!basicDetails?.mobileNumber) {
      showToast("Mobile number is required", "error");
      return;
    }
    if (
      !basicDetails?.guardianName &&
      (talent === "Model Kid" || talent === "Actor Kid")
    ) {
      showToast("Guardian Name is required", "error");
      return;
    }

    if (talent == "Client" || talent == "Partners") {
      if (!clientDetails?.organization) {
        showToast("Organisation is required", "error");
        return;
      }
      if (!clientDetails?.title) {
        showToast("Title is required", "error");
        return;
      }
      if (!clientDetails?.businessEmail) {
        showToast("Business Email is required", "error");
        return;
      }
      if (!clientDetails?.businessWebsite) {
        showToast("Business Website is required", "error");
        return;
      }
      if (!clientDetails?.description) {
        showToast("Description is required", "error");
        return;
      }
    }

    if (talent === "Photographer") {
      if (!basicDetails?.hourlyRate) {
        showToast("Hourly Rate is required", "error");
        return;
      }
      if (!basicDetails?.halfRate) {
        showToast("Half Day Rate is required", "error");
        return;
      }
      if (!basicDetails?.fullRate) {
        showToast("Full Day Rate is required", "error");
        return;
      }
      if (!country) {
        showToast("Country is required", "error");
        return;
      }
      if (!state) {
        showToast("State is required", "error");
        return;
      }
      if (cityOptions?.length && !city) {
        showToast("City is required", "error");
        return;
      }
      if (!address?.addressLine) {
        showToast("Address is required", "error");
        return;
      }
      if (!address?.postalCode) {
        showToast("Postal Code is required", "error");
        return;
      }
      if (!bioDetails?.professionalBio) {
        showToast("Professional Bio is required", "error");
        return;
      }
      if (!bioDetails?.personalBio) {
        showToast("Personal Bio is required", "error");
        return;
      }
      if (!expertise?.length) {
        showToast("Expertise are required", "error");
        return;
      }
      if (!experience) {
        showToast("Experience level is required", "error");
        return;
      }
      if (!basicDetails?.yearsExperience) {
        showToast("Years of experience is required", "error");
        return;
      }
      if (!mySkill?.length) {
        showToast("Skills are required", "error");
        return;
      }
    }

    if (
      talent === "Actor" ||
      talent === "Model" ||
      talent === "Model Kid" ||
      talent === "Actor Kid"
    ) {
      if (!basicDetails?.hourlyRate) {
        showToast("Hourly Rate is required", "error");
        return;
      }
      if (!country) {
        showToast("Country is required", "error");
        return;
      }
      if (!state) {
        showToast("State is required", "error");
        return;
      }
      if (cityOptions?.length && !city) {
        showToast("City is required", "error");
        return;
      }
      // if (location?.cityList?.length && !city) {
      //   showToast("City is required", "error");
      //   return;
      // }
      if (!address?.addressLine) {
        showToast("Address is required", "error");
        return;
      }
      if (!address?.postalCode) {
        showToast("Postal Code is required", "error");
        return;
      }
      if (!language?.length) {
        showToast("Language is required", "error");
        return;
      }
      if (!englishLevel) {
        showToast("English Level is required", "error");
        return;
      }
      if (!englishLevel) {
        showToast("English Level is required", "error");
        return;
      }
      if (!bioDetails?.professionalBio) {
        showToast("Professional Bio is required", "error");
        return;
      }
      if (!bioDetails?.personalBio) {
        showToast("Personal Bio is required", "error");
        return;
      }
      if (!height) {
        showToast("Height is required", "error");
        return;
      }
      if (!weight) {
        showToast("Weight is required", "error");
        return;
      }
      if (!ethnicity?.length) {
        showToast("Ethnicity is required", "error");
        return;
      }
      if (!hairColor) {
        showToast("Hair Colour is required", "error");
        return;
      }
      if (!eyeColor) {
        showToast("Eye Colour is required", "error");
        return;
      }
      if (!experience) {
        showToast("Experience is required", "error");
        return;
      }
      if (!mySkill?.length) {
        showToast("Skills are required", "error");
        return;
      }
      if (
        basicDetails?.age <= 13 ||
        basicDetails?.age?.includes("Month") ||
        basicDetails?.age?.includes("M")
      ) {
        if (!shirtSizeC?.length) {
          showToast("Shirt size is required", "error");
          return;
        }
        if (!pantSizeWC?.length) {
          showToast("Pant size is required", "error");
          return;
        }
        if (!shoeSizeC?.length) {
          showToast("Shoe size is required", "error");
          return;
        }
        if (!dressSizeC?.length && (gender == "Female" || gender == "female")) {
          showToast("Dress size is required", "error");
          return;
        }
      }
      if (basicDetails?.age > 13 && (gender == "Male" || gender == "male")) {
        if (!shirtSize?.length) {
          showToast("Shirt size is required", "error");
          return;
        }
        if (!pantSizeW?.length) {
          showToast("Pant size(waist) is required", "error");
          return;
        }
        if (!pantSizeL?.length) {
          showToast("Pant size(length) is required", "error");
          return;
        }
        if (!shoeSize?.length) {
          showToast("Shoe size is required", "error");
          return;
        }
        if (!dressShirt) {
          showToast("Dress shirt size is required", "error");
          return;
        }
        if (!shirtSleeve) {
          showToast("Dress Shirt Sleeve is required", "error");
          return;
        }
        if (!neckSize) {
          showToast("Neck Size is required", "error");
          return;
        }
        if (!chestSize) {
          showToast("Chest Size is required", "error");
          return;
        }
        if (!jacket) {
          showToast("Jacket Size is required", "error");
          return;
        }
      }
      if (
        basicDetails?.age > 13 &&
        (gender == "Female" || gender == "female")
      ) {
        if (!shirtSizeF?.length) {
          showToast("Shirt size is required", "error");
          return;
        }
        if (!pantSize?.length) {
          showToast("Pant size is required", "error");
          return;
        }
        if (!braCup) {
          showToast("Bra cup is required", "error");
          return;
        }
        if (!braSize) {
          showToast("Bra size is required", "error");
          return;
        }
        if (!dressSize) {
          showToast("Dress Size is required", "error");
          return;
        }
        if (!shoeSizeF?.length) {
          showToast("Shoe size is required", "error");
          return;
        }
      }
      // if (
      //   basicDetails?.age > 13 &&
      //   (gender == "Non Binary" || gender == "non binary" || gender == "other")
      // ) {
      //   if (!shirtSize?.length) {
      //     showToast("Shirt size(M) is required", "error");
      //     return;
      //   }
      //   if (!pantSizeW?.length) {
      //     showToast("Pant size(waist) is required", "error");
      //     return;
      //   }
      //   if (!pantSizeL?.length) {
      //     showToast("Pant size(length) is required", "error");
      //     return;
      //   }
      //   if (!shoeSize?.length) {
      //     showToast("Shoe size(M) is required", "error");
      //     return;
      //   }
      //   if (!dressShirt) {
      //     showToast("Dress shirt size(M) is required", "error");
      //     return;
      //   }
      //   if (!shirtSleeve) {
      //     showToast("Dress Shirt Sleeve(M) is required", "error");
      //     return;
      //   }
      //   if (!neckSize) {
      //     showToast("Neck Size is required", "error");
      //     return;
      //   }
      //   if (!chestSize) {
      //     showToast("Chest Size is required", "error");
      //     return;
      //   }
      //   if (!jacket) {
      //     showToast("Jacket Size is required", "error");
      //     return;
      //   }
      //   if (!shirtSizeF?.length) {
      //     showToast("Shirt size(F) is required", "error");
      //     return;
      //   }
      //   if (!pantSize?.length) {
      //     showToast("Pant size(F) is required", "error");
      //     return;
      //   }
      //   if (!braCup) {
      //     showToast("Bra cup is required", "error");
      //     return;
      //   }
      //   if (!braSize) {
      //     showToast("Bra size is required", "error");
      //     return;
      //   }
      //   if (!dressSize) {
      //     showToast("Dress Size(F) is required", "error");
      //     return;
      //   }
      //   if (!shoeSizeF?.length) {
      //     showToast("Shoe size(F) is required", "error");
      //     return;
      //   }
      // }
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      if (userRole == 13) {
        handlePhotographerRegisteration();
      } else if (userRole == 14) {
        handlePatnerRegisteration();
      } else {
        handleProfileSetup();
      }
      setError(false);
    }
  };

  const handleProfileSetup = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    setError(true);

    let body = {
      action: "update",
      first_name: basicDetails?.fname,
      last_name: basicDetails?.lname,
      user_id: userId ? JSON.parse(userId) : "",
      profile_id: profileId ? JSON.parse(profileId) : "",
      phone_number: basicDetails?.mobileNumber,
      hourly_rate: basicDetails?.hourlyRate,
      tageline: basicDetails?.tagLine,
      country: country,
      state: state,
      city: city,
      address_line1: address?.addressLine,
      address_line2: address?.addressLine2,
      postal_code: address?.postalCode,
      languages: language?.length
        ? language?.map(({ value, label }) => ({
            name: label,
            id: value,
          }))
        : [],
      english: englishLevel,
      talent_type: talent,
      professional_bio: bioDetails?.professionalBio,
      personal_bio: bioDetails?.personalBio,
      height_inch: height,
      weight_pound: weight,
      ethnicity: ethnicity,
      hair_color: hairColor,
      experience_level: experience,
      facebook_link: socialLinks?.facebook,
      instagram_link: socialLinks?.instagram,
      twitter_link: socialLinks?.twitter,
      youtube_link: socialLinks?.youtube,
      vimeo_link: socialLinks?.vimeo,
      tiktok_link: socialLinks?.tikTok,
      facebook_followers: facebookFollowers,
      instagram_followers: instagramFollowers,
      twitter_followers: twitterFollowers,
      youtube_followers: youtubeFollowers,
      vimeo_followers: vimeoFollowers,
      tiktok_followers: tiktokFollowers,
      shirt_size: shirtSize,
      pant_size_waist: pantSizeW,
      pant_size: pantSizeL,
      pant_size_length: pantSizeL,
      shoe_size: shoeSize ? shoeSize : shoeSizeC,
      dress_shirt_size: dressShirt,
      dress_shirt_sleeve: shirtSleeve,
      neck_size: neckSize ? neckSize : "",
      chest_size: chestSize,
      jacket: jacket,
      shirt_size_f: shirtSizeF,
      pant_size_f: pantSize,
      bra_cup: braCup,
      bra_size: braSize,
      dress_size_f: dressSize,
      shoe_size_f: shoeSizeF,
      skills: mySkill,
      custom_skills: customSkills,
      // kid Registeration
      eye_color: eyeColor,
      gardian_name: basicDetails?.guardianName,
      gender:
        gender == "Male" || gender == "male"
          ? "male"
          : gender == "Female" || gender == "female"
            ? "female"
            : gender == "non binary" ||
              gender == "Non Binary" ||
              gender == "other" ||
              gender == "Other"
              ? "other"
              : "",
      children_shirt_size: shirtSizeC,
      children_pant_size: pantSizeWC,
      children_shoe_size: shoeSizeC,
      children_dress_size: dressSizeC,

      toddler_shirt_size: shirtSizeC,
      toddler_pant_size: pantSizeWC,
      toddler_shoe_size: shoeSizeC,
      toddler_dress_size: dressSizeC,

      infant_shirt_size: shirtSizeC,
      infant_dress_size: dressSizeC,
      infant_pant_size: pantSizeWC,
      infant_shoe_size: shoeSizeC,

      // Client
      title: clientDetails?.title,
      business_Email: clientDetails?.businessEmail,
      business_website: clientDetails?.businessWebsite,
      business_since: clientDetails?.businessSince,
      description: clientDetails?.description,

      tattoo_location: tattooLoc,
      member_of: memberOf,
      organization: clientDetails?.organization,
      theater_exp: theaterExp,
      edu_training: educationTraining,
      comm_exp: commercialExp,
      video_link: videoLink,
      // link_to_reel: reelLink,
      personal_website: socialLinks?.website,
    };

    if (talent === "Actor") {
      body.relationship_status = relationStatus || "";
      body.have_children = childrenCount || "";
      body.occupation = auditions?.occupation || "";
      body.about_you_nobody_knows = auditions?.threeThings || "";
      body.reality_show_yourself_why = auditions?.showOn || "";
      body.reality_show_what_when = auditions?.realityShow || "";
    }

    let res = await dispatch(profileSetup(body));
    console.log("profileSetupprofileSetup-----", body);
    if (res?.status == 200) {
      getUserData();
      showToast("Saved !", "success");
      setError(false);
      setValidated(false);
      setSteps(3);
      window.scrollTo(0, 0);
    }
  };

  const handleChooseGallery = async (event) => {
    const files = event.target.files;
    if (files?.length != 0) {
      const newDetails = Array.from(files).map((file) => ({
        uri: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
        type: file?.type,
      }));

      let newArr = [...(images || []), ...newDetails];
      if (newArr?.length > 10) {
        alert(
          "You cannot upload another Image. You reached your limit, Choose Minimum 3 Images or Maximum 10 Images."
        );
      } else {
        setSelectedFiles((prevFileDetails) => [
          ...(prevFileDetails || []),
          ...newDetails,
        ]);
        setGalleryCropper(true);
      }
    }
  };
  const handleNext = async () => {
    if (!profileImage) {
      showToast("Please upload your profile photo", "error");
    } else if (
      talent != "Client" &&
      talent != "Partners" &&
      images?.length >= 0 &&
      images?.length < 3
    ) {
      alert(
        "You have to upload Minimum 3 Images or Maximum 10 Images in Gallery Section ."
      );
    } else {
      let uploadData = new FormData();
      let userId = await getData(storageKey?.USER_ID);
      let profileId = await getData(storageKey?.PROFILE_ID);

      uploadData.append("upload_type", "next");
      uploadData.append("user_id", JSON.parse(userId));
      uploadData.append("profile_id", JSON?.parse(profileId));

      let res = await dispatch(uploadGalleryProfile(uploadData));
      if (res?.status == 200) {
        showToast("Application Submitted Successfully !", "success");
        // navigate(routeName?.DASHBOARD);
       
        let body = {
          user_id: JSON.parse(userId),
          profile_id: JSON?.parse(profileId),
          email: email,
        };
        let encoded = doubleEncodedData(body);
        if(window){
          window.open(`https://booksculp.com/redirect/?code=dashboard&redirect=${encoded}`,"_self")
        }
        window.scrollTo(0, 0);
      }
    }
  };
  const removeGalleryImage = async (eachImage, type) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);

    var body = {
      user_id: JSON.parse(userId),
      profile_id: JSON.parse(profileId),
      image_type: type,
      image_id:
        type == "gallery"
          ? eachImage?.attachment_id
          : type === "waist_up"
            ? waistImageData?.attachment_id
            : type === "shoulders_up_straight"
              ? shoulderUpImageData?.attachment_id
              : type === "shoulders_up_turn"
                ? shoulderDownImageData?.attachment_id
                : type === "happy"
                  ? happyData?.attachment_id
                  : type === "sad"
                    ? sadData?.attachment_id
                    : type === "angry"
                      ? AngryData?.attachment_id
                      : profileImage?.attachment_id,
      action: "remove",
    };
    let res = await dispatch(removeImage(body));
    if (res?.status == 200) {
      getUserImages();
    }

    if (type == "profile") {
      setProfileImage("");
    } else if (type === "waist_up") {
      setWaistImageData("");
    } else if (type === "shoulders_up_straight") {
      setShoulderUpImageData("");
    } else if (type === "shoulders_up_turn") {
      setShoulderDownImageData("");
    } else if (type === "happy") {
      setHappyData("");
    } else if (type === "sad") {
      setSadData("");
    } else if (type === "angry") {
      setAngryData("");
    }
  };
  const getUserImages = async () => {
    let userID = await getData(storageKey?.USER_ID);
    let userRole = await getData(storageKey?.USER_ROLE);
    setUserRole(userRole);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status == 200) {
        let data =
          res?.results?.images_gallery &&
            res?.results?.images_gallery?.length != 0
            ? res?.results?.images_gallery
            : [];
        res?.results?.profile_image && res?.results?.profile_image?.length != 0
          ? setProfileImage({
            ...profileImage,
            uri: res?.results?.profile_image[0]?.guid,
          })
          : setProfileImage("");
        data?.length != 0 ? setImages(data) : setImages([]);
      }
    }
  };

  const handleChooseProfile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      const detail = {
        uri: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
        type: file?.type,
      };
      setSelectedFile(detail);
      setCropped(true);
    } else {
      setProfileImage(null);
    }
  };

  const handleChooseImages = (event, type) => {
    const file = event.target.files[0];
    if (type === "waist") {
      setWaistImage(event.target.files[0]);
    } else if (type === "shoulderUp") {
      setShoulderUpImage(event.target.files[0]);
    } else if (type === "shoulderDown") {
      setShoulderDownImage(event.target.files[0]);
    } else if (type === "happy") {
      setHappy(event.target.files[0]);
    } else if (type === "sad") {
      setSad(event.target.files[0]);
    } else if (type === "angry") {
      setangry(event.target.files[0]);
    }
    if (file) {
      const detail = {
        uri: URL.createObjectURL(file),
        name: file.name,
        size: file.size,
        type: file?.type,
      };
      if (type === "waist") {
        setWaistImage(detail);
      } else if (type === "shoulderUp") {
        setShoulderUpImage(detail);
      } else if (type === "shoulderDown") {
        setShoulderDownImage(detail);
      } else if (type === "happy") {
        setHappy(detail);
      } else if (type === "sad") {
        setSad(detail);
      } else if (type === "angry") {
        setangry(detail);
      }

      setCropped(true);
    } else {
      if (type === "waist") {
        setWaistImageData(null);
      } else if (type === "shoulderUp") {
        setShoulderUpImageData(null);
      } else if (type === "shoulderDown") {
        setShoulderDownImageData(null);
      } else if (type === "happy") {
        setHappyData(null);
      } else if (type === "sad") {
        setSadData(null);
      } else if (type === "angry") {
        setAngryData(null);
      }
    }
  };

  const uploadWaistphoto = async (data, type) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let uploadData = new FormData();
    uploadData.append("upload_type", type);
    uploadData.append("user_id", JSON.parse(userId));
    uploadData.append("profile_id", JSON?.parse(profileId));
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setCropped(false);

      if (type === "waist_up") {
        setWaistImage([]);
        setWaistImageData({
          ...waistImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "shoulders_up_straight") {
        setShoulderUpImage([]);
        setShoulderUpImageData({
          ...shoulderUpImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "shoulders_up_turn") {
        setShoulderDownImage([]);
        setShoulderDownImageData({
          ...shoulderDownImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "happy") {
        setHappy([]);
        setHappyData({
          ...happyData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "sad") {
        setSad([]);
        setSadData({
          ...sadData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "angry") {
        setangry([]);
        setAngryData({
          ...AngryData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      }
    }
  };

  const uploadProfilePhoto = async (data, type) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let uploadData = new FormData();
    uploadData.append("upload_type", "profile");
    uploadData.append("user_id", JSON.parse(userId));
    uploadData.append("profile_id", JSON?.parse(profileId));
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setCropped(false);
      setSelectedFile([]);
      setProfileImage({
        ...profileImage,
        uri: res?.results?.uri,
        name: res?.results.name,
        size: res?.results.size,
        type: res?.results?.type,
      });
      setHeaderLoad(true);
      setTimeout(() => {
        setHeaderLoad(false);
      }, 3000);
    }
  };

  const handleGallerySubmit = async (data) => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let uploadData = new FormData();

    console.log(data,"gdhfgdhsgf");

    uploadData.append("upload_type", "gallery");
    uploadData.append("user_id", JSON.parse(userId));
    uploadData.append("profile_id", JSON?.parse(profileId));
    Array.from(data).map((file, index) => {
      uploadData.append("file_type" + "[" + [index] + "]", file);
    });
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setImages(res.results);
      setSelectedFiles([]);
    }
  };

  const handlePatnerRegisteration = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    setError(true);
    let body = {
      user_type: talent == "Partners" ? "partners" : "",
      first_name: basicDetails?.fname,
      last_name: basicDetails?.lname,
      action: "update",
      display_name: basicDetails?.displayName,
      organization: clientDetails?.organization,
      terms: agree,
      mobile: basicDetails?.mobileNumber,
      tageline: basicDetails?.tagLine,
      country: country,
      state: state,
      city: city,
      address_line1: address?.addressLine,
      address_line2: address?.addressLine2,
      postal_code: address?.postalCode,
      facebook: socialLinks?.facebook,
      instagram: socialLinks?.instagram,
      twitter: socialLinks?.twitter,
      youtube: socialLinks?.youtube,
      vimeo: socialLinks?.vimeo,
      tiktok: socialLinks?.tikTok,
      user_id: userId ? JSON.parse(userId) : "",
      profile_id: profileId ? JSON.parse(profileId) : "",
      title: clientDetails?.title,
      business_email: clientDetails?.businessEmail,
      business_website: clientDetails?.businessWebsite,
      business_since: clientDetails?.businessSince,
      description: clientDetails?.description,
    };

    let res = await dispatch(userRegister(body, PARTNER_UPDATE));

    if (res?.status == 200) {
      // setEditSecStep(false);
      // showToast("Saved !", "success");
      setError(false);
      setValidated(false);
      setSteps(3);
      window.scrollTo(0, 0);

      // setEditDetail(falses);
    }
  };

  const handlePhotographerRegisteration = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    setError(true);
    var body = {
      user_type: "photographer",
      pre: "update",
      per_website: "",
      email: email,
      model_type:
        talent == "Model" || talent == "model"
          ? "model"
          : talent == "Model Kid" || talent == "Actor Kid" || talent == "child"
            ? "child"
            : "",
      password: password,
      address_line1: address?.addressLine,
      avatar:
        "https://booksculp.com/dev/wp-content/uploads/workreap-temp/00002-2.jpg",

      first_name: basicDetails?.fname,
      last_name: basicDetails?.lname,
      display_name: basicDetails?.displayName,

      gender:
        gender == "Male" || gender == "male"
          ? "male"
          : gender == "Female" || gender == "female"
            ? "female"
            : gender == "Non Binary" ||
              gender == "non binary" ||
              gender == "other"
              ? "other"
              : "",
      country: country,
      state: state,
      city: city,

      skills: mySkill,
      custom_skills: customSkills,
      dob: moment(basicDetails?.birthDate).format("YYYY-MM-DD"),
      tageline: basicDetails?.tagLine,

      expertise: expertise,
      custom_expertise: basicDetails?.customExpertise,
      experiences_level: experience,

      full_day_rate: basicDetails?.fullRate,
      half_day_rate: basicDetails?.halfRate,
      hourly_rate: basicDetails?.hourlyRate,

      mobile: basicDetails?.mobileNumber,
      pack_status: "basic",

      personal_bio: bioDetails?.personalBio,
      professional_bio: bioDetails?.professionalBio,
      postal_code: address?.postalCode,
      years_experience: basicDetails?.yearsExperience,
      camera_type: "dxsdxcsc",
      retouch_concent: "cdscdsc",
      listed_with_booksculp: "cdscsc",

      facebook_followers: facebookFollowers,
      facebook_link: socialLinks?.facebook,

      instagram_followers: instagramFollowers,
      instagram_link: socialLinks?.instagram,

      youtube_followers: youtubeFollowers,
      youtube_link: socialLinks?.youtube,

      twitter_followers: twitterFollowers,
      twitter_link: socialLinks?.twitter,

      vimeo_followers: vimeoFollowers,
      vimeo_link: socialLinks?.vimeo,

      tiktok_followers: tiktokFollowers,
      tiktok_link: socialLinks?.tikTok,
      user_id: userId ? JSON.parse(userId) : "",
      profile_id: profileId ? JSON.parse(profileId) : "",
    };
    let res = await dispatch(updatePhotographerProfile(body));
    console.log(res, "resresresres");
    if (res?.status == 200) {
      setError(false);
      setValidated(false);
      setSteps(3);
      setHeaderLoad(true);
      setTimeout(() => {
        setHeaderLoad(false);
      }, 3000);
      window.scrollTo(0, 0);
    }
  };
  return (
    <>
      <Loader loading={laoding?.isLoading} />
      <Header load={headerLoad} />

      <div className="pageTitle">
        <div className="wt-title">
          <h1>Edit Profile</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <a>Home</a>
            </li>
            <li className="last-item">Edit Profile</li>
          </ol>
        </div>
      </div>

      <Container>
        <div className="registerForm full-size">
          <div className="verification_contain_form wt-email-cont">
            <Form noValidate validated={validated} onSubmit={handleForm}>
              <div class="reg_application">
                <div class="form-section">
                  <div className="form-section-title">Your basics</div>
                  <div class="form-group form-group-50">
                    {talent != "Client" && talent != "Partners" ? (
                      <>
                        <InputField
                          required
                          title="Talent Type"
                          placeholder="Select User Type"
                          type="text"
                          value={talent}
                          onChangeText={(e) => {
                            setTalent(e?.target?.value);
                          }}
                          disabled={true}
                        />

                        <DropdownList
                          required
                          title="Gender"
                          placeholder="Select Gender"
                          options={
                            talent == "kid" ||
                              talent == "Model Kid" ||
                              talent == "Actor Kid" ||
                              talent == "child"
                              ? kidGenderTypes
                              : genderTypes
                          }
                          value={gender}
                          setValue={setGender}
                          disabled={true}
                        />
                      </>
                    ) : null}

                    <InputField
                      required
                      title="First name"
                      type="text"
                      placeholder="First name"
                      value={basicDetails?.fname}
                      onChangeText={(e) => {
                        setBasicDetails({
                          ...basicDetails,
                          fname: e?.target?.value,
                        });
                      }}
                    />
                    <InputField
                      required
                      title="Last name"
                      type="text"
                      placeholder="Last name"
                      value={basicDetails?.lname}
                      onChangeText={(e) => {
                        setBasicDetails({
                          ...basicDetails,
                          lname: e?.target?.value,
                        });
                      }}
                    />
                    <InputField
                      required
                      title="Display name"
                      type="text"
                      placeholder="Display name"
                      value={basicDetails?.displayName}
                      onChangeText={(e) => {
                        setBasicDetails({
                          ...basicDetails,
                          displayName: e?.target?.value,
                        });
                      }}
                      toolTipText={
                        talent === "Client"
                          ? "This will be your display name on the site"
                          : ""
                      }
                    />

                    {talent == "Model Kid" ||
                      talent == "Actor Kid" ||
                      talent == "child" ? (
                      <>
                        <InputField
                          required
                          title="Guardian Name"
                          type="text"
                          placeholder="Guardian Name"
                          value={basicDetails?.guardianName}
                          onChangeText={(e) => {
                            setBasicDetails({
                              ...basicDetails,
                              guardianName: e?.target?.value,
                            });
                          }}
                        />
                        <InputField
                          required
                          title="Guardian phone number"
                          type="number"
                          placeholder="Guardian phone number"
                          value={basicDetails?.mobileNumber}
                          onChangeText={(e) => {
                            setBasicDetails({
                              ...basicDetails,
                              mobileNumber: e?.target?.value,
                            });
                          }}
                        />
                      </>
                    ) : (
                      <InputField
                        required
                        title="Phone number"
                        type="number"
                        placeholder="Phone number"
                        value={basicDetails?.mobileNumber}
                        onChangeText={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            mobileNumber: e?.target?.value,
                          });
                        }}
                      />
                    )}

                    {talent === "Actor" ? (
                      <DropdownList
                        title="Relationship Status"
                        placeholder="Select Status"
                        options={
                          optionsType?.actorRelation?.relationship_status
                            ?.field_meta?.choices
                        }
                        value={relationStatus}
                        setValue={setRelationStatus}
                      />
                    ) : undefined}

                    {talent == "Client" ||
                      talent == "Partners" ||
                      talent == "Photographer" ? (
                      <InputField
                        title="Tagline"
                        type="text"
                        placeholder="Tagline"
                        value={basicDetails?.tagLine}
                        onChangeText={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            tagLine: e?.target?.value,
                          });
                        }}
                        toolTipText={
                          "This is a quick line to help promote you. It will be shown on your profile page. Ex. Outgoing and Friendly"
                        }
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div style={{ height: 20 }} />
              <div class="reg_application">
                <div class="form-section">
                  <div className="form-section-title">
                    {talent == "Client" || talent == "Partners"
                      ? "Company Info"
                      : talent == "Photographer"
                        ? "Photographer Details"
                        : talent == "Model Kid"
                          ? "Model Kid Details"
                          : talent == "Actor"
                            ? "Actor Details"
                            : talent == "Actor Kid"
                              ? "Actor Kid Details"
                              : "Model Details"}
                  </div>

                  <div class="form-group form-group-50">
                    {talent == "Client" || talent == "Partners" ? (
                      <>
                        <InputField
                          required
                          title="Organization"
                          type="text"
                          value={clientDetails?.organization}
                          placeholder="Organization"
                          onChangeText={(e) => {
                            setClientDetails({
                              ...clientDetails,
                              organization: e?.target?.value,
                            });
                          }}
                        />
                        <InputField
                          title={talent == "Partners" ? "Job Title" : "Title"}
                          type="text"
                          value={clientDetails?.title}
                          placeholder={
                            talent == "Partners" ? "Job Title" : "Title"
                          }
                          onChangeText={(e) => {
                            setClientDetails({
                              ...clientDetails,
                              title: e?.target?.value,
                            });
                          }}
                          toolTipText={
                            talent === "Partners"
                              ? "Add your Business Title Here."
                              : "This is your job title for the company."
                          }
                        />
                        <InputField
                          required
                          title="Business Email Address"
                          type="email"
                          value={clientDetails?.businessEmail}
                          placeholder="Business Email Address"
                          onChangeText={(e) => {
                            setClientDetails({
                              ...clientDetails,
                              businessEmail: e?.target?.value,
                            });
                          }}
                        />
                        <InputField
                          required
                          title="Business website"
                          type="text"
                          value={clientDetails?.businessWebsite}
                          placeholder="Business website"
                          onChangeText={(e) => {
                            setClientDetails({
                              ...clientDetails,
                              businessWebsite: e?.target?.value,
                            });
                          }}
                        />
                        <InputField
                          title="We have been in business since"
                          type="number"
                          value={clientDetails?.businessSince}
                          placeholder="We have been in business since"
                          onChangeText={(e) => {
                            setClientDetails({
                              ...clientDetails,
                              businessSince: e?.target?.value,
                            });
                          }}
                        />
                        <div className="fullCol">
                          <InputField
                            required
                            title="Brief description"
                            type="textarea"
                            value={clientDetails?.description}
                            placeholder="Brief description"
                            onChangeText={(e) => {
                              setClientDetails({
                                ...clientDetails,
                                description: e?.target?.value,
                              });
                            }}
                          />
                        </div>
                      </>
                    ) : talent == "Photographer" ? (
                      <>
                        <InputField
                          required
                          type="number"
                          value={basicDetails?.hourlyRate}
                          title="Hourly Rate"
                          placeholder="Hourly Rate"
                          onChangeText={(e) =>
                            setBasicDetails({
                              ...basicDetails,
                              hourlyRate: e?.target?.value,
                            })
                          }
                          toolTipText={
                            talent === "Actor Kid" || talent === "Model Kid"
                              ? "Add your per hour rate suggested rate is $75"
                              : "Add your per hour rate suggested rate is $150"
                          }
                        />
                        <InputField
                          required
                          type="number"
                          value={basicDetails?.halfRate}
                          title="Half Day Rate"
                          placeholder="Half Day Rate"
                          onChangeText={(e) =>
                            setBasicDetails({
                              ...basicDetails,
                              halfRate: e?.target?.value,
                            })
                          }
                        />
                        <InputField
                          required
                          type="number"
                          value={basicDetails?.fullRate}
                          title="Full Day Rate"
                          placeholder="Full Day Rate"
                          onChangeText={(e) =>
                            setBasicDetails({
                              ...basicDetails,
                              fullRate: e?.target?.value,
                            })
                          }
                        />
                      </>
                    ) : (
                      <>
                        <InputField
                          required
                          type="number"
                          value={basicDetails?.hourlyRate}
                          title="Hourly Rate"
                          placeholder="Hourly Rate"
                          onChangeText={(e) =>
                            setBasicDetails({
                              ...basicDetails,
                              hourlyRate: e?.target?.value,
                            })
                          }
                          toolTipText={
                            talent === "Actor Kid" || talent === "Model Kid"
                              ? "Add your per hour rate suggested rate is $75"
                              : "Add your per hour rate suggested rate is $150"
                          }
                        />

                        <InputField
                          required
                          type="text"
                          value={basicDetails?.age}
                          title={
                            talent === "Actor" || talent === "Actor Kid"
                              ? "Age"
                              : "Model Age"
                          }
                          placeholder="Age"
                          onChangeText={(e) =>
                            setBasicDetails({
                              ...basicDetails,
                              age: e?.target?.value,
                            })
                          }
                          disabled={true}
                        />

                        <InputField
                          title="Tagline"
                          type="text"
                          placeholder="Tagline"
                          value={basicDetails?.tagLine}
                          onChangeText={(e) => {
                            setBasicDetails({
                              ...basicDetails,
                              tagLine: e?.target?.value,
                            });
                          }}
                          toolTipText={
                            "This is a quick line to help promote you. It will be shown on your profile page. Ex. Outgoing and Friendly"
                          }
                        />
                      </>
                    )}

                    <DropdownList
                      required
                      title="Country"
                      placeholder="Select Country"
                      options={countryOptions}
                      value={country}
                      setValue={setCountry}
                      onSelect={() => {
                        // getAllCountryName("state");
                        setState("");
                        setCity("");
                      }}
                      type="country"
                    />
                    <DropdownList
                      required
                      title="State"
                      placeholder={
                        stateOptions?.length != 0
                          ? "Select State"
                          : "State not found !"
                      }
                      options={stateOptions}
                      value={state}
                      setValue={setState}
                      disabled={country ? false : true}
                      onSelect={() => {
                        getAllCityName();
                        setCity("");
                        setCityOptions([]);
                      }}
                      type="state"
                    />

                    <DropdownList
                      required={cityOptions?.length ? true : false}
                      title="City"
                      placeholder={
                        stateOptions?.length != 0 || cityOptions?.length != 0
                          ? "Select City"
                          : "City not found !"
                      }
                      options={cityOptions}
                      value={city}
                      setValue={setCity}
                      disabled={country && state ? false : true}
                    />
                    {/* <DropdownList
                      required
                      title="City"
                      options={location?.cityList}
                      value={state}
                      setValue={setCity}
                      placeholder={
                        stateOptions?.length != 0 ||
                        location?.cityList?.length != 0
                          ? "Select City"
                          : "City not found !"
                      }
                      disabled={country && state ? false : true}
                    /> */}
                    <InputField
                      required
                      title="Address line"
                      type="text"
                      placeholder="Enter Address line"
                      value={address?.addressLine}
                      onChangeText={(e) => {
                        setAddress({
                          ...address,
                          addressLine: e?.target?.value,
                        });
                      }}
                    />

                    {talent === "Photographer" ? undefined : (
                      <InputField
                        title="Address line 2"
                        type="text"
                        placeholder="Enter Address line 2"
                        value={address?.addressLine2}
                        onChangeText={(e) => {
                          setAddress({
                            ...address,
                            addressLine2: e?.target?.value,
                          });
                        }}
                      />
                    )}
                    <InputField
                      required
                      title="Postal Code"
                      type="number"
                      placeholder="Enter Postal Code"
                      value={address?.postalCode}
                      onChangeText={(e) => {
                        setAddress({
                          ...address,
                          postalCode: e?.target?.value,
                        });
                      }}
                    />
                  </div>
                </div>
                {talent != "Client" &&
                  talent != "Photographer" &&
                  talent != "Partners" ? (
                  <>
                    <div class="form-section">
                      <div className="form-section-title">
                        Languages you can speak
                      </div>
                      <div class="form-group form-group-50">
                        <MultiSelectDropdown
                          required
                          title="Select Languages"
                          options={
                            optionsType?.languages?.length
                              ? optionsType?.languages?.map(({ name, id }) => ({
                                  label: name,
                                  value: id,
                                }))
                              : []
                          }
                          value={language}
                          setValue={setLanguage}
                          isValid={error && language?.length == 0}
                        />
                        <DropdownList
                          required
                          title="Language Level"
                          placeholder="Select Language Level"
                          options={optionsType?.englishLevel}
                          value={englishLevel}
                          setValue={setEnglishLevel}
                        />
                      </div>
                    </div>

                    <div class="form-section">
                      <div className="form-section-title">
                        {talent === "Actor"
                          ? "Actor Details"
                          : talent === "Actor Kid"
                            ? "Actor Kid Details"
                            : "Model Details"}
                      </div>
                      <div class="form-group form-group-50">
                        {talent === "Actor" ||
                          talent === "Actor Kid" ? undefined : (
                          <InputField
                            required
                            title="Professional Bio"
                            type="textarea"
                            placeholder="Professional Bio"
                            value={bioDetails?.professionalBio}
                            onChangeText={(e) => {
                              setBioDetails({
                                ...bioDetails,
                                professionalBio: e?.target?.value,
                              });
                            }}
                          />
                        )}
                        {talent === "Actor" ||
                          talent === "Actor Kid" ? undefined : (
                          <InputField
                            required
                            title="Personal bio"
                            type="textarea"
                            placeholder="Personal bio"
                            value={bioDetails?.personalBio}
                            onChangeText={(e) => {
                              setBioDetails({
                                ...bioDetails,
                                personalBio: e?.target?.value,
                              });
                            }}
                          />
                        )}
                        <DropdownList
                          required
                          title="Height (Inches)"
                          placeholder="Select Height"
                          options={
                            optionsType?.otherOptions?.height?.field_meta
                              ?.choices
                          }
                          value={height}
                          setValue={setHeight}
                        />
                        <DropdownList
                          required
                          title="Weight (Pounds)"
                          placeholder="Select Weight"
                          options={
                            optionsType?.otherOptions?.weight?.field_meta
                              ?.choices
                          }
                          value={weight}
                          setValue={setWeight}
                        />

                        <MultiSelectDropdown
                          required
                          title="Select Ethnicity"
                          placeholder="Select Ethnicity"
                          options={
                            optionsType?.otherOptions?.ethnicity?.field_meta
                              ?.choices
                          }
                          value={ethnicity}
                          setValue={setEthnicity}
                          isValid={error && ethnicity?.length == 0}
                        />
                        <DropdownList
                          required
                          title="Hair color"
                          placeholder="Select Hair color"
                          options={
                            optionsType?.otherOptions?.hair_colour?.field_meta
                              ?.choices
                          }
                          value={hairColor}
                          setValue={setHairColor}
                        />
                        <DropdownList
                          required
                          title="Eye color"
                          placeholder="Select Eye color"
                          options={
                            optionsType?.otherOptions?.eye_color?.field_meta
                              ?.choices
                          }
                          value={eyeColor}
                          setValue={setEyeColor}
                        />
                        <DropdownList
                          required
                          title="Experience level"
                          placeholder="Select Experience level"
                          options={
                            optionsType?.otherOptions?.experience_level
                              ?.field_meta?.choices
                          }
                          value={experience}
                          setValue={setExperience}
                        />
                        <MultiSelectDropdown
                          required
                          title="My Skills"
                          placeholder="Select Skills"
                          options={
                            optionsType?.otherOptions?.add_new_skill?.field_meta
                              ?.choices
                          }
                          value={mySkill}
                          setValue={setMySkill}
                          isValid={error && mySkill?.length == 0}
                        />
                        {talent === "Actor" ||
                          talent === "Actor Kid" ? undefined : (
                          <InputField
                            // required
                            title="Custom Skills"
                            type="text"
                            placeholder="Add Custom Skills. eg. Baseball, Football..."
                            value={customSkills}
                            onChangeText={(e) => {
                              setCustomSkills(e?.target?.value);
                            }}
                          />
                        )}
                        {talent === "Actor" ? (
                          <>
                            <InputField
                              // required
                              title="Tattoo/Design/Location"
                              type="text"
                              placeholder="Add Tattoo/Design/Location."
                              value={tattooLoc}
                              onChangeText={(e) => {
                                setTattoLoc(e?.target?.value);
                              }}
                            />
                          </>
                        ) : undefined}
                        {talent === "Actor" || talent === "Actor Kid" ? (
                          <>
                            <InputField
                              // required
                              title="Member of any union"
                              type="text"
                              placeholder="Add Member of any union eg. SAG/AFTRA"
                              value={memberOf}
                              onChangeText={(e) => {
                                setMemberOf(e?.target?.value);
                              }}
                            />
                          </>
                        ) : undefined}
                      </div>
                    </div>

                    {talent === "Actor" || talent === "Actor Kid" ? (
                      <div class="form-section">
                        <div className="form-section-title">
                          {talent == "Actor" || talent === "Actor Kid"
                            ? "Experience"
                            : ""}
                        </div>
                        {talent == "Actor" || talent === "Actor Kid" ? (
                          <div class="form-group form-group-50">
                            <InputField
                              required
                              title="Professional Bio"
                              type="textarea"
                              placeholder="Professional Bio"
                              value={bioDetails?.professionalBio}
                              onChangeText={(e) => {
                                setBioDetails({
                                  ...bioDetails,
                                  professionalBio: e?.target?.value,
                                });
                              }}
                            />
                            <InputField
                              required
                              title="Personal bio"
                              type="textarea"
                              placeholder="Personal bio"
                              value={bioDetails?.personalBio}
                              onChangeText={(e) => {
                                setBioDetails({
                                  ...bioDetails,
                                  personalBio: e?.target?.value,
                                });
                              }}
                            />

                            <InputField
                              title="Education or Training"
                              type="textarea"
                              placeholder="Education or Training"
                              value={educationTraining}
                              onChangeText={(e) => {
                                setEducationTraining(e?.target?.value);
                              }}
                            />

                            <InputField
                              title="Theater experience"
                              type="textarea"
                              placeholder="Theater experience"
                              value={theaterExp}
                              onChangeText={(e) => {
                                setTheaterExp(e?.target?.value);
                              }}
                            />

                            <InputField
                              title="Awards"
                              type="textarea"
                              placeholder="Awards"
                              value={awards}
                              onChangeText={(e) => {
                                setAwards(e?.target?.value);
                              }}
                            />

                            <InputField
                              title="Commercial experience"
                              type="textarea"
                              placeholder="Commercial experience"
                              value={commercialExp}
                              onChangeText={(e) => {
                                setCommercialExp(e?.target?.value);
                              }}
                            />
                            <InputField
                              // require  d
                              title="Special Skills"
                              type="textarea"
                              placeholder="Add Custom Skills. eg. Baseball, Football..."
                              value={customSkills}
                              onChangeText={(e) => {
                                setCustomSkills(e?.target?.value);
                              }}
                            />
                          </div>
                        ) : undefined}
                      </div>
                    ) : undefined}

                    {(talent == "Actor" || talent === "Actor Kid") && gender ? (
                      <div class="form-section">
                        <div className="form-section-title">
                          {talent == "Actor Kid" &&
                            (basicDetails?.age <= 2 ||
                              basicDetails?.age?.includes("M") ||
                              basicDetails?.age?.includes("Month"))
                            ? "Measurements"
                            : talent == "Actor Kid" &&
                              basicDetails?.age > 2 &&
                              basicDetails?.age <= 5
                              ? "Measurements"
                              : talent == "Actor Kid" &&
                                basicDetails?.age > 5 &&
                                basicDetails?.age <= 13
                                ? "Measurements"
                                : talent == "Actor"
                                  ? gender == "Male" || gender == "male"
                                    ? "Male Actor Measurement"
                                    : gender == "Female" || gender == "female"
                                      ? "Female Actor Measurement"
                                      : "Actor Measurement For Non Binary"
                                  : "Actor Measurement"}
                        </div>
                        <div class="form-group form-group-50">
                          {talent == "Actor Kid" &&
                            (basicDetails?.age <= 13 ||
                              basicDetails?.age?.includes("M") ||
                              basicDetails?.age?.includes("Month")) ? (
                            <>
                              <MultiSelectDropdown
                                required
                                options={
                                  optionsType?.childOptions?.toddler_shirt_size
                                    ?.field_meta?.choices
                                }
                                title="Shirt size"
                                placeholder="Select Shirt size"
                                value={shirtSizeC}
                                setValue={setShirtSizeC}
                                isValid={error && shirtSizeC?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title="Pant Size"
                                placeholder="Select Pant Size"
                                value={pantSizeWC}
                                setValue={setPantSizeWC}
                                options={
                                  optionsType?.childOptions?.toddler_pant_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSizeWC?.length == 0}
                              />

                              <MultiSelectDropdown
                                required
                                title="Shoe Size"
                                placeholder="Select Shoe Size"
                                value={shoeSizeC}
                                setValue={setShoeSizeC}
                                options={
                                  optionsType?.childOptions?.toddler_shoe_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && shoeSizeC?.length == 0}
                              />
                              {gender == "Female" || gender == "female" ? (
                                <MultiSelectDropdown
                                  required
                                  title={"Dress Size"}
                                  placeholder={"Select Dress Size"}
                                  value={dressSizeC}
                                  setValue={setDressSizeC}
                                  options={
                                    optionsType?.childOptions
                                      ?.toddler_dress_size?.field_meta?.choices
                                  }
                                  isValid={error && dressSizeC?.length == 0}
                                />
                              ) : null}
                            </>
                          ) : gender == "Male" || gender == "male" ? (
                            <>
                              <MultiSelectDropdown
                                required
                                title={"Shirt Size"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSize}
                                setValue={setShirtSize}
                                options={
                                  optionsType?.maleOptions?.shirt_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && shirtSize?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title={"Pant Size (Waist)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeW}
                                setValue={setPantSizeW}
                                options={
                                  optionsType?.maleOptions?.pant_size_waist
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSizeW?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title={"Pant Size (Length)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeL}
                                setValue={setPantSizeL}
                                options={
                                  optionsType?.maleOptions?.pant_size_length
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSizeL?.length == 0}
                              />

                              <MultiSelectDropdown
                                required
                                title={"Shoe Size"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSize}
                                setValue={setShoeSize}
                                options={
                                  optionsType?.maleOptions?.shoe_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && shoeSize?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Dress Shirt Size"
                                placeholder="Select Dress Shirt Size"
                                value={dressShirt}
                                setValue={setDressShirt}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && dressShirt?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Dress Shirt Sleeve"
                                placeholder="Select Dress Shirt Sleeve"
                                value={shirtSleeve}
                                setValue={setShirtSleeve}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_sleeve
                                    ?.field_meta?.choices
                                }
                                isValid={error && shirtSleeve?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Neck Size"
                                placeholder="Select Neck Size"
                                value={neckSize}
                                setValue={setNeckSize}
                                options={
                                  optionsType?.maleOptions?.neck_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && neckSize?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Chest Size"
                                placeholder="Select Chest Size"
                                value={chestSize}
                                setValue={setChestSize}
                                options={
                                  optionsType?.maleOptions?.chest_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && chestSize?.length == 0}
                              />
                              <DropdownList
                                required
                                title="Jacket"
                                placeholder="Select Jacket"
                                value={jacket}
                                setValue={setJacket}
                                options={
                                  optionsType?.maleOptions?.jacket?.field_meta
                                    ?.choices
                                }
                                isValid={error && jacket?.length == 0}
                              />
                            </>
                          ) : gender == "Female" || gender == "female" ? (
                            <>
                              <MultiSelectDropdown
                                required
                                title={"Shirt Size"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSizeF}
                                setValue={setShirtSizeF}
                                options={
                                  optionsType?.femaleOptions?.shirt_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && shirtSizeF?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title={"Pant Size"}
                                placeholder={"Select Pant Size"}
                                value={pantSize}
                                setValue={setPantSize}
                                options={
                                  optionsType?.femaleOptions?.pant_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSize?.length == 0}
                              />
                              <div className="fullCol">
                                <span>
                                  Bra size and Bra cup will only be visible to
                                  clients.
                                </span>
                              </div>
                              <DropdownList
                                required
                                title="Bra Cup"
                                placeholder="Select Bra Cup"
                                value={braCup}
                                setValue={setBraCup}
                                options={
                                  optionsType?.femaleOptions?.bra_cup
                                    ?.field_meta?.choices
                                }
                                isValid={error && braCup?.length == 0}
                              />
                              <DropdownList
                                required
                                title="Bra Size"
                                placeholder="Select Bra Size"
                                value={braSize}
                                setValue={setBraSize}
                                options={
                                  optionsType?.femaleOptions?.bra_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && braSize?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Dress Size"
                                placeholder="Select Dress Size"
                                value={dressSize}
                                setValue={setDressSize}
                                options={
                                  optionsType?.femaleOptions?.dress_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && dressSize?.length == 0}
                              />

                              <MultiSelectDropdown
                                required
                                title={"Shoe Size"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSizeF}
                                setValue={setShoeSizeF}
                                options={
                                  optionsType?.femaleOptions?.shoe_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && shoeSizeF?.length == 0}
                              />
                            </>
                          ) : (
                            <>
                              <MultiSelectDropdown
                                title={"Shirt Size (M)"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSize}
                                setValue={setShirtSize}
                                options={
                                  optionsType?.maleOptions?.shirt_size
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Pant Size (Waist)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeW}
                                setValue={setPantSizeW}
                                options={
                                  optionsType?.maleOptions?.pant_size_waist
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Pant Size (Length)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeL}
                                setValue={setPantSizeL}
                                options={
                                  optionsType?.maleOptions?.pant_size_length
                                    ?.field_meta?.choices
                                }
                              />

                              <MultiSelectDropdown
                                title={"Shoe Size (M)"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSize}
                                setValue={setShoeSize}
                                options={
                                  optionsType?.maleOptions?.shoe_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Dress Shirt Size (M)"
                                placeholder="Select Dress Shirt Size"
                                value={dressShirt}
                                setValue={setDressShirt}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Dress Shirt Sleeve (M)"
                                placeholder="Select Dress Shirt Sleeve"
                                value={shirtSleeve}
                                setValue={setShirtSleeve}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_sleeve
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Neck Size (M)"
                                placeholder="Select Neck Size"
                                value={neckSize}
                                setValue={setNeckSize}
                                options={
                                  optionsType?.maleOptions?.neck_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Chest Size (M)"
                                placeholder="Select Chest Size"
                                value={chestSize}
                                setValue={setChestSize}
                                options={
                                  optionsType?.maleOptions?.chest_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Jacket (M)"
                                placeholder="Select Jacket"
                                value={jacket}
                                setValue={setJacket}
                                options={
                                  optionsType?.maleOptions?.jacket?.field_meta
                                    ?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Shirt Size (F)"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSizeF}
                                setValue={setShirtSizeF}
                                options={
                                  optionsType?.femaleOptions?.shirt_size_f
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Pant Size (F)"}
                                placeholder={"Select Pant Size"}
                                value={pantSize}
                                setValue={setPantSize}
                                options={
                                  optionsType?.femaleOptions?.pant_size_f
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Shoe Size (F)"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSizeF}
                                setValue={setShoeSizeF}
                                options={
                                  optionsType?.femaleOptions?.shoe_size_f
                                    ?.field_meta?.choices
                                }
                              />
                              <div className="fullCol">
                                <span>
                                  Bra size and Bra cup will only be visible to
                                  clients.
                                </span>
                              </div>
                              <DropdownList
                                title="Bra Cup (F)"
                                placeholder="Select Bra Cup"
                                value={braCup}
                                setValue={setBraCup}
                                options={
                                  optionsType?.femaleOptions?.bra_cup
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Bra Size (F)"
                                placeholder="Select Bra Size"
                                value={braSize}
                                setValue={setBraSize}
                                options={
                                  optionsType?.femaleOptions?.bra_size
                                    ?.field_meta?.choices
                                }
                              />
                              <DropdownList
                                title="Dress Size (F)"
                                placeholder="Select Dress Size"
                                value={dressSize}
                                setValue={setDressSize}
                                options={
                                  optionsType?.femaleOptions?.dress_size_f
                                    ?.field_meta?.choices
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    ) : null}

                    {(talent == "Model" && gender) ||
                      (talent == "model" && gender) ||
                      (talent == "Model Kid" && gender) ||
                      (talent == "kid" && gender) ||
                      (talent == "child" && gender) ? (
                      <div class="form-section">
                        <div className="form-section-title">
                          {(talent == "Model Kid" &&
                            (basicDetails?.age <= 2 ||
                              basicDetails?.age?.includes("M") ||
                              basicDetails?.age?.includes("Month"))) ||
                            (talent == "kid" && basicDetails?.age <= 2) ||
                            (talent == "child" &&
                              (basicDetails?.age <= 2 ||
                                basicDetails?.age?.includes("M") ||
                                basicDetails?.age?.includes("Month")))
                            ? "Measurements"
                            : talent == "Model Kid" ||
                              talent == "kid" ||
                              (talent == "child" &&
                                basicDetails?.age > 2 &&
                                basicDetails?.age <= 5)
                              ? "Measurements"
                              : talent == "Model Kid" ||
                                talent == "kid" ||
                                (talent == "child" &&
                                  basicDetails?.age > 5 &&
                                  basicDetails?.age <= 13)
                                ? "Measurements"
                                : talent == "Model" || talent == "model"
                                  ? gender == "Male" || gender == "male"
                                    ? "Male Model Measurement"
                                    : gender == "Female" || gender == "female"
                                      ? "Female Model Measurement"
                                      : "Model Measurement For Non Binary"
                                  : "Model Measurement"}
                        </div>
                        <div class="form-group form-group-50">
                          {talent == "Model Kid" ||
                            talent == "Kid" ||
                            talent == "child" ? (
                            <>
                              <MultiSelectDropdown
                                required
                                options={
                                  optionsType?.childOptions?.toddler_shirt_size
                                    ?.field_meta?.choices
                                }
                                title="Shirt size"
                                placeholder="Select Shirt size"
                                value={shirtSizeC}
                                setValue={setShirtSizeC}
                                isValid={error && shirtSizeC?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title="Pant Size"
                                placeholder="Select Pant Size"
                                value={pantSizeWC}
                                setValue={setPantSizeWC}
                                options={
                                  optionsType?.childOptions?.toddler_pant_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSizeWC?.length == 0}
                              />

                              <MultiSelectDropdown
                                required
                                title="Shoe Size"
                                placeholder="Select Shoe Size"
                                value={shoeSizeC}
                                setValue={setShoeSizeC}
                                options={
                                  optionsType?.childOptions?.toddler_shoe_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && shoeSizeC?.length == 0}
                              />
                              {gender == "Female" || gender == "female" ? (
                                <MultiSelectDropdown
                                  required
                                  title={"Dress Size"}
                                  placeholder={"Select Dress Size"}
                                  value={dressSizeC}
                                  setValue={setDressSizeC}
                                  options={
                                    optionsType?.childOptions
                                      ?.toddler_dress_size?.field_meta?.choices
                                  }
                                  isValid={error && dressSizeC?.length == 0}
                                />
                              ) : null}
                            </>
                          ) : gender == "Male" || gender == "male" ? (
                            <>
                              <MultiSelectDropdown
                                required
                                title={"Shirt Size"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSize}
                                setValue={setShirtSize}
                                options={
                                  optionsType?.maleOptions?.shirt_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && shirtSize?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title={"Pant Size (Waist)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeW}
                                setValue={setPantSizeW}
                                options={
                                  optionsType?.maleOptions?.pant_size_waist
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSizeW?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title={"Pant Size (Length)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeL}
                                setValue={setPantSizeL}
                                options={
                                  optionsType?.maleOptions?.pant_size_length
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSizeL?.length == 0}
                              />

                              <MultiSelectDropdown
                                required
                                title={"Shoe Size"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSize}
                                setValue={setShoeSize}
                                options={
                                  optionsType?.maleOptions?.shoe_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && shoeSize?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Dress Shirt Size"
                                placeholder="Select Dress Shirt Size"
                                value={dressShirt}
                                setValue={setDressShirt}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && dressShirt?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Dress Shirt Sleeve"
                                placeholder="Select Dress Shirt Sleeve"
                                value={shirtSleeve}
                                setValue={setShirtSleeve}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_sleeve
                                    ?.field_meta?.choices
                                }
                                isValid={error && shirtSleeve?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Neck Size"
                                placeholder="Select Neck Size"
                                value={neckSize}
                                setValue={setNeckSize}
                                options={
                                  optionsType?.maleOptions?.neck_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && neckSize?.length == 0}
                              />

                              <DropdownList
                                required
                                title="Chest Size"
                                placeholder="Select Chest Size"
                                value={chestSize}
                                setValue={setChestSize}
                                options={
                                  optionsType?.maleOptions?.chest_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && chestSize?.length == 0}
                              />
                              <DropdownList
                                required
                                title="Jacket"
                                placeholder="Select Jacket"
                                value={jacket}
                                setValue={setJacket}
                                options={
                                  optionsType?.maleOptions?.jacket?.field_meta
                                    ?.choices
                                }
                                isValid={error && jacket?.length == 0}
                              />
                            </>
                          ) : gender == "Female" || gender == "female" ? (
                            <>
                              <MultiSelectDropdown
                                required
                                title={"Shirt Size"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSizeF}
                                setValue={setShirtSizeF}
                                options={
                                  optionsType?.femaleOptions?.shirt_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && shirtSizeF?.length == 0}
                              />
                              <MultiSelectDropdown
                                required
                                title={"Pant Size"}
                                placeholder={"Select Pant Size"}
                                value={pantSize}
                                setValue={setPantSize}
                                options={
                                  optionsType?.femaleOptions?.pant_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && pantSize?.length == 0}
                              />
                              <div className="fullCol">
                                <span>
                                  Bra size and Bra cup will only be visible to
                                  clients.
                                </span>
                              </div>
                              <DropdownList
                                required
                                title="Bra Cup"
                                placeholder="Select Bra Cup"
                                value={braCup}
                                setValue={setBraCup}
                                options={
                                  optionsType?.femaleOptions?.bra_cup
                                    ?.field_meta?.choices
                                }
                                isValid={error && braCup?.length == 0}
                              />
                              <DropdownList
                                required
                                title="Bra Size"
                                placeholder="Select Bra Size"
                                value={braSize}
                                setValue={setBraSize}
                                options={
                                  optionsType?.femaleOptions?.bra_size
                                    ?.field_meta?.choices
                                }
                                isValid={error && braSize?.length == 0}
                              />
                              <DropdownList
                                required
                                title="Dress Size"
                                placeholder="Select Dress Size"
                                value={dressSize}
                                setValue={setDressSize}
                                options={
                                  optionsType?.femaleOptions?.dress_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && dressSize?.length == 0}
                              />

                              <MultiSelectDropdown
                                required
                                title={"Shoe Size"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSizeF}
                                setValue={setShoeSizeF}
                                options={
                                  optionsType?.femaleOptions?.shoe_size_f
                                    ?.field_meta?.choices
                                }
                                isValid={error && shoeSizeF?.length == 0}
                              />
                            </>
                          ) : (
                            <>
                              <MultiSelectDropdown
                                title={"Shirt Size (M)"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSize}
                                setValue={setShirtSize}
                                options={
                                  optionsType?.maleOptions?.shirt_size
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Pant Size (Waist)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeW}
                                setValue={setPantSizeW}
                                options={
                                  optionsType?.maleOptions?.pant_size_waist
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Pant Size (Length)"}
                                placeholder={"Select Pant Size"}
                                value={pantSizeL}
                                setValue={setPantSizeL}
                                options={
                                  optionsType?.maleOptions?.pant_size_length
                                    ?.field_meta?.choices
                                }
                              />

                              <MultiSelectDropdown
                                title={"Shoe Size (M)"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSize}
                                setValue={setShoeSize}
                                options={
                                  optionsType?.maleOptions?.shoe_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Dress Shirt Size (M)"
                                placeholder="Select Dress Shirt Size"
                                value={dressShirt}
                                setValue={setDressShirt}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Dress Shirt Sleeve (M)"
                                placeholder="Select Dress Shirt Sleeve"
                                value={shirtSleeve}
                                setValue={setShirtSleeve}
                                options={
                                  optionsType?.maleOptions?.dress_shirt_sleeve
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Neck Size (M)"
                                placeholder="Select Neck Size"
                                value={neckSize}
                                setValue={setNeckSize}
                                options={
                                  optionsType?.maleOptions?.neck_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Chest Size (M)"
                                placeholder="Select Chest Size"
                                value={chestSize}
                                setValue={setChestSize}
                                options={
                                  optionsType?.maleOptions?.chest_size
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Jacket (M)"
                                placeholder="Select Jacket"
                                value={jacket}
                                setValue={setJacket}
                                options={
                                  optionsType?.maleOptions?.jacket?.field_meta
                                    ?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Shirt Size (F)"}
                                placeholder={"Select Shirt Size"}
                                value={shirtSizeF}
                                setValue={setShirtSizeF}
                                options={
                                  optionsType?.femaleOptions?.shirt_size_f
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Pant Size (F)"}
                                placeholder={"Select Pant Size"}
                                value={pantSize}
                                setValue={setPantSize}
                                options={
                                  optionsType?.femaleOptions?.pant_size_f
                                    ?.field_meta?.choices
                                }
                              />
                              <MultiSelectDropdown
                                title={"Shoe Size (F)"}
                                placeholder={"Select Shoe Size"}
                                value={shoeSizeF}
                                setValue={setShoeSizeF}
                                options={
                                  optionsType?.femaleOptions?.shoe_size_f
                                    ?.field_meta?.choices
                                }
                              />
                              <div className="fullCol">
                                <span>
                                  Bra size and Bra cup will only be visible to
                                  clients.
                                </span>
                              </div>
                              <DropdownList
                                title="Bra Cup (F)"
                                placeholder="Select Bra Cup"
                                value={braCup}
                                setValue={setBraCup}
                                options={
                                  optionsType?.femaleOptions?.bra_cup
                                    ?.field_meta?.choices
                                }
                              />

                              <DropdownList
                                title="Bra Size (F)"
                                placeholder="Select Bra Size"
                                value={braSize}
                                setValue={setBraSize}
                                options={
                                  optionsType?.femaleOptions?.bra_size
                                    ?.field_meta?.choices
                                }
                              />
                              <DropdownList
                                title="Dress Size (F)"
                                placeholder="Select Dress Size"
                                value={dressSize}
                                setValue={setDressSize}
                                options={
                                  optionsType?.femaleOptions?.dress_size_f
                                    ?.field_meta?.choices
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : talent == "Photographer" ? (
                  <>
                    <div class="form-section">
                      <div className="form-section-title">
                        Photographer Details
                      </div>
                      <div class="form-group form-group-50">
                        <InputField
                          required
                          title="Professional Bio"
                          type="textarea"
                          placeholder="Professional Bio"
                          value={bioDetails?.professionalBio}
                          onChangeText={(e) => {
                            setBioDetails({
                              ...bioDetails,
                              professionalBio: e?.target?.value,
                            });
                          }}
                        />
                        <InputField
                          required
                          title="Personal bio"
                          type="textarea"
                          placeholder="Personal bio"
                          value={bioDetails?.personalBio}
                          onChangeText={(e) => {
                            setBioDetails({
                              ...bioDetails,
                              personalBio: e?.target?.value,
                            });
                          }}
                        />

                        <MultiSelectDropdown
                          required
                          title="Select Expertise"
                          options={expertises}
                          value={expertise}
                          setValue={setExpertise}
                        />
                        <InputField
                          required
                          title="Custom Expertise"
                          type="text"
                          value={basicDetails?.customExpertise}
                          placeholder="Eg. Baseball, Football etc"
                          onChangeText={(e) => {
                            setBasicDetails({
                              ...basicDetails,
                              customExpertise: e?.target?.value,
                            });
                          }}
                        />
                        <DropdownList
                          required
                          title="Experience level"
                          placeholder="Select Experience level"
                          options={
                            optionsType?.otherOptions?.experience_level
                              ?.field_meta?.choices
                          }
                          value={experience}
                          setValue={setExperience}
                        />
                        <InputField
                          required
                          title="Years of experience"
                          type="text"
                          value={basicDetails?.yearsExperience}
                          placeholder="Eg. 1, 2, 3 etc"
                          onChangeText={(e) => {
                            setBasicDetails({
                              ...basicDetails,
                              yearsExperience: e?.target?.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div class="form-section">
                      <div className="form-section-title">My Skills</div>
                      <div class="form-group form-group-50">
                        <MultiSelectDropdown
                          required
                          title="My Skills"
                          placeholder="Select Skills"
                          options={
                            optionsType?.otherOptions?.add_new_skill?.field_meta
                              ?.choices
                          }
                          value={mySkill}
                          setValue={setMySkill}
                          isValid={error && mySkill?.length == 0}
                        />
                        <InputField
                          // required
                          title="Custom Skills"
                          type="text"
                          placeholder="Add Custom Skills. eg. Baseball, Football..."
                          value={customSkills}
                          onChangeText={(e) => {
                            setCustomSkills(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                {talent === "Actor" ? (
                  <div class="form-section">
                    <div
                      style={{ justifyContent: "flex-start" }}
                      className="form-section-title"
                    >
                      Reality TV Auditioning
                      <Tooltip
                        style={{ marginLeft: 15 }}
                        arrow
                        placement="top-start"
                        title="This information is private and will only display to the client"
                      >
                        <span className="tooltipOverlay">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </span>
                      </Tooltip>
                    </div>
                    <div class="form-group form-group-50">
                      <InputField
                        title="How many children do you have?"
                        type="number"
                        placeholder="Enter no. of children"
                        value={childrenCount}
                        onChangeText={(e) => {
                          setChildrenCount(e?.target?.value);
                        }}
                      />

                      <InputField
                        title="Occupation"
                        type="text"
                        placeholder="Enter Occupation"
                        value={auditions?.occupation}
                        onChangeText={(e) => {
                          setAuditions({
                            ...auditions,
                            occupation: e?.target?.value,
                          });
                        }}
                      />

                      <InputField
                        title="Three things about you that no one knows"
                        type="textarea"
                        placeholder="Enter your answer"
                        value={auditions?.threeThings}
                        onChangeText={(e) => {
                          setAuditions({
                            ...auditions,
                            threeThings: e?.target?.value,
                          });
                        }}
                      />

                      <InputField
                        title="What reality show can you see yourself on and why?"
                        type="textarea"
                        placeholder="Enter your answer"
                        value={auditions?.showOn}
                        onChangeText={(e) => {
                          setAuditions({
                            ...auditions,
                            showOn: e?.target?.value,
                          });
                        }}
                      />

                      <InputField
                        title="Have you ever been on a reality show? If yes, which show and when?"
                        type="textarea"
                        placeholder="Enter your answer"
                        value={auditions?.realityShow}
                        onChangeText={(e) => {
                          setAuditions({
                            ...auditions,
                            realityShow: e?.target?.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                ) : undefined}

                <div class="form-section">
                  <div className="form-section-title">Social Media Handles</div>

                  <div class="form-group form-group-50">
                    {talent != "Partners" ? (
                      <InputField
                        title="Your website"
                        type="text"
                        value={socialLinks.website}
                        placeholder="https://your-website-link.com/"
                        onChangeText={(e) => {
                          setSocialLinks({
                            ...socialLinks,
                            website: e?.target?.value,
                          });
                        }}
                      />
                    ) : null}
                    {talent == "Actor" || talent == "Actor Kid" ? (
                      <InputField
                        title="Video Link"
                        type="text"
                        value={videoLink}
                        placeholder="https://your-video-link.mp4"
                        onChangeText={(e) => {
                          setVideoLink(e.target.value);
                        }}
                      />
                    ) : null}
                    <InputField
                      title="Facebook Link"
                      type="text"
                      value={socialLinks.facebook}
                      placeholder="https://www.facebook.com/facebook-profile-link"
                      onChangeText={(e) => {
                        setSocialLinks({
                          ...socialLinks,
                          facebook: e?.target?.value,
                        });
                      }}
                    />
                    {talent != "Client" && talent != "Partners" ? (
                      <DropdownList
                        title="Facebook followers"
                        placeholder="Select"
                        options={followers}
                        value={facebookFollowers}
                        setValue={setFacebookFollowers}
                      />
                    ) : null}
                    <InputField
                      title="Instagram Link"
                      type="text"
                      value={socialLinks.instagram}
                      placeholder="https://www.instagram.com/instagram-profile-link"
                      onChangeText={(e) => {
                        setSocialLinks({
                          ...socialLinks,
                          instagram: e?.target?.value,
                        });
                      }}
                    />
                    {talent != "Client" && talent != "Partners" ? (
                      <DropdownList
                        title="Instargam followers"
                        placeholder="Select"
                        options={followers}
                        value={instagramFollowers}
                        setValue={setInstagramFollowers}
                      />
                    ) : null}
                    <InputField
                      title="Twitter Link"
                      type="text"
                      value={socialLinks.twitter}
                      placeholder="https://www.twitter.com/twitter-profile-link"
                      onChangeText={(e) => {
                        setSocialLinks({
                          ...socialLinks,
                          twitter: e?.target?.value,
                        });
                      }}
                    />
                    {talent != "Client" && talent != "Partners" ? (
                      <DropdownList
                        title="Twitter followers"
                        placeholder="Select"
                        options={followers}
                        value={twitterFollowers}
                        setValue={setTwitterFollowers}
                      />
                    ) : null}
                    <InputField
                      title="Youtube Link"
                      type="text"
                      value={socialLinks.youtube}
                      placeholder="https://www.youtube.com/youtube-profile-link"
                      onChangeText={(e) => {
                        setSocialLinks({
                          ...socialLinks,
                          youtube: e?.target?.value,
                        });
                      }}
                    />
                    {talent != "Client" && talent != "Partners" ? (
                      <DropdownList
                        title="Youtube followers"
                        placeholder="Select"
                        options={followers}
                        value={youtubeFollowers}
                        setValue={setYoutubeFollowers}
                      />
                    ) : null}
                    <InputField
                      title="TikTok Link"
                      type="text"
                      value={socialLinks.tikTok}
                      placeholder="https://www.tiktok.com/tiktok-profile-link"
                      onChangeText={(e) => {
                        setSocialLinks({
                          ...socialLinks,
                          tikTok: e?.target?.value,
                        });
                      }}
                    />
                    {talent != "Client" && talent != "Partners" ? (
                      <DropdownList
                        title="TikTok followers"
                        placeholder="Select"
                        options={followers}
                        value={tiktokFollowers}
                        setValue={setTiktokFollowers}
                      />
                    ) : null}
                    <InputField
                      title="Vimeo Link"
                      type="text"
                      value={socialLinks.vimeo}
                      placeholder="https://www.vimeo.com/vimeo-profile-link"
                      onChangeText={(e) => {
                        setSocialLinks({
                          ...socialLinks,
                          vimeo: e?.target?.value,
                        });
                      }}
                    />
                    {talent != "Client" && talent != "Partners" ? (
                      <DropdownList
                        title="Vimeo followers"
                        placeholder="Select"
                        options={followers}
                        value={vimeoFollowers}
                        setValue={setVimeoFollowers}
                      />
                    ) : null}
                  </div>
                </div>
                <Button variant=" wt-btn" class="wt-btn hide_org" type="submit">
                  Save
                </Button>
              </div>
            </Form>
            <div style={{ height: 50 }} />
            <>
              <div class="form-section">
                <div className="form-section-title">Profile Photo</div>
                <div class="wt-bannerphoto wt-tabsinfo">
                  <div class="wt-profilephotocontent">
                    <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                      <fieldset>
                        <div class="form-group form-group-label">
                          <div class="wt-labelgroup">
                            <label for="filep">
                              <span class="wt-btn">Select Files</span>
                              <input
                                type="file"
                                name="file"
                                id="filep"
                                onChange={handleChooseProfile}
                              />
                            </label>
                            <span>Choose file to upload</span>
                            {auth?.isLoading && (
                              <em class="wt-fileuploading">
                                Uploading
                                <i class="fa fa-spinner fa-spin"></i>
                              </em>
                            )}
                          </div>
                        </div>
                        {selectedFile && (
                          <ImageCropper
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            imageSrc={selectedFile?.uri}
                            type={"profile"}
                            show={cropped}
                            setShow={setCropped}
                            cropped={cropped}
                            setCropped={setCropped}
                            profileImage={profileImage}
                            setProfileImage={setProfileImage}
                            onCrop={uploadProfilePhoto}
                          />
                        )}
                        {profileImage && (
                          <div class="form-group">
                            <ul class="wt-attachfile wt-attachfilevtwo">
                              <li class="wt-uploadingholder">
                                <div class="wt-uploadingbox">
                                  <div class="wt-designimg">
                                    <input
                                      id="demoq"
                                      type="radio"
                                      name="employees"
                                      value="company"
                                    />
                                    <label for="demoq">
                                      <img
                                        src={
                                          profileImage?.url
                                            ? profileImage?.url
                                            : profileImage?.uri
                                        }
                                        alt="img description"
                                      />
                                      <i class="fa fa-check"></i>
                                    </label>
                                  </div>
                                  <div class="wt-uploadingbar">
                                    <span class="uploadprogressbar"></span>
                                    <span>{profileImage?.name}</span>
                                    <em>
                                      {profileImage?.size && (
                                        <>
                                          File size:{" "}
                                          {bytesToKB(profileImage?.size)}
                                        </>
                                      )}
                                      <i
                                        class="fa fa-times lnr lnr-cross"
                                        aria-hidden="true"
                                        onClick={() =>
                                          //  setProfileImage("")
                                          removeGalleryImage(
                                            profileImage,
                                            "profile"
                                          )
                                        }
                                      ></i>
                                    </em>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              {talent != "Client" && talent != "Partners" ? (
                <div class="form-section">
                  {selectedFiles?.length != 0 && (
                    <ImageCropper
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      type={"gallery"}
                      show={galleryCropper}
                      setShow={setGalleryCropper}
                      onCrop={handleGallerySubmit}
                      images={images}
                      setImages={setImages}
                    />
                  )}
                  <div
                    style={{ justifyContent: "flex-start" }}
                    className="form-section-title"
                  >
                    Gallery
                    <Tooltip
                      style={{ marginLeft: 15 }}
                      arrow
                      placement="top-start"
                      title="These images will be displayed on your public
                        gallery"
                    >
                      <span className="tooltipOverlay">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                    </Tooltip>
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id={`tooltip`}>These images will be displayed on your public
                      gallery</Tooltip>}
                    >
                      <Form.Label>
                        <span className="tooltipOverlay">
                          <i class="fa fa-question-circle" aria-hidden="true">
                            
                          </i>
                        </span>
                      </Form.Label>
                    </OverlayTrigger> */}
                  </div>
                  <div class="wt-bannerphoto wt-tabsinfo">
                    <div class="wt-profilephotocontent">
                      <div className="fullCol">
                        <span>
                          Upload a minimum of 3 images. Images should be
                          uploaded in jpg/png format.
                        </span>
                      </div>
                      <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                        <fieldset>
                          <div class="form-group form-group-label">
                            <div
                              class={
                                images?.length >= 10
                                  ? "limit_border"
                                  : "wt-labelgroup"
                              }
                            >
                              {images?.length >= 10 ? (
                                <span className="note">
                                  Note : You can choose minimum 3 images or
                                  maximum 10 images.
                                </span>
                              ) : (
                                <>
                                  <label for="filew">
                                    <span class="wt-btn">Select Files</span>
                                    <input
                                      type="file"
                                      name="file"
                                      id="filew"
                                      multiple
                                      onChange={handleChooseGallery}
                                      disabled={images?.length >= 10}
                                    />
                                  </label>
                                  <span>Choose files to upload</span>
                                  <em class="wt-fileuploading">
                                    Uploading
                                    <i class="fa fa-spinner fa-spin"></i>
                                  </em>
                                </>
                              )}
                            </div>
                          </div>

                          <div class="form-group">
                            <ul class="wt-attachfile wt-attachfilevtwo">
                              {images?.length != 0 &&
                                images?.map((item, index) => {
                                  return (
                                    <>
                                      <li class="wt-uploadingholder">
                                        <div class="wt-uploadingbox">
                                          <div class="wt-designimg">
                                            <input
                                              id="demoq"
                                              type="radio"
                                              name="employees"
                                              value="company"
                                              checked=""
                                            />
                                            <label for="demoq">
                                              <img
                                                src={
                                                  item?.url
                                                    ? item?.url
                                                    : item?.uri
                                                }
                                                alt="img description"
                                              />
                                              <i class="fa fa-check"></i>
                                            </label>
                                          </div>
                                          <div class="wt-uploadingbar">
                                            <span class="uploadprogressbar"></span>
                                            <span>{item?.name}</span>
                                            <em>
                                              {item?.size && (
                                                <>
                                                  File size:{" "}
                                                  {bytesToKB(item?.size)}
                                                </>
                                              )}

                                              <i
                                                class="fa fa-times lnr lnr-cross"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  removeGalleryImage(
                                                    item,
                                                    "gallery"
                                                  )
                                                }
                                              ></i>
                                            </em>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}

              {talent === "Actor" || talent === "Actor Kid" ? (
                <>
                  <div class="form-section">
                    <div className="form-section-title">Waist Up Photo</div>
                    <div class="wt-bannerphoto wt-tabsinfo">
                      <div class="wt-profilephotocontent">
                        <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                          <div class="form-group form-group-label">
                            <div class="wt-labelgroup">
                              <label for="filewa">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="filewa"
                                  onChange={(e) =>
                                    handleChooseImages(e, "waist")
                                  }
                                />
                              </label>
                              <span>Choose file to upload</span>
                              {auth?.isLoading && (
                                <em class="wt-fileuploading">
                                  Uploading
                                  <i class="fa fa-spinner fa-spin"></i>
                                </em>
                              )}
                            </div>
                          </div>
                          {waistImage && (
                            <ImageCropper
                              selectedFile={waistImage}
                              setSelectedFile={setWaistImage}
                              imageSrc={waistImage?.uri}
                              type={"waist_up"}
                              show={cropped}
                              setShow={setCropped}
                              cropped={cropped}
                              setCropped={setCropped}
                              profileImage={waistImageData}
                              setProfileImage={setWaistImageData}
                              onCrop={uploadWaistphoto}
                            />
                          )}
                          {waistImageData && (
                            <div class="form-group">
                              <ul class="wt-attachfile wt-attachfilevtwo">
                                <li class="wt-uploadingholder">
                                  <div class="wt-uploadingbox">
                                    <div class="wt-designimg">
                                      <input
                                        id="demoq"
                                        type="radio"
                                        name="employees"
                                        value="company"
                                      />
                                      <label for="demoq">
                                        <img
                                          src={waistImageData?.uri}
                                          alt="img description"
                                        />
                                        <i class="fa fa-check"></i>
                                      </label>
                                    </div>
                                    <div class="wt-uploadingbar">
                                      <span class="uploadprogressbar"></span>
                                      <span>{waistImageData?.name}</span>
                                      <em>
                                        {waistImageData?.size && (
                                          <>
                                            File size:{" "}
                                            {bytesToKB(waistImageData?.size)}
                                          </>
                                        )}
                                        <i
                                          class="fa fa-times lnr lnr-cross"
                                          aria-hidden="true"
                                          onClick={() =>
                                            // setProfileImage("")
                                            removeGalleryImage(
                                              waistImageData,
                                              "waist_up"
                                            )
                                          }
                                        ></i>
                                      </em>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="form-section">
                    <div className="form-section-title">
                      Shoulder Up, Facing Camera
                    </div>
                    <div class="wt-bannerphoto wt-tabsinfo">
                      <div class="wt-profilephotocontent">
                        <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                          <div class="form-group form-group-label">
                            <div class="wt-labelgroup">
                              <label for="filesu">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="filesu"
                                  onChange={(e) =>
                                    handleChooseImages(e, "shoulderUp")
                                  }
                                />
                              </label>
                              <span>Choose file to upload</span>
                              {auth?.isLoading && (
                                <em class="wt-fileuploading">
                                  Uploading
                                  <i class="fa fa-spinner fa-spin"></i>
                                </em>
                              )}
                            </div>
                          </div>
                          {shoulderUpImage && (
                            <ImageCropper
                              selectedFile={shoulderUpImage}
                              setSelectedFile={setShoulderUpImage}
                              imageSrc={shoulderUpImage?.uri}
                              type={"shoulders_up_straight"}
                              show={cropped}
                              setShow={setCropped}
                              cropped={cropped}
                              setCropped={setCropped}
                              profileImage={shoulderUpImageData}
                              setProfileImage={setShoulderUpImageData}
                              onCrop={uploadWaistphoto}
                            />
                          )}
                          {shoulderUpImageData && (
                            <div class="form-group">
                              <ul class="wt-attachfile wt-attachfilevtwo">
                                <li class="wt-uploadingholder">
                                  <div class="wt-uploadingbox">
                                    <div class="wt-designimg">
                                      <input
                                        id="demoq"
                                        type="radio"
                                        name="employees"
                                        value="company"
                                      />
                                      <label for="demoq">
                                        <img
                                          src={shoulderUpImageData?.uri}
                                          alt="img description"
                                        />
                                        <i class="fa fa-check"></i>
                                      </label>
                                    </div>
                                    <div class="wt-uploadingbar">
                                      <span class="uploadprogressbar"></span>
                                      <span>{shoulderUpImageData?.name}</span>
                                      <em>
                                        {shoulderUpImageData?.size && (
                                          <>
                                            File size:{" "}
                                            {bytesToKB(
                                              shoulderUpImageData?.size
                                            )}
                                          </>
                                        )}
                                        <i
                                          class="fa fa-times lnr lnr-cross"
                                          aria-hidden="true"
                                          onClick={() =>
                                            // setProfileImage("")
                                            removeGalleryImage(
                                              shoulderUpImageData,
                                              "shoulders_up_straight"
                                            )
                                          }
                                        ></i>
                                      </em>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="form-section">
                    <div className="form-section-title">
                      Shoulder Up, Side Profile
                    </div>
                    <div class="wt-bannerphoto wt-tabsinfo">
                      <div class="wt-profilephotocontent">
                        <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                          <div class="form-group form-group-label">
                            <div class="wt-labelgroup">
                              <label for="filestu">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="filestu"
                                  onChange={(e) =>
                                    handleChooseImages(e, "shoulderDown")
                                  }
                                />
                              </label>
                              <span>Choose file to upload</span>
                              {auth?.isLoading && (
                                <em class="wt-fileuploading">
                                  Uploading
                                  <i class="fa fa-spinner fa-spin"></i>
                                </em>
                              )}
                            </div>
                          </div>
                          {shoulderDownImage && (
                            <ImageCropper
                              selectedFile={shoulderDownImage}
                              setSelectedFile={setShoulderDownImage}
                              imageSrc={shoulderDownImage?.uri}
                              type={"shoulders_up_turn"}
                              show={cropped}
                              setShow={setCropped}
                              cropped={cropped}
                              setCropped={setCropped}
                              profileImage={shoulderDownImageData}
                              setProfileImage={setShoulderDownImageData}
                              onCrop={uploadWaistphoto}
                            />
                          )}
                          {shoulderDownImageData && (
                            <div class="form-group">
                              <ul class="wt-attachfile wt-attachfilevtwo">
                                <li class="wt-uploadingholder">
                                  <div class="wt-uploadingbox">
                                    <div class="wt-designimg">
                                      <input
                                        id="demoq"
                                        type="radio"
                                        name="employees"
                                        value="company"
                                      />
                                      <label for="demoq">
                                        <img
                                          src={shoulderDownImageData?.uri}
                                          alt="img description"
                                        />
                                        <i class="fa fa-check"></i>
                                      </label>
                                    </div>
                                    <div class="wt-uploadingbar">
                                      <span class="uploadprogressbar"></span>
                                      <span>{shoulderDownImageData?.name}</span>
                                      <em>
                                        {shoulderDownImageData?.size && (
                                          <>
                                            File size:{" "}
                                            {bytesToKB(
                                              shoulderDownImageData?.size
                                            )}
                                          </>
                                        )}
                                        <i
                                          class="fa fa-times lnr lnr-cross"
                                          aria-hidden="true"
                                          onClick={() =>
                                            // setProfileImage("")
                                            removeGalleryImage(
                                              shoulderDownImageData,
                                              "shoulders_up_turn"
                                            )
                                          }
                                        ></i>
                                      </em>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              ) : undefined}

              {talent === "Actor" ? (
                <>
                  <div class="form-section">
                    <div className="form-section-title">Happy</div>
                    <div class="wt-bannerphoto wt-tabsinfo">
                      <div class="wt-profilephotocontent">
                        <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                          <div class="form-group form-group-label">
                            <div class="wt-labelgroup">
                              <label for="filehappy">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="filehappy"
                                  onChange={(e) =>
                                    handleChooseImages(e, "happy")
                                  }
                                />
                              </label>
                              <span>Choose file to upload</span>
                              {auth?.isLoading && (
                                <em class="wt-fileuploading">
                                  Uploading
                                  <i class="fa fa-spinner fa-spin"></i>
                                </em>
                              )}
                            </div>
                          </div>
                          {happy && (
                            <ImageCropper
                              selectedFile={happy}
                              setSelectedFile={setHappy}
                              imageSrc={happy?.uri}
                              type={"happy"}
                              show={cropped}
                              setShow={setCropped}
                              cropped={cropped}
                              setCropped={setCropped}
                              profileImage={happyData}
                              setProfileImage={setHappyData}
                              onCrop={uploadWaistphoto}
                            />
                          )}
                          {happyData && (
                            <div class="form-group">
                              <ul class="wt-attachfile wt-attachfilevtwo">
                                <li class="wt-uploadingholder">
                                  <div class="wt-uploadingbox">
                                    <div class="wt-designimg">
                                      <input
                                        id="demoq"
                                        type="radio"
                                        name="employees"
                                        value="company"
                                      />
                                      <label for="demoq">
                                        <img
                                          src={happyData?.uri}
                                          alt="img description"
                                        />
                                        <i class="fa fa-check"></i>
                                      </label>
                                    </div>
                                    <div class="wt-uploadingbar">
                                      <span class="uploadprogressbar"></span>
                                      <span>{happyData?.name}</span>
                                      <em>
                                        {happyData?.size && (
                                          <>
                                            File size:{" "}
                                            {bytesToKB(happyData?.size)}
                                          </>
                                        )}
                                        <i
                                          class="fa fa-times lnr lnr-cross"
                                          aria-hidden="true"
                                          onClick={() =>
                                            // setProfileImage("")
                                            removeGalleryImage(
                                              happyData,
                                              "happy"
                                            )
                                          }
                                        ></i>
                                      </em>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="form-section">
                    <div className="form-section-title">Sad</div>
                    <div class="wt-bannerphoto wt-tabsinfo">
                      <div class="wt-profilephotocontent">
                        <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                          <div class="form-group form-group-label">
                            <div class="wt-labelgroup">
                              <label for="filesad">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="filesad"
                                  onChange={(e) => handleChooseImages(e, "sad")}
                                />
                              </label>
                              <span>Choose file to upload</span>
                              {auth?.isLoading && (
                                <em class="wt-fileuploading">
                                  Uploading
                                  <i class="fa fa-spinner fa-spin"></i>
                                </em>
                              )}
                            </div>
                          </div>
                          {sad && (
                            <ImageCropper
                              selectedFile={sad}
                              setSelectedFile={setSad}
                              imageSrc={sad?.uri}
                              type={"sad"}
                              show={cropped}
                              setShow={setCropped}
                              cropped={cropped}
                              setCropped={setCropped}
                              profileImage={sadData}
                              setProfileImage={setSadData}
                              onCrop={uploadWaistphoto}
                            />
                          )}
                          {sadData && (
                            <div class="form-group">
                              <ul class="wt-attachfile wt-attachfilevtwo">
                                <li class="wt-uploadingholder">
                                  <div class="wt-uploadingbox">
                                    <div class="wt-designimg">
                                      <input
                                        id="demoq"
                                        type="radio"
                                        name="employees"
                                        value="company"
                                      />
                                      <label for="demoq">
                                        <img
                                          src={sadData?.uri}
                                          alt="img description"
                                        />
                                        <i class="fa fa-check"></i>
                                      </label>
                                    </div>
                                    <div class="wt-uploadingbar">
                                      <span class="uploadprogressbar"></span>
                                      <span>{sadData?.name}</span>
                                      <em>
                                        {sadData?.size && (
                                          <>
                                            File size:{" "}
                                            {bytesToKB(sadData?.size)}
                                          </>
                                        )}
                                        <i
                                          class="fa fa-times lnr lnr-cross"
                                          aria-hidden="true"
                                          onClick={() =>
                                            // setProfileImage("")
                                            removeGalleryImage(sadData, "sad")
                                          }
                                        ></i>
                                      </em>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div class="form-section">
                    <div className="form-section-title">Angry</div>
                    <div class="wt-bannerphoto wt-tabsinfo">
                      <div class="wt-profilephotocontent">
                        <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                          <div class="form-group form-group-label">
                            <div class="wt-labelgroup">
                              <label for="fileangry">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="fileangry"
                                  onChange={(e) =>
                                    handleChooseImages(e, "angry")
                                  }
                                />
                              </label>
                              <span>Choose file to upload</span>
                              {auth?.isLoading && (
                                <em class="wt-fileuploading">
                                  Uploading
                                  <i class="fa fa-spinner fa-spin"></i>
                                </em>
                              )}
                            </div>
                          </div>
                          {angry && (
                            <ImageCropper
                              selectedFile={angry}
                              setSelectedFile={setangry}
                              imageSrc={angry?.uri}
                              type={"angry"}
                              show={cropped}
                              setShow={setCropped}
                              cropped={cropped}
                              setCropped={setCropped}
                              profileImage={AngryData}
                              setProfileImage={setAngryData}
                              onCrop={uploadWaistphoto}
                            />
                          )}
                          {AngryData && (
                            <div class="form-group">
                              <ul class="wt-attachfile wt-attachfilevtwo">
                                <li class="wt-uploadingholder">
                                  <div class="wt-uploadingbox">
                                    <div class="wt-designimg">
                                      <input
                                        id="demoq"
                                        type="radio"
                                        name="employees"
                                        value="company"
                                      />
                                      <label for="demoq">
                                        <img
                                          src={AngryData?.uri}
                                          alt="img description"
                                        />
                                        <i class="fa fa-check"></i>
                                      </label>
                                    </div>
                                    <div class="wt-uploadingbar">
                                      <span class="uploadprogressbar"></span>
                                      <span>{AngryData?.name}</span>
                                      <em>
                                        {AngryData?.size && (
                                          <>
                                            File size:{" "}
                                            {bytesToKB(AngryData?.size)}
                                          </>
                                        )}
                                        <i
                                          class="fa fa-times lnr lnr-cross"
                                          aria-hidden="true"
                                          onClick={() =>
                                            // setProfileImage("")
                                            removeGalleryImage(
                                              AngryData,
                                              "angry"
                                            )
                                          }
                                        ></i>
                                      </em>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </>
              ) : undefined}

              <Button
                variant=" wt-btn"
                class="wt-btn hide_org"
                onClick={() => handleNext()}
              >
                Submit
              </Button>

              <div style={{ height: 50 }} />
            </>
          </div>
        </div>
        <div style={{ height: 50 }} />
      </Container>

      <Footer />
    </>
  );
};
