import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { getData, storageKey } from "../../Utility/Storage";
import {
  getFollowDetails,
  userFollowing,
} from "../../Redux/Services/OtherServices";

const FollowDetails = () => {
  const [tab, setTab] = useState("followers");
  const dispatch = useDispatch();
  const other = useSelector((state) => state?.otherReducer);
  const [followList, setFollowList] = useState("");
  const [followedID, setFollowedID] = useState("");
  const [profileID, setProfileID] = useState("");
  const [emptyList, setEmptyList] = useState(false);

  useEffect(() => {
    getFollowingList("followers");
  }, []);

  const getFollowingList = async (type) => {
    console.log("list type------", type);
    let userID = await getData(storageKey?.USER_ID);
    let profileID = await getData(storageKey?.PROFILE_ID);

    var body = {
      action: type,
      user_id: userID,
      post_id: profileID,
    };
    let res = await dispatch(getFollowDetails(body));
    console.log("list res------", res);

    if (res?.status == 200) {
      setTab(type);
      setFollowList(res?.results);
      if (res?.results?.length == 0) {
        setEmptyList(true);
      } else {
        setEmptyList(false);
      }
    } else {
      setEmptyList(true);
    }
  };
  const handleFollow = async (type, data) => {
    let userID = await getData(storageKey?.USER_ID);
    var body = {
      action: type,
      user_id: userID,
      post_id: data?.profile_id,
    };
    let res = await dispatch(userFollowing(body));
    if (res?.status == 200) {
      if (tab == 1) {
        if (type == "follow" || type == "remove" || type == "unfollow") {
          getFollowingList("followers", 1);
        }
      } else if (tab == 2) {
        if (type == "unfollow") {
          getFollowingList("following", 2);
        }
      } else if (tab == 3) {
        if (type == "accept" || type == "decline") {
          getFollowingList("requests", 3);
        }
      } else if (tab == 4) {
        if (type == "accept" || type == "decline") {
          getFollowingList("blocked", 4);
        }
      }
    }
  };
  const options = [
    {
      name: "Followers",
      type: "followers",
    },
    {
      name: "Following",
      type: "following",
    },
    {
      name: "Requests",
      type: "requests",
    },
    {
      name: "Blocked",
      type: "blocked",
    },
  ];

 
  return (
    <>
      <SidebarLayout>
        <div class="searchtalentList dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="followers"
              className="mb-3"
              id="follow"
              activeKey={tab}
              onSelect={getFollowingList}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  {followList?.map((ele, ind) => {
                    return (
                      <>
                        <div class="wt-userlistingholder wt-userlisting wt-haslayout">
                          <section>
                            <div>
                              <div>
                                <div class="paidUsers">
                                  <i class="fas fa-crown"></i>
                                </div>
                                <div class="rpc-post-image">
                                  <a href="#">
                                    <div class="fixed-height-image">
                                      <img src={ele?.profile_img} />
                                    </div>
                                  </a>
                                </div>
                                <a
                                  href="#"
                                  class="craContent"
                                  id=""
                                  tabindex="-1"
                                >
                                  <div class="bottomArea ">
                                    <h3 class="rpc-post-title">
                                      <div class="rpc-title" target="_self">
                                        <span
                                          class="wt-tipso tipso_style"
                                          data-tipso="ID-Verified"
                                          data-toggle="tooltip"
                                        >
                                          <i
                                            class="fa fa-check"
                                            color="#ef4d74"
                                          ></i>
                                        </span>
                                       {ele?.display_name}
                                      </div>
                                    </h3>
                                    <dic className="actBrns">
                                      {/* <button className="btn">Follow</button> */}
                                      <button className="btn">Unfollow</button>
                                    </dic>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </section>
                        </div>
                      </>
                    );
                  })}
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </SidebarLayout>
    </>
  );
};

export default FollowDetails;
