import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { DeletePost, Loader, PostCard } from "../../Components";
import { useDispatch } from "react-redux";
import {
  deletePost,
  getUserSocialPosts,
  likeDislike,
} from "../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../Utility/Storage";

const ManagePost = () => {
  const dispatch = useDispatch();
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const getPortfolioData = async () => {
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    try {
      let body = {
        user_id: JSON.parse(userId),
        profile_id: JSON?.parse(profileId),
      };
      setLoading(true);
      let res = await dispatch(getUserSocialPosts(body));
      setLoading(false);
      if (res?.status === 200) {
        setPortfolios(res?.results);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeletePost = async () => {
    try {
      let userId = await getData(storageKey?.USER_ID);
      let body = {
        user_id: JSON.parse(userId),
        action: "delete",
        post_id: selectedId,
      };
      setLoading(true);
      let res = await dispatch(deletePost(body));
      setLoading(false);
      if (res?.status === 200) {
        getPortfolioData();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleLikeUnlike = async (type, id) => {
    let userId = await getData(storageKey?.USER_ID);
    try {
      let body = {
        type: type,
        post_id: id,
        user_id: JSON.parse(userId),
      };
      setLoading(true);
      let res = await dispatch(likeDislike(body));
      setLoading(false);
      if (res?.status === 200) {
        getPortfolioData();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPortfolioData();
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          {portfolios?.length ? (
            portfolios?.map((item, index) => (
              <div key={index} className="post-card-grid">
                  <PostCard
                    setIsDeleteModal={setIsDeleteModal}
                    type="post"
                    cardData={item}
                    setSelectedId={setSelectedId}
                    handleLikeUnlike={handleLikeUnlike}
                  />
                </div>
            ))
          ) : loading ? undefined : (
            <span>No Social Post Found</span>
          )}
        </div>
      </main>

      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeletePost}
          text="Are you sure you want to delete this post?"
        />
      ) : undefined}
    </SidebarLayout>
  );
};

export default ManagePost;
