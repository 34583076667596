// Filename - ContactUs.jsx
import React from "react";
import { Header, Footer, Loader } from "../../Components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  directJobAction,
  getJobDetails,
  getSavedPost,
  savePostApi,
  unsavePostApi,
  userFollowing,
  userReporting,
} from "../../Redux/Services/OtherServices";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import { LoginAlert } from "../../Components/Popups/LoginAlert";
import { DashboardPopUp, Login } from "../../Components/Popups";
import { getData, storageKey } from "../../Utility/Storage";
import {
  getOptionsData,
  getUserDetail,
} from "../../Redux/Services/AuthServices";
import { routeName, showToast } from "../../Utility";
import { Images } from "../../Constants";

export const JobDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const allOptionData = useSelector(
    (state) => state?.authReducer.allOptionData
  );
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  const type = queryParams?.get("type");
  console.log("Your Param Value:", type);
  const [jobDetail, setjobDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobData, setJobDeta] = useState("");
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [reportDescription, setReportDescription] = useState("");
  const [reportReason, setReportReason] = useState("");
  // const [optionsData, setOptionsData] = useState("");
  const [isJobSaved, setIsJobSaved] = useState(false);
  const [clientInfo, setClientInfo] = useState("");
  const [isFollow, setIsFollow] = useState(false);
  const [isIdentityVisible, setIsIdentityVisible] = useState(false);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      let res = await dispatch(getUserDetail(body));
    } else {
    }
  };

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24?.split(":");
    let period = "AM";
    if (hours >= 12) {
      period = "PM";
    }
    const hours12 = hours % 12 || 12;
    const formattedTime = `${String(hours12)?.padStart(
      2,
      "0"
    )}:${minutes} ${period}`;
    return formattedTime;
  }

  function formatString(inputString) {
    const stringWithoutHyphens = inputString?.replace(/-/g, " ");
    const words = stringWithoutHyphens?.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const resultString = capitalizedWords.join(" ");
    return resultString;
  }

  const getSavedJobData = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      let body = {
        user_id: JSON.parse(user),
        post_type: 213,

        // type: "saved",
        // post_type: "213",
      };
      let res = await dispatch(getSavedPost(body));
      if (res?.status == 200) {
        const matchingObject = res?.results?.length
          ? res?.results?.find((obj) => obj.profile?.ID === id)
          : false;
        setIsJobSaved(matchingObject);
      }
    }
  };

  const jobDetails = async () => {
    setLoading(true);
    let userID = await getData(storageKey?.USER_ID);
    if (id) {
      let body = {
        project_id: id,
        user_id: userID ? JSON.parse(userID) : "",
      };
      let res = await dispatch(getJobDetails(body));
      setLoading(false);
      if (res?.status == 200) {
        setjobDetail(res?.results?.[0]);
      }
    } else {
    }
  };

  const handleSendProposal = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      if (!jobDetail?.verification_detail?.account_status) {
        showToast(
          "You cannot send proposal to this job as your profile is not verified",
          "error"
        );
        return;
      }

      if (!jobDetail?.verification_detail?.bank_account_status) {
        showToast(
          "You cannot send proposal to this job as your bank details are not added",
          "error"
        );
        return;
      }
      if (!jobDetail?.verification_detail?.identity_status) {
        // showToast("You cannot send proposal to this job", "error");
        setIsIdentityVisible(true);
        return;
      }

      const queryParams = new URLSearchParams({
        id: jobDetail?.profile?.ID,
      });
      navigate(`${routeName.JOB_PROPOSAL}?${queryParams.toString()}`);
    } else {
      setLoginModalVisible(true);
    }
  };

  const handleAcceptProposal = async (key) => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      if (!jobDetail?.verification_detail?.account_status) {
        showToast(
          "You cannot send proposal to this job as your profile is not verified",
          "error"
        );
        return;
      }
      if (!jobDetail?.verification_detail?.bank_account_status) {
        showToast(
          "You cannot send proposal to this job as your bank details are not added",
          "error"
        );
        return;
      }
      if (!jobDetail?.verification_detail?.identity_status) {
        showToast("You cannot send proposal to this job", "error");
        return;
      }
      let body = {
        user_id: loggedInUser?.user_data?.user_id || "",
        project_id: id || "",
        action: key === "accept" ? "offer_accept" : "offer_decline",
      };
      setLoading(true);
      try {
        let res = await dispatch(directJobAction(body));
        setLoading(false);
        if (res?.status === 200) {
          showToast(
            key === "accept"
              ? "Offer accepted successfully"
              : "Offer rejected Successfully",
            "success"
          );
          jobDetails();
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      setLoginModalVisible(true);
    }
  };

  const handleReport = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      if (!reportReason) {
        showToast("Choose report reason first");
        return;
      }
      let body = {
        user_by: JSON.parse(user),
        // reported_user_id: talentData?.userData?.user_id,

        report_type: "project",
        reported_post_id: id,

        title: reportReason,
        content: reportDescription || "",
      };
      let res = await dispatch(userReporting(body));
      if (res?.status == 200) {
        setReportReason("");
        setReportDescription("");
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  const handleSaveJob = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      let body = {
        user_id: JSON.parse(user),
        post_id: id,

        type: isJobSaved ? "unsaved" : "saved",
        post_type: "213",
      };
      setLoading(true);
      let res = "";
      if (isJobSaved) {
        res = await dispatch(unsavePostApi(body));
      } else {
        res = await dispatch(savePostApi(body));
      }
      if (res?.status == 200) {
        getSavedJobData();
        showToast(
          isJobSaved ? "Job Removed Successfully" : "Job Saved Successfully",
          "success"
        );
      }
      setLoading(false);
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  // const getAllFiltersData = async () => {
  //   let res = await dispatch(getOptionsData());
  //   if (res?.status === 200) {
  //     setOptionsData(res?.results?.user_report_options || "");
  //   }
  // };

  const getClientDetail = async () => {
    let user = await getData(storageKey.USER_ID);
    let body = {
      user_id: jobDetail?.profile?.post_author,
    };
    let res = await dispatch(getUserDetail(body));
    if (res?.status == 200) {
      setClientInfo(res?.results);
      setIsFollow(
        res?.results?.post_meta_details?.user_followers?.includes(user)
          ? true
          : false
      );
    }
  };

  useEffect(() => {
    if (jobDetail?.profile?.post_author) {
      getClientDetail();
    }
  }, [jobDetail]);

  const handleFollowUnFollow = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      let body = {
        user_id: user,
        post_id: clientInfo?.user_data?.profile_id,
        action: isFollow ? "unfollow" : "follow",
      };
      let res = await dispatch(userFollowing(body));
      if (res?.status == 200) {
        getClientDetail();
        showToast(
          isFollow ? "UnFollow Successfully" : "Follow successfully",
          "success"
        );
      }
    } else {
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  useEffect(() => {
    setLoading(true);
    jobDetails();
    // getAllFiltersData();
    getSavedJobData();
  }, []);

  return (
    <div>
      <Header />
      <Loader loading={loading} />
      <div class="container">
        <div class="row">
          <div id="wt-twocolumns" class="wt-twocolumns wt-haslayout">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left">
              <div class="wt-proposalholder">
                <div class="wt-proposalhead">
                  <h2>{jobDetail?.profile?.post_title || ""}</h2>
                  <ul class="wt-userlisting-breadcrumb wt-userlisting-breadcrumbvtwo">
                    {jobDetail?.fw_option?.length ? (
                      <li>
                        <span>
                          Job Type:{" "}
                          {jobDetail?.fw_option?.length
                            ? jobDetail?.fw_option?.[0]?.job_option
                            : ""}
                        </span>
                      </li>
                    ) : undefined}
                    <li>
                      <span>
                        {jobDetail?.post_meta_details?.country || ""} /{" "}
                        {jobDetail?.post_meta_details?.city}
                      </span>
                    </li>
                    <li>
                      <span>
                        Type:{" "}
                        {jobDetail?.post_meta_details?._project_type &&
                          jobDetail?.post_meta_details?._project_type}
                      </span>
                    </li>
                    <li>
                      <span>
                        Level:{" "}
                        {jobDetail?.fw_option?.length
                          ? formatString(jobDetail?.fw_option[0]?.project_level)
                          : ""}
                      </span>
                    </li>
                  </ul>
                  {jobDetail?.post_meta_details?.model_type_req ===
                  "casting calls" ? (
                    <small className="castingCalls">Casting calls</small>
                  ) : undefined}
                </div>
                <div class="wt-btnarea">
                  {type === "notification" ? (
                    jobDetail?.post_meta_details?.proposal_status == "1" ? (
                      <a href="javascrip:void(0);" class="wt-btn">
                        Offer Already Accepted
                      </a>
                    ) : (
                      <>
                        <a
                          onClick={() => handleAcceptProposal("accept")}
                          href="javascrip:void(0);"
                          class="wt-btn"
                        >
                          Accept
                        </a>
                        <a
                          onClick={() => handleAcceptProposal("reject")}
                          href="javascrip:void(0);"
                          class="wt-btn"
                        >
                          Reject
                        </a>
                      </>
                    )
                  ) : jobDetail?.post_meta_details?.proposal_status == "1" ? (
                    <a href="javascrip:void(0);" class="wt-btn">
                      Proposal already submitted
                    </a>
                  ) : Number(jobDetail?.profile?.post_author) ===
                      Number(loggedInUser?.user_data?.user_id) ||
                    loggedInUser?.user_data?.user_role === 12 ||
                    loggedInUser?.user_data?.user_role === 14 ? undefined : (
                    <a
                      onClick={handleSendProposal}
                      href="javascrip:void(0);"
                      class="wt-btn"
                    >
                      Send Proposal
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="wt-projectdetail-holder">
                  <div class="wt-projectdetail">
                    <div class="wt-title">
                      <h3>Project Detail</h3>
                      <div onClick={() => handleSaveJob()} class="wt-clicklike">
                        <i
                          class={isJobSaved ? "fa fa-heart" : "fa fa-heart-o"}
                        ></i>
                      </div>
                    </div>
                    <div class="wt-description">
                      <p>{jobDetail?.profile?.post_content}</p>
                    </div>
                  </div>
                  <div class="wt-skillsrequired">
                    <div class="wt-title">
                      <h3>Skills Required</h3>
                    </div>
                    <div class="wt-tag wt-widgettag">
                      {jobDetail?.post_meta_details?.skills_names?.map(
                        (item) => (
                          <a>{item?.value}</a>
                        )
                      )}
                      {jobDetail?.post_meta_details?.add_custom_skills?.length
                        ? jobDetail?.post_meta_details?.add_custom_skills
                            ?.split(", ")
                            ?.map((item) => <a>{item || ""}</a>)
                        : undefined}
                    </div>
                  </div>

                  <div class="wt-date-time wt-widgettag">
                    <div class="wt-title">
                      <h3>Requirements</h3>
                    </div>
                    <table>
                      <tbody>
                        {jobDetail?.post_meta_details?.required_gender ? (
                          <tr>
                            <td>Required Gender</td>
                            <td>
                              {jobDetail?.post_meta_details?.required_gender ===
                              "male"
                                ? "Male"
                                : jobDetail?.post_meta_details
                                    ?.required_gender === "female"
                                ? "Female"
                                : jobDetail?.post_meta_details
                                    ?.required_gender === "other"
                                ? "Non-Binary"
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.post_meta_details?.age_req?.length ? (
                          <tr>
                            <td>Required Age</td>
                            <td>
                              {jobDetail?.post_meta_details?.age_req?.length
                                ? jobDetail?.post_meta_details?.age_req
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}
                        {jobDetail?.post_meta_details?.ethnicity_req?.length ? (
                          <tr>
                            <td>Ethnicity</td>
                            <td>
                              {jobDetail?.post_meta_details?.ethnicity_req
                                ?.length
                                ? jobDetail?.post_meta_details?.ethnicity_req
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}
                        {jobDetail?.post_meta_details?.influencer_req ? (
                          <tr>
                            <td>Influencer</td>
                            <td>
                              {jobDetail?.post_meta_details?.influencer_req ||
                                ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.post_meta_details?.height_req ? (
                          <tr>
                            <td>Height</td>
                            <td>
                              {jobDetail?.post_meta_details?.height_req || ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.post_meta_details?.weight_req ? (
                          <tr>
                            <td>Weight</td>
                            <td>
                              {jobDetail?.post_meta_details?.weight_req || ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.post_meta_details?.hair_color_req ? (
                          <tr>
                            <td>Hair Color</td>
                            <td>
                              {jobDetail?.post_meta_details?.hair_color_req ||
                                ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.shirt_size?.length ? (
                          <tr>
                            <td>Shirt Size</td>
                            <td>
                              {jobDetail?.measurement_details?.shirt_size
                                ?.length
                                ? jobDetail?.measurement_details?.shirt_size
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.shirt_size_f
                          ?.length ? (
                          <tr>
                            <td>Shirt Size (F)</td>
                            <td>
                              {jobDetail?.measurement_details?.shirt_size_f
                                ?.length
                                ? jobDetail?.measurement_details?.shirt_size_f
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.pant_size_waist
                          ?.length ? (
                          <tr>
                            <td>Pant Size (Waist)</td>
                            <td>
                              {jobDetail?.measurement_details?.pant_size_waist
                                ?.length
                                ? jobDetail?.measurement_details?.pant_size_waist
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.pant_size_length
                          ?.length ? (
                          <tr>
                            <td>Pant Size (Length)</td>
                            <td>
                              {jobDetail?.measurement_details?.pant_size_length
                                ?.length
                                ? jobDetail?.measurement_details?.pant_size_length
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.pant_size?.length ? (
                          <tr>
                            <td>Pant Size</td>
                            <td>
                              {jobDetail?.measurement_details?.pant_size?.length
                                ? jobDetail?.measurement_details?.pant_size
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.dress_size?.length ? (
                          <tr>
                            <td>Dress Size</td>
                            <td>
                              {jobDetail?.measurement_details?.dress_size
                                ?.length
                                ? jobDetail?.measurement_details?.dress_size
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.shoe_size?.length ? (
                          <tr>
                            <td>Shoe Size</td>
                            <td>
                              {jobDetail?.measurement_details?.shoe_size?.length
                                ? jobDetail?.measurement_details?.shoe_size
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.shoe_sizes?.length ? (
                          <tr>
                            <td>Shoe Size (F)</td>
                            <td>
                              {jobDetail?.measurement_details?.shoe_sizes
                                ?.length
                                ? jobDetail?.measurement_details?.shoe_sizes
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.dress_shirt_size ? (
                          <tr>
                            <td>Dress Shirt Size</td>
                            <td>
                              {jobDetail?.measurement_details?.dress_shirt_size}
                            </td>
                          </tr>
                        ) : undefined}
                        {/* dress_shirt_sleeve */}
                        {jobDetail?.measurement_details?.dress_shirt_sleeve ? (
                          <tr>
                            <td>Dress Shirt Sleeve</td>
                            <td>
                              {
                                jobDetail?.measurement_details
                                  ?.dress_shirt_sleeve
                              }
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.jacket ? (
                          <tr>
                            <td>jacket Size</td>
                            <td>{jobDetail?.measurement_details?.jacket}</td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.chest_size?.length ? (
                          <tr>
                            <td>Chest Size</td>
                            <td>
                              {jobDetail?.measurement_details?.chest_size}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.measurement_details?.neck_size?.length ? (
                          <tr>
                            <td>Neck Size</td>
                            <td>{jobDetail?.measurement_details?.neck_size}</td>
                          </tr>
                        ) : undefined}
                        {jobDetail?.measurement_details?.bra_cup?.length ? (
                          <tr>
                            <td>Bra Cup</td>
                            <td>{jobDetail?.measurement_details?.bra_cup}</td>
                          </tr>
                        ) : undefined}
                        {jobDetail?.measurement_details?.bra_size?.length ? (
                          <tr>
                            <td>Bra Size</td>
                            <td>{jobDetail?.measurement_details?.bra_size}</td>
                          </tr>
                        ) : undefined}
                      </tbody>
                    </table>
                  </div>

                  <div class="wt-date-time wt-widgettag">
                    <div class="wt-title">
                      <h3>Usage</h3>
                    </div>
                    <table>
                      <tbody>
                        {jobDetail?.post_meta_details?.duration_usage ? (
                          <tr>
                            <td>Duration</td>
                            <td>
                              {jobDetail?.post_meta_details?.duration_usage}
                            </td>
                          </tr>
                        ) : undefined}

                        {jobDetail?.post_meta_details?.media_type_usage
                          ?.length ? (
                          <tr>
                            <td>Media Type</td>
                            <td>
                              {jobDetail?.post_meta_details?.media_type_usage
                                ?.length
                                ? jobDetail?.post_meta_details?.media_type_usage
                                    ?.map((item) => item?.label)
                                    ?.join(", ")
                                : ""}
                            </td>
                          </tr>
                        ) : undefined}
                      </tbody>
                    </table>
                  </div>

                  <div class="wt-date-time wt-widgettag">
                    <div class="wt-title">
                      <h3>Date And Time</h3>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <th>Start Date</th>
                          <th>End Date</th>
                        </tr>
                        <tr>
                          <td>
                            {jobDetail?.post_meta_details?.starting_date || ""}
                          </td>
                          <td>
                            {jobDetail?.post_meta_details?.end_date || ""}
                          </td>
                        </tr>
                        <tr>
                          {/* end_time */}
                          <td>
                            {jobDetail?.post_meta_details?.format_start_time
                              ? convertTo12HourFormat(
                                  jobDetail?.post_meta_details
                                    ?.format_start_time
                                )
                              : ""}
                          </td>
                          <td>
                            {jobDetail?.post_meta_details?.format_end_time
                              ? convertTo12HourFormat(
                                  jobDetail?.post_meta_details?.format_end_time
                                )
                              : ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {jobDetail?.post_meta_details?.time_zone ? (
                    <div class="wt-skillsrequired">
                      <div class="wt-title">
                        <h3>TImezone:</h3>
                        {/* <h4>{jobDetail?.post_meta_details?.time_zone}</h4> */}
                      </div>
                      <div class="wt-tag wt-widgettag">
                        <a class="cat_70102" href="">
                          {jobDetail?.post_meta_details?.time_zone || ""}
                        </a>
                      </div>
                    </div>
                  ) : undefined}

                  {jobDetail?.post_meta_details?._english_level ? (
                    <div class="wt-skillsrequired">
                      <div class="wt-title">
                        <h3>English Level</h3>
                      </div>

                      <div class="wt-tag wt-widgettag">
                        <a class="cat_70102" href="">
                          {formatString(
                            jobDetail?.post_meta_details?._english_level
                          ) || ""}
                        </a>
                      </div>
                    </div>
                  ) : undefined}

                  {jobDetail?.post_meta_details?.client_provide?.length ? (
                    <div class="wt-skillsrequired">
                      <div class="wt-title">
                        <h3>Client Provides</h3>
                      </div>

                      <div class="wt-tag wt-widgettag">
                        {jobDetail?.post_meta_details?.client_provide?.map(
                          (item, index) => (
                            <a class="cat_70102" href="">
                              {item?.value || ""}
                            </a>
                          )
                        )}
                      </div>
                    </div>
                  ) : undefined}

                  {jobDetail?.post_meta_details?.type_of_work?.length ? (
                    <div class="wt-skillsrequired">
                      <div class="wt-title">
                        <h3>Types of Work</h3>
                      </div>

                      <div class="wt-tag wt-widgettag">
                        {jobDetail?.post_meta_details?.type_of_work?.map(
                          (item) => (
                            <a class="cat" href="">
                              {item?.label}
                            </a>
                          )
                        )}
                      </div>
                    </div>
                  ) : undefined}

                  {jobDetail?.post_meta_details?.talent_requirements?.length ? (
                    <div class="wt-skillsrequired">
                      <div class="wt-title">
                        <h3>Talent Required</h3>
                      </div>

                      <div class="talent_req">
                        <div class="wt-tag wt-widgettag flx_dd">
                          {jobDetail?.post_meta_details?.talent_requirements?.map(
                            (item) => (
                              <a class="cat" href="">
                                {item?.label}
                              </a>
                            )
                          )}

                          <a class="cat_0" href="">
                            {" "}
                            (Zhdh)
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : undefined}

                  {jobDetail?.post_meta_details?.model_type_req ? (
                    <div class="wt-skillsrequired">
                      <div class="wt-title">
                        <h3>Freelancer type required for this project</h3>
                      </div>
                      <div class="wt-tag wt-widgettag">
                        <a href="" class="cat_70102">
                          {jobDetail?.post_meta_details?.model_type_req || ""}
                        </a>
                      </div>
                    </div>
                  ) : undefined}

                  {jobDetail?.post_meta_details
                    ?.project_location_description ? (
                    <div class="wt-skillsrequired">
                      <div class="wt-title">
                        <h3>Project Location</h3>
                      </div>
                      <div class="wt-tag wt-widgettag">
                        <a href="" class="cat_70102">
                          {jobDetail?.post_meta_details
                            ?.project_location_description || ""}
                        </a>
                      </div>
                    </div>
                  ) : undefined}
                </div>
              </div>

              <div class="col-md-4">
                <aside>
                  <div class="wt-proposalsr">
                    <div class="wt-proposalsrcontent">
                      <span class="wt-proposalsicon">
                        <i class="fa fa-angle-double-down"></i>
                        <i class="fa fa-newspaper"></i>
                      </span>
                      <div class="wt-title">
                        <h3>
                          {jobDetail?.post_meta_details?._project_cost
                            ? "$" + jobDetail?.post_meta_details?._project_cost
                            : jobDetail?.post_meta_details?._hourly_rate
                            ? "$" + jobDetail?.post_meta_details?._hourly_rate
                            : // + ", " +
                              // jobDetail?.post_meta_details?._estimated_hours +
                              // " hours"
                              0}
                        </h3>
                        {jobDetail?.post_meta_details?._project_cost ? (
                          <span>Model Rate</span>
                        ) : jobDetail?.post_meta_details?._hourly_rate ? (
                          <span>
                            Per hour rate for estimated
                            <em>{`${
                              jobDetail?.post_meta_details?._estimated_hours ||
                              0
                            } hours`}</em>
                          </span>
                        ) : undefined}
                      </div>
                    </div>

                    <div class="wt-proposalsrcontent">
                      <span class="wt-proposalsicon">
                        <i class="fa fa-angle-double-down"></i>
                        <i class="fa fa-newspaper"></i>
                      </span>
                      <div class="wt-title">
                        <h3>
                          {jobDetail?.post_meta_details
                            ?.amount_of_per_diem_provided
                            ? "$" +
                              jobDetail?.post_meta_details
                                ?.amount_of_per_diem_provided *
                                jobDetail?.post_meta_details?.set_custom_time
                                  ?.length
                            : "$0"}
                        </h3>
                        <span>Total per diem Price</span>
                      </div>
                    </div>

                    <div class="wt-proposalsrcontent">
                      <span class="wt-proposalsicon">
                        <i class="fa fa-angle-double-down"></i>
                        <i class="fa fa-newspaper"></i>
                      </span>
                      <div class="wt-title">
                        <h3>
                          {jobDetail?.post_meta_details?.usage_fee
                            ? "$" + jobDetail?.post_meta_details?.usage_fee
                            : "$0"}
                        </h3>
                        <span>Usage Fee</span>
                      </div>
                    </div>

                    <div class="wt-proposalsrcontent">
                      <span class="wt-proposalsicon">
                        <i class="fa fa-angle-double-down"></i>
                        <i class="fa fa-newspaper"></i>
                      </span>
                      <div class="wt-title">
                        <h3>
                          {jobDetail?.post_meta_details?._project_cost
                            ? `$${
                                Number(
                                  jobDetail?.post_meta_details?._project_cost
                                ) +
                                Number(
                                  jobDetail?.post_meta_details?.usage_fee
                                ) +
                                Number(
                                  jobDetail?.post_meta_details
                                    ?.amount_of_per_diem_provided *
                                    jobDetail?.post_meta_details
                                      ?.set_custom_time?.length
                                )
                              }`
                            : jobDetail?.post_meta_details?._hourly_rate
                            ? `$${
                                Number(
                                  jobDetail?.post_meta_details?._hourly_rate
                                ) *
                                  Number(
                                    jobDetail?.post_meta_details
                                      ?._estimated_hours
                                  ) +
                                Number(
                                  jobDetail?.post_meta_details?.usage_fee
                                ) +
                                Number(
                                  jobDetail?.post_meta_details
                                    ?.amount_of_per_diem_provided *
                                    jobDetail?.post_meta_details
                                      ?.set_custom_time?.length
                                )
                              }`
                            : "$0"}
                        </h3>
                        <span>Grand Total</span>
                      </div>
                    </div>

                    {/* <div class="wt-clicksavearea">
                      <a
                        onClick={handleSaveJob}
                        href="javascrip:void(0);"
                        class="wt-clicksavebtn"
                      >
                        <i class={isJobSaved ? "fa fa-heart" : "far fa-heart"}></i>{" "}
                        {isJobSaved ? "Remove Job" : "Save Job"}
                      </a>
                    </div> */}
                  </div>
                  <div class="wt-widget wt-companysinfo-jobsingle">
                    <div class="wt-companysdetails">
                      {/* <figure class="wt-companysimg">
                        <img
                          src="images/company/img-01.jpg"
                          alt="img description"
                        />
                      </figure> */}
                      <div class="wt-companysinfo">
                        {clientInfo?.profile_image?.length ? (
                          <figure class="wt-companysimg">
                            <img
                              src={
                                clientInfo?.profile_image?.[0]?.guid ||
                                Images?.hero1
                              }
                              alt="img description"
                            />
                            {/* <div class="wt-userdropdown wt-online"></div> */}
                          </figure>
                        ) : undefined}
                        {/* <figure>
                          <img
                            src="images/company/img-01.png"
                            alt="img description"
                          />
                        </figure> */}
                        <div class="wt-title">
                          {clientInfo?.user_data?.verified_status === "yes" ? (
                            <a href="javascript:void(0);">
                              <i class="fa fa-check-circle"></i> Verified
                              Company
                            </a>
                          ) : undefined}
                          <h2>{clientInfo?.user_data?.display_name || ""}</h2>
                        </div>
                        <ul class="wt-postarticlemeta">
                          {/* <li>
                            <a href="javascript:void(0);">
                              <span>Open Jobs</span>
                            </a>
                          </li> */}
                          <li
                            onClick={() => {
                              const queryParams = new URLSearchParams({
                                id: jobDetail?.profile?.post_author,
                              });
                              navigate(
                                `${
                                  routeName.CLIENT_PROFILE
                                }?${queryParams.toString()}`
                              );
                            }}
                          >
                            <a href="javascript:void(0);">
                              <span>Full Profile</span>
                            </a>
                          </li>
                          <li class="wt-following">
                            <a
                              onClick={handleFollowUnFollow}
                              href="javascript:void(0);"
                            >
                              <span>{isFollow ? "UnFollow" : "Follow"}</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="wt-widget wt-sharejob">
                    <div class="wt-widgettitle">
                      <h2>Share This Job</h2>
                    </div>
                    <div class="wt-widgetcontent">
                      <ul class="wt-socialiconssimple">
                        <li class="wt-facebook">
                          <a href="javascript:void(0);">
                            <FacebookShareButton
                              url={"https://booksculp.com/project/test-job/"}
                              quote={"Job"}
                            >
                              <i class="fab fa-facebook-f"></i>
                              Share on Facebook
                            </FacebookShareButton>
                          </a>
                        </li>
                        <li class="wt-twitter">
                          <a href="javascript:void(0);">
                            <TwitterShareButton
                              url={window.location.href}
                              quote={"Job"}
                            >
                              <i class="fab fa-twitter"></i>Share on Twitter
                            </TwitterShareButton>
                          </a>
                        </li>
                        <li class="wt-linkedin">
                          <a href="javascript:void(0);">
                            <LinkedinShareButton
                              url={window.location.href}
                              quote={"Job"}
                            >
                              <i class="fab fa-linkedin-in"></i>Share on
                              Linkedin
                            </LinkedinShareButton>
                          </a>
                        </li>
                        <li class="wt-googleplus">
                          <a href="javascript:void(0);">
                            <EmailShareButton url={window.location.href}>
                              <i class="fa fa-envelope"></i>Share on Email
                            </EmailShareButton>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="wt-widget wt-reportjob">
                    <div class="wt-widgettitle">
                      <h2>Report This Job</h2>
                    </div>
                    <div class="wt-widgetcontent">
                      <form class="wt-formtheme wt-formreport">
                        <fieldset>
                          <div class="form-group">
                            <span class="wt-select">
                              <select
                                value={reportReason}
                                onChange={(e) =>
                                  setReportReason(e.target.value)
                                }
                              >
                                <option disabled value="">
                                  Select Reason
                                </option>
                                {allOptionData?.postReportOptions?.length
                                  ? allOptionData?.postReportOptions?.map(
                                      (item) => (
                                        <option
                                          key={item?.label}
                                          value={item?.label}
                                        >
                                          {item?.value}
                                        </option>
                                      )
                                    )
                                  : undefined}
                              </select>
                            </span>
                          </div>
                          <div class="form-group">
                            <textarea
                              class="form-control"
                              placeholder="Description"
                              value={reportDescription}
                              onChange={(e) =>
                                setReportDescription(e.target.value)
                              }
                            ></textarea>
                          </div>
                          <div class="form-group wt-btnarea">
                            <a
                              onClick={handleReport}
                              href="javascrip:void(0);"
                              class="wt-btn"
                            >
                              Submit
                            </a>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
        {loginModalVisible && (
          <LoginAlert
            setVisible={setLoginModalVisible}
            visible={loginModalVisible}
            setLoginModal={setLoginModal}
          />
        )}

        {loginModal && (
          <Login
            show={loginModal}
            setShow={setLoginModal}
            getUserData={getUserData}
          />
        )}
        {isIdentityVisible ? (
          <DashboardPopUp
            setVisible={setIsIdentityVisible}
            visible={isIdentityVisible}
            text={
              "You cannot send proposal to the job until your identity is not verified by the admin"
            }
            title={"ID Verification Missing"}
          />
        ) : undefined}
      </div>
      <Footer />
    </div>
  );
};
