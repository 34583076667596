import React from "react";
import Sidebar from "../Screens/Others/Sidebar";
import { Header } from "../Components";

const SidebarLayout = (props) => {
  return (
    <div className="">
      <div className="sideBarArea">
        <Sidebar />
        <div className="dashContent container-fluid">
          <Header />
          {props.children}
          <div className="dashFoot">Book Sculp 2022 © All Rights Reserved.</div>
        </div>
      </div>
    </div>
  );
};

export default SidebarLayout;
